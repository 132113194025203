

"use client"

import Link from "next/link"
import { useActionState, useEffect, useRef, useState } from "react"
import { BfxUserChangePassword } from "../../lib/actions/bfxAPI/user/functionUser"


export function ChangePassForm ()
{

  const { navOpen, setNavOpen, siteNav } = useUserUiContext()
  const { hasSession, setHasSession, setUserInfo } = useUserContext()

  /*   flog('LoginForm hasSession:', hasSession) */

  const [res, dispatch, isPending] = useActionState(BfxUserChangePassword, undefined)

  useEffect(() =>
  {

    fxlog('isPending:', isPending)
    if (isPending) return
    if (!isPending && res?.status == 'success')
    {
      tlog('res?.status', res)
      if (res?.result)
      {
        setHasSession(true)


        setUserInfo(res?.result)
      }
      navOpen && setNavOpen(false)
    }
  }, [isPending])

  let anidelay = 1

  const loginForm = useRef<HTMLFormElement>(null)
  const loginBtn = useRef<HTMLButtonElement>(null)

  const [oldpassword, setOldpassword] = useState('')
  const [newpassword, setNewpassword] = useState('')
  const [conpassword, setConpassword] = useState('')
  const [oldpasswordValid, setOldpasswordValid] = useState('')
  const [newpasswordValid, setNewpasswordValid] = useState('')
  const [conpasswordValid, setConpasswordValid] = useState('')


  return (
    (<div className="max-w-6xl mx-auto px-4 sm:px-6 justify-center justify-items-center items-center"
    >
      <div className="pt-10 pb-12 md:pt-20" ref={siteNav}>
        <div className="max-w-md min-w-md mx-auto ">
          <div aria-live="polite" className={`bg-zinc-800 rounded-xl ring-rose-500 ring-2 my-4 text-center p-2 ${!res?.status && 'invisible'}`}>
            {!isPending && res?.status && <p className="text-lg text-rose-400 shadow-xl">{res?.msg} </p>}
          </div>


          {/*   <div aria-live="polite" className={`bg-zinc-800 rounded-xl ring-rose-500 ring-2 my-4 text-center p-2 ${loginForm?.current?.['username'].invalid && 'invisible'}`}
            aria-invalid={loginForm?.current?.['username']?.invalid}
          >

            <p className="text-lg text-rose-400 shadow-xl">
              {loginForm?.current?.['username']?.checkValidity()}
            </p>
          </div> */}


          <form className='loginform flex flex-col w-full p-4 max-w-lg size-fit rounded-lg border-[1.5px] border-stroke px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-luxury dark:text-white dark:focus:border-primary dark:disabled:bg-black'
            aria-disabled={isPending}

            ref={loginForm}
            action={dispatch}>



            <label className={`block text-gray text-lg duration-100 font-medium  mt-4 mb-1 fade-u aniDelay-${anidelay++}`} htmlFor="oldpassword">
              Current Password
            </label>
            <input
              className={`form-input peer/oldpassword duration-100 w-full shadow-xl text-lg text-graydark fade-u aniDelay-${anidelay++} ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500`}
              disabled={isPending}
              id="oldpassword"
              type="password"
              name="oldpassword"
              onBlur={({ currentTarget }) =>
              {
                if (currentTarget.value.match(/^[A-Za-z0-9]{4,16}$/)) setOldpasswordValid("")
                else setOldpasswordValid("รหัสผ่านต้องมีอย่างน้อย 4 ตัวอักษร")
              }}
              onChange={({ currentTarget }) => setOldpassword(currentTarget.value)}
              value={oldpassword}
              pattern="^[A-Za-z0-9]{4,16}$"
              title="รหัสผ่านมีอย่างน้อย 4 ตัว"
              minLength={4}
              placeholder="รหัสผ่านปัจจุบัน"
              autoComplete="current-password"
              required
            />
            <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/current-password:block' htmlFor="current-password" >
              {oldpasswordValid}
            </label>

            <label className={`block text-gray text-lg duration-100 font-medium  mt-4 mb-1 fade-u aniDelay-${anidelay++}`} htmlFor="newpassword">
              New Password
            </label>
            <input
              className={`form-input peer/newpassword duration-100 w-full shadow-xl text-lg text-graydark fade-u aniDelay-${anidelay++} ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500`}
              disabled={isPending}
              id="newpassword"
              type="password"
              name="newpassword"
              onBlur={({ currentTarget }) =>
              {
                if (currentTarget.value.match(/^[A-Za-z0-9]{4,16}$/)) setNewpasswordValid("")
                else setNewpasswordValid("รหัสผ่านต้องมีอย่างน้อย 4 ตัวอักษร")
              }}
              onChange={({ currentTarget }) => setNewpassword(currentTarget.value)}
              value={newpassword}
              pattern="^[A-Za-z0-9]{4,16}$"
              title="รหัสผ่านมีอย่างน้อย 4 ตัว"
              minLength={4}
              placeholder="รหัสผ่านใหม่"
              autoComplete="new-password"
              required
            />
            <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/password:block' htmlFor="new-password" >
              {newpasswordValid}
            </label>

            <label className={`block text-gray text-lg duration-100 font-medium  mt-4 mb-1 fade-u aniDelay-${anidelay++}`} htmlFor="conpassword">
              Confirm Password
            </label>
            <input
              className={`form-input peer/cpassword duration-100 w-full shadow-xl text-lg text-graydark fade-u aniDelay-${anidelay++} ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500`}
              disabled={isPending}
              id="conpassword"
              type="password"
              name="conpassword"
              onBlur={({ currentTarget }) =>
              {
                if (currentTarget.value.match(/^[A-Za-z0-9]{4,16}$/)) setConpasswordValid("")
                else setConpasswordValid("รหัสผ่านต้องมีอย่างน้อย 4 ตัวอักษร")
              }}
              onChange={({ currentTarget }) => setConpassword(currentTarget.value)}
              value={conpassword}
              pattern="^[A-Za-z0-9]{4,16}$"
              title="รหัสผ่านมีอย่างน้อย 4 ตัว"
              minLength={4}
              placeholder="ยืนยัน รหัสผ่านใหม่"
              autoComplete="new-password"
              required
            />
            <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/cpassword:block' htmlFor="password" >
              {conpasswordValid}
            </label>
            <div className={`flex flex-row  mt-4 fade-u items-center duration-100  aniDelay-${anidelay++}`}>
              <Link href={{pathname:"/line"}} className="text-sm font-medium text-blue-400 hover:underline">
                Forget Password ลืมรหัสผ่าน?
              </Link>
            </div>
            <button type="submit"
              className={`p-2 text-xl text-indigo-100 disabled:text-zinc-600 disabled:hover:cursor-default enabled:active:animate-ping enabled:hover:animate-hover hover:-translate-y-px hover:translate-x-px drop-shadow-sm size-fit font-bold rounded-md mt-6 mb-4 w-full ring-2 inset-1 enabled:ring-blue-600  ${isPending ? 'animate-bounce-2 aniDelay-0' : 'aniDelay-' + anidelay + ' fade-u'} disabled:read-only `}
              disabled={!(loginForm?.current?.['oldpassword']?.validity.valid && loginForm?.current?.['newpassword']?.validity.valid && loginForm?.current?.['conpassword']?.validity.valid)}
              id='loginBtn'
              name='loginBtn'
              ref={loginBtn}
            >
              {isPending ? <span className="cubic-loader" /> : <span className="flex flex-row justify-center">Change Password เปลี่ยนรหัสผ่าน</span>}

            </button>

          </form>
        </div>
      </div>
    </div>)
  )
}


