'use client'
import Link from "next/link"
import { memo } from "react"
import { ImgList, ImgLogo } from "../../images"
import { EzLoading } from "../EzLoading"
import { EzLink } from "../EzLink"


export const Hero = memo(function() {
  plog('Hero load')
const {hasSession} = useUserContext()
let aniCouter=1
  return (

    <section className="flex flex-row size-screen justify-center content-center  min-w-75 max-w-3xl mx-auto px-4 sm:px-6 text-slate-50">
 
        <div className="flex flex-col justify-center items-center pt-32 pb-12 md:pt-40 md:pb-20">
        
        <ImgLogo.BFLIX className={ `fade-u aniDelay-${ aniCouter++ }` } />

{/* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */}
          <div className="flex mt-auto justify-center items-center mb-auto gap-4 flex-wrap sm:flex-row">

            <EzLoading>{hasSession &&
            <EzLink href={ { pathname: "/lobby" } } className={ `fade-r aniDelay-${ aniCouter++ }` }>
                <ImgList.ButtonRegister className="shadow-2xl" text='ทางเข้าคาสิโน_LOBBY' /></EzLink> ||

              <EzLink href={{pathname:"/register"}} className={`fade-r aniDelay-${aniCouter++}`}><ImgList.ButtonRegister className="shadow-2xl" text='สมัครสมาชิก_REGISTER'/></EzLink>
              }</EzLoading>

          <EzLink href={ { pathname: "/line"} } className={ `fade-l aniDelay-${ aniCouter++ }` }>
            <ImgList.ButtonLine className="shadow-2xl" text='@BF579'/>
            </EzLink>


          </div>

          {/* ⇧ End   __________________________    ___________________________ End ⇧ */}


          <div className="flex flex-row mt-auto gap-8 mb-0 max-w-3xl justify-center sm:max-w-none sm:flex-wrap Fbtn icon">
          <EzLink href={ { pathname: "/lobby" } }>
            <ImgList.android className={`fade-r aniDelay-${aniCouter++}`}/></EzLink>
          <EzLink href={ { pathname: "/lobby" } }>   <ImgList.ios className={ `fade-u aniDelay-${ aniCouter++ }` } /></EzLink>
          <EzLink href={ { pathname: "/lobby" } }> <ImgList.desktop className={ `fade-l aniDelay-${ aniCouter++ }` } /></EzLink>
          </div>

        </div>

    </section>

  )
}
)