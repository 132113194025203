'use client'

import { createContext, useContext } from 'react'



export const useadminUiContext = createContext<AdminUiContext>({
    showInstallBanner: false, setShowInstallBanner: () => { },
    GameframeRef: undefined,
    themeRef: undefined, trigger: undefined, pageScroll: undefined, siteNav: undefined, pathname: undefined, router: undefined,
    top: true, setTop: () => { },
    navOpen: false, setNavOpen: () => { },
    delay: 300, setDelay: () => { },
    sideBarOpen: false, setSideBarOpen: () => { },
    darkThemeContext: false , setDarkThemeContext: () => { }
})

export const useAdminUiContext = () => useContext(useadminUiContext)
