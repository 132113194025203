export { }
declare global {
      /**ลบตัวอย่าง property */
      type Concrete<Type> = Partial<{
            [Property in keyof Type]-?: Type[Property]
      }>
      /**
       * type MongoDb.= {  
      * [P in keyof FnModule<typeof MongoDb.rv>]: FnModule<typeof MongoDb.rv>[P]  
}
       */
      type MapModule<T> = {
            [P in keyof T & string]-?: T[P]
      }
      type GetFnModule<T> = Partial<{ [k in keyof T]: (Function) }>

    type OptionalKeys<T> = {
        [K in keyof T]-?: T extends Record<K, T[K]> ? never : K;
    }[keyof T];
    
    type RequiredKeys<T> = {
        [K in keyof T]-?: T extends Record<K, T[K]> ? K : never;
    }[keyof T] & keyof T;

      type Arrayish = { [n: number]: unknown }
      type A = keyof Arrayish

      type Mapish = { [k: string]: boolean }
      type M = keyof Mapish

      type ToArray<Type> = Type extends any ? Type[] : never

      type StrArrOrNumArr = ToArray<string | number>

      type ToArrayNonDist<Type> = [Type] extends [any] ? Type[] : never

      // 'ArrOfStrOrNum' is no longer a union.
      type ArrOfStrOrNum = ToArrayNonDist<string | number>
      /* ⇧ End   __________________________  END  ___________________________ End ⇧ */

      type PropEventSource<Type> = {
            on<Key extends string & keyof Type>
                  (eventName: `${Key}Changed`, callback: (newValue: Type[Key]) => void): void
      }

}
declare function handleRequest (url: string, method: "GET" | "POST"): void

declare function makeWatchedObject<Type> (obj: Type): Type & PropEventSource<Type>



function compare (a: string, b: string): -1 | 0 | 1
{
      return a === b ? 0 : a > b ? 1 : -1
}






/* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */
const req1 = { url: "https://example.com", method: "GET" }
/* handleRequest(req1.url, req1.method as any); */

// Change 1:
const req2 = { url: "https://example.com", method: "GET" as "GET" }
// Change 2
/* handleRequest(req2.url, req2.method as "GET");
const person = makeWatchedObject({
      firstName: "Saoirse",
      lastName: "Ronan",
      age: 26
}); */

/* person.on("firstNameChanged", newName => {
      //                        ^?
      console.log(`new name is ${newName.toUpperCase()}`);
});

person.on("ageChanged", newAge => {
      //                  ^?
      if (newAge < 0) {
            console.warn("warning! negative age");
      }
}) */


