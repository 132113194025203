'use client'

import { SelectGroupTwo } from ".."
import { MultiSelect } from "./MultiSelect"


export const Input = ( { ...ags } ) => <input { ...ags } className={ "EzInput" } />

export const Textarea = ( { ...ags } ) => <textarea className={ "EzTextarea" } { ...ags } />

type Prop = { children?: React.ReactNode, divclassname?: string }
export const InputIcon = ( { ...arg } ) =>
  <div className={ arg?.divclassname }>
    <input ref={ arg?.ref } className={ `${ arg?.className }` } />
    { arg?.children }
  </div>

export const Select_input = ( { ...ags } ) =>
  <div className={ `Select_input` }>
    <div>        <h3>          { ags?.children }        </h3>      </div>
    <div>        <SelectGroupTwo />        <MultiSelect id="multiSelect" />      </div>
  </div>

