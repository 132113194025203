'use client'
import { createContext, useContext } from 'react'



export const uselinebotcontext = createContext<LineBotContext>({
    chatBotEvent: [], setChatBotEvent: () => { },
    lineUserList: [], setLineUserList: () => { },
    lineChannelList: [], setLineChannelList: () => { },
    lineBotAssistant: [], setLineBotAssistant: () => { },
})

export const useLineBotContext = () => useContext(uselinebotcontext)