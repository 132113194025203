export { }


export function Breadcrumb({ 
  pageName ,
  segment
}:{ 
  pageName?: string;
  segment?:string}){
  return  <div className="mb flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
      <h2 className="text-sm font-semibold text-black dark:text-white">
        {pageName?.replace(/\//,"").toUpperCase()}
      </h2>
      <nav>
        <ol className="flex items-center gap-2">
          <li className="font-medium text-primary">{segment||pageName}</li>
        </ol>
      </nav>
    </div>
  
}

