'use client' 
import { useState } from "react"
import { uselinebotcontext, useLocalStorage } from "@/lib"


export function LineBotContexProvider ({
  children
}: {
  children: React.ReactNode
})
{

  /* ⇩ Start  `````````````````````````  LINE OA  ````````````````````````` Start ⇩ */
  const [chatBotEvent, setChatBotEvent] = useLocalStorage<ChatBotEvent[]>('chatBotEvent', [])
  const [lineUserList, setLineUserList] = useLocalStorage<LineUser[]>('lineUserList', [])
  const [lineChannelList, setLineChannelList] = useState<LineBotChannel[]>([])
  const [lineBotAssistant, setLineBotAssistant] = useState<LineBotAssistant[]>([])
  /* ⇧ End   __________________________  LINE OA  ___________________________ End ⇧ */

  /* async function WatchChangeStream() {
    const pipeline = [ {  $changeStream: {} } ]
  
  MongoDb.Aggregate<ChatBotEvent>('events','linebot',pipeline)
     .then(res => {
      if(typeof res == "object"){
        tlog("setChatBotEvent=",typeof res,"\n res=",res)
        startTransition(()=>
          //@ts-ignore
            setChatBotEvent(n=>
            n?[res,...n]:[res]
          )
          )
         }
         return res
        }
     ).catch((e) => {
      setTimeout(() => {
        fxlog("start new setChatBotEvent=")
      WatchChangeStream()
      },1000)
    })
    }
  
  
    useEffect( ()=>{
     const task = setTimeout(async() => {
      
  WatchChangeStream()
              }, 1000)
         
  return () => clearTimeout(task)
     
    },[chatBotEvent]) */
laylog("LineBotContexProvider")
  return <uselinebotcontext.Provider
    value={
      {
        chatBotEvent, setChatBotEvent,
        lineChannelList, setLineChannelList,
        lineUserList, setLineUserList,
        lineBotAssistant, setLineBotAssistant,

      }}>
    {children}


  </uselinebotcontext.Provider>
}
