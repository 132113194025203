'use client'
import { createContext, useContext } from 'react'



export const useusercontext = createContext<UserContext>({
    // ⇩ Start  ````````````````````````  หน้าบ้านโซน  ````````````````````````` Start ⇩\
    userInfo: undefined, setUserInfo: () => { },
    hasSession: false, setHasSession: () => { },
    bfxGameProviderList: [], setBfxGameProviderList: () => { },
    historyGames: [], setHistoryGames: () => { },
    //⇧ End   __________________________  หน้าบ้านโซน  ___________________________ End ⇧\
})

export const useUserContext = () => useContext(useusercontext)
