'use client';

import { Fragment, memo } from "react";
import type { BfxGameProvider } from "../../lib";
import { EzLoading } from "../EzLoading";
import { CardOverLay } from "./gamesCard";

export const LobbyProviderLists = memo( function  ( { list }: { list?: BfxGameProvider[]; } )
{
  return <div className="flex justify-between flex-wrap gap-y-10 overflow-y-visible ">
   <EzLoading>{ list?.map( ( i, k ) =>
      <Fragment key={ k } >
        <CardOverLay
          GamesProvider={ i } className={ `max-w-[30vw] fade-u` }
        />
      </Fragment>
    ) }</EzLoading> 
  </div>;
})
