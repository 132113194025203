
import type { ObjectId } from "mongodb"
import type { DetailedHTMLProps, Dispatch, HTMLAttributes, JSX, MouseEventHandler, ReactNode, RefAttributes, SetStateAction } from "react"
import { FncIcons } from "../Components/Icons/FncIcons"
import { iconList } from "./utils/iconList"



declare global
{
  type RefType<T> = DetailedHTMLProps<HTMLAttributes<T>, T>
    | undefined
    | Partial<T>
    | RefAttributes<T>
    | MouseEventHandler<T>
    | React.ReactNode

  type oId = ObjectId

  interface EZ{
    API:{[x:string|number]:string}
    Contexts:MapModule<typeof import("./Contexts")>
    DB:MapModule<typeof import("./MongoDb/MongoSrv")>
}
  var EZ:EZ
  /** Create a "watched object" with an `on` method  
   * so that you can watch for changes to properties.   
   * const Object = MakeEventObject({  
   * name:"..."  
   * })
   * 
   * Object.on("nameChanged",function => {  
   * ....
   * })
   * */
  function MakeEventObject<Type> (obj: Type): Type & PropEventSource<Type>;

  type PartialRecord<A extends keyof any, T> = Partial<Record<A, T>>
  /*
  * ไม่เอา password
  */
  type NonPW<T> = DistributiveOmit<T, "password">
  var UserHasSession: boolean | undefined
  /**
   * 
   * @param res Log ก่อน Return
   * @param fnName 
   */
  function logRe<T> (res: T, fnName?: string): T

  interface BoUser
  {
    
    username: string
    name: string
    role?: "main" | "sub"
    session?: BoSession[]
   
    updateAt?: number
    createAt?:number
  }

  interface BoUserHasAg extends Omit<BoUser,"password"> {
    agInfo:AgInfo
  }
  interface BoUserPW extends BoUser
  {
    password?: string
  }

  type NoticeMsg = {
    _id: string | number
    from?: string
    msg?: string
    createdAt: number
    updateAt?: number
    expireAt?: number
  }



  interface BoSession {
    _id:  ObjectId | string
    sessionId: string
    userId: string
    updateAt: number
  }

  type MetaDataTemplate = {
    path: string
    title: string
    description: string
    OGImg: string
    favicon?: string
  }

  type ContentArticle = {
    data: any[]
    updateAt: number
  }

  interface AgInfo
  {
    _id?: ObjectId | string
    status: "active" | "lock"
    name: string
    apiEndPoint?: string
    username: string
    updateAt?: number
    balance?: number
      secret:AgSecret
    
  }


  interface loginAg extends AgInfo {
  
    account?:BoUserPW[]
  }

  interface AgSecret
  {
    Xapicat: string
    Xapikey: string
  }

  type FrontProfile = {
    frontId: string
    name: string
    logoImg?: string
    siteBG?: string
    btnReg?: string
    btnLine?: string
    updateAt?: number
  }


  const IconList: { [key in typeof iconList[number]]: key }

  var LogColor: Partial<{
    [x: string]: string
  }>

  type Indexed<T> = T[keyof T]
  type IndexMap = {
    [index: string]: number | string
  }
  /**
   * @property https://fonts.google.com/icons
   * @param Icon https://fonts.google.com/icons
   * @link https://fonts.google.com/icons
   * @types string
   */
  function Icons ({
    iconName, className, children
  }: {
    iconName?: keyof typeof IconList | string
    className?: string, children?: ReactNode
  }): any


  function log (message?: any, ...optionalParams: any[]): void
  function laylog (message?: any, ...optionalParams: any[]): void
  function plog (message?: any, ...optionalParams: any[]): void
  function fnclog (message?: any, ...optionalParams: any[]): void
  function svlog (message?: any, ...optionalParams: any[]): void
  function celog (message?: any, ...optionalParams: any[]): void
  function fxlog (message?: any, ...optionalParams: any[]): void
  function olog (message?: any, ...optionalParams: any[]): void
  function flog (message?: any, ...optionalParams: any[]): void
  function tlog (message?: any, ...optionalParams: any[]): void



  interface BoMenuLink {
    title?: string, content?: JSX.Element,
    iconL?: keyof typeof IconList, name?: string, iconR?: keyof typeof IconList,
    href?: string, passHref?: boolean, prefetch?: boolean
    subMenu?: BoMenuLink[], collapseAble?: boolean
  }


  interface Article {
    _id?:ObjectId,
    DateTime?: string,
    Title: string,
    Link: string,
    ImageURL: string,
    ContentHTML: string,
    Content: string,
    createAt?: number
    updateAt?: number

  }


  type UsersListT<T> = [T]
  /**
   * Property Type รับ Property ของ Type ออกมาโชว์
   */
  type Property<T> = {
    [Property in keyof T]?: T[Property]
  }
  var clientSession: any


  var initMenuLink: BoMenuLink[]
  /**
   * useState setUseSteta
   */
  type s_S<T> = Dispatch<SetStateAction<T>>
  /**
   * 
   */
  type StrictOmit<T, K extends keyof T> = Omit<T, K>
  type DistributiveOmit<T, K extends keyof any> = T extends any
    ? Omit<T, K>
    : never
  /**
   * เอาค่าที่ undefined
   */
  type NoUndefined<T> = Exclude<T, undefined>
  

  //{"bfx":{colName:"users"|"usersBio"}, "bo":{colName:"Admin"} , "linebot":{colName:""}}
  /**
   * database @property "bfx"
   */
  interface dbname
  {
    bfx: "users" | "usersBio" | "providers" | "session" | "subscribeUser" | "adminBio" | "boAG"|"customer",
    bo: "agInfo" | "users" | "agTransaction" | "bfxProviders" | "session" | "sitedata",
    linebot: "assistant" | "channel" | "events" | "users"|"intent"|"bflix_events"|"channelId"
  }
  type colname<T> = T[keyof T]
  type MongoDB<T=dbname> =
    {
      dbName: keyof T
      colName:  A extends {dbName:keyof T}?A:colname<T>
    }

  type DB = PartialRecord<A, dbname>
  type dbName = dBName<string>
  type dBName<A> = A extends { name: string, collection: collName[] }
    ? A
    : string
  type collName = collname<string>
  type collname<A> = A extends { name: string }
    ? A
    : string
}




globalThis.initMenuLink = [

  { name: "HOME", href: "/", iconL: "Tune" },
  {
    name: "AG", href: "/bo", iconL: 'Sword_Rose', passHref: true,
    subMenu: [
      { name: "Transfer", href: "/transfer", iconL: 'Currency_Exchange' },
      { name: "Brance", href: "/brance", iconL: 'swords' },

    ]
  },
  { name: "สมาชิก", href: "/members", iconL: "Group" },
  {
    name: "LINE BOT", href: '/linebot', iconR: 'Phone_Enabled',
    subMenu: [
      { name: 'Chat', href: '/chat' },
      { name: 'Users', href: '/users' },
      { name: 'ตั้งค่า', href: "/setting" }
    ]
  },
  {
    name: "seting", href: "/setting", iconR: 'Phone_Enabled', passHref: true,
    subMenu: [
      { name: "บัญชีฝาก", href: "/banking", iconL: 'Currency_Exchange' },
      { name: "ค่าย-เกมส์", href: "/provider", iconL: 'swords' }
    ]

  },
  {
    name: "EzPreviewUI", href: "/ezpreview", iconL: 'Language',
    subMenu: [
      { name: "UI", href: "/ui" },
      { name: "database", href: "/database" },
      { name: "Send Message", href: "/sendmessage", iconL: 'swords' }
    ]
  },
  {
    name: "img", href: "/img/res", iconR: 'Phone_Enabled', prefetch: false,
    subMenu: [
      { name: "goldborder", href: "/goldborder", prefetch: false },
      { name: "txtimg", href: "/txtimg", prefetch: false },
      { name: "icon", href: "/icon", prefetch: false }
    ]
  }
]


globalThis.LogColor = {

  FgBlack: "\x1b[30m", FgRed: "\x1b[31m", FgGreen: "\x1b[32m", FgYellow: "\x1b[33m", FgBlue: "\x1b[34m", FgMagenta: "\x1b[35m", FgCyan: "\x1b[36m", FgWhite: "\x1b[37m", FgGray: "\x1b[90m",

  BgBlack: "\x1b[40m", BgRed: "\x1b[41m", BgGreen: "\x1b[42m", BgYellow: "\x1b[43m", BgBlue: "\x1b[44m", BgMagenta: "\x1b[45m", BgCyan: "\x1b[46m", BgWhite: "\x1b[47m", BgGray: "\x1b[100m",

  BgRedFgBlack: "\x1b[101m", BgGreenFgBlack: "\x1b[102m", BgYellowFgBlack: "\x1b[103m", BgBlueFgBlack: "\x1b[104m", BgMagentaFgBlack: "\x1b[105m", BgCyanFgBlack: "\x1b[106m",

  Blink: "\x1b[5m", Reverse: "\x1b[7m", Hidden: "\x1b[8m",
  Reset: '\x1b[0m', Bright: "\x1b[1m", Dim: "\x1b[2m",
  Underscore: `\x1b[4m`

} as const
/**
* @param iCon @prop เพิ่มเติม คลิก https://fonts.google.com/icons
*/
globalThis.log = process?.env?.NODE_ENV === "development" ? console.log : () => { }
globalThis.laylog = log.bind(null, `⏳ \x1b[106m Layout \x1b[0m`)
globalThis.plog = log.bind(null, `♊ \x1b[93m Page \x1b[0m`)
globalThis.fnclog = log.bind(null, `\x1b[42m function > > \x1b[0m`)
globalThis.svlog = log.bind(null, `\x1b[104m server > > \x1b[0m`)
globalThis.celog = log.bind(null, `\x1b[31m client > > \x1b[0m`)
globalThis.fxlog = log.bind(null, `\x1b[41m fx > > \x1b[0m`)
globalThis.olog = (o: any) => log.bind(null, `\x1b[45m ${o?.name || 'f'} > > \x1b[0m `)
globalThis.tlog = log.bind(null, `✔ \x1b[32m true  \x1b[0m`)
globalThis.flog = log.bind(null, `⛔ \x1b[33m false  \x1b[0m`)

globalThis.String.prototype.toUrl = function (): string
{
  return this.replace(/\s|\%20/gm, '')
}
globalThis.Icons = FncIcons


globalThis.logRe = (res: any, fnName?: string) =>
{
  console.log(fnName || '', '\ndb:', res)
  return res
}

function getDisplayName (WrappedComponent: { displayName: any; name: any })
{
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}
export { }

