
'use client'
import { useUserUiContext } from "@/lib";
import Link from "next/link";
import { EzLink } from "../EzLink"
export { };
export function EntransLobbyBtn() {
  
  const {showInstallBanner,gamePlayState } = useUserUiContext()


  return <div className={`fixed flex flex-row justify-evenly z-10 ${showInstallBanner?'bottom-11 md:bottom-0':'bottom-0 '} w-full max-h-12 items-end bg-gradient-to-tl from-[#112B] to-[#224] aniDelay-5 ${gamePlayState?'translate-y-[200%]':'fade-u'}`}>


{/* ⇩ Start  `````````````````````````  ฝาก  ````````````````````````` Start ⇩ */}

      <EzLink className=" mb-2" href={{pathname:"/line"}}>
   <div className="flex flex-row drop-shadow items-center gap-1 hover:opacity-95">

   <svg className="" height={30} viewBox="0 -25 50 30" fill="url(#xgold)">
          <text fontSize={28}>ฝาก</text>
          
        </svg>
      
<svg className="2xsm:block hidden" height={30} viewBox="0 -960 960 960" fill="url(#xgold)">
  <path d="M480-67.77q-114.39 0-208.06-56.71T123.73-278.81v123.65H67.77v-215.96h215.96v55.96H168.5q43.38 86.77 127.42 139.1 84.04 52.33 184.04 52.33 71.89 0 135.42-26.6 63.54-26.59 111.45-72.8 47.9-46.22 76.92-108.97 29.02-62.75 31.83-135.17h55.96q-2.43 83.22-35.66 156.01-33.23 72.8-88.84 127.05-55.62 54.25-129.48 85.34-73.87 31.1-157.56 31.1Zm-26.69-144.77v-49.62q-42.81-10.26-71.42-35.46-28.62-25.19-44.66-68.57l47.38-19q14.31 38.27 41.22 58.17 26.9 19.91 60.17 19.91 33.65 0 61.06-17.45 27.4-17.44 27.4-55.06 0-31.3-22.79-49.48-22.78-18.17-86.05-41.55-61.7-22.16-88.2-48.72-26.5-26.55-26.5-70.94 0-37.96 27.27-69.46 27.27-31.5 77.12-40.04v-47.65h51.38v47.64q33.19 2.63 60.73 24.15T608-623.01L561.85-604q-10.62-22.23-30.06-36.56-19.43-14.33-49.72-14.33-37.38 0-58.57 18.15-21.19 18.14-21.19 46.36 0 28.3 19.88 44.07 19.89 15.77 82.96 37.31 71.73 25.15 95.83 56.41 24.1 31.26 24.1 73 0 28.28-10.43 49.65-10.42 21.36-27.4 35.94-16.98 14.58-38.54 22.71-21.56 8.14-43.94 11.11v47.64h-51.46ZM68.46-492.12q3.5-85.03 37.04-158.03t89.47-126.71q55.92-53.72 129.3-84.24 73.38-30.51 155.77-30.51 113.38 0 208.11 57 94.74 57 148.12 155.77v-125.39h55.96v215.96H676.27v-55.96H791.5q-41.11-84.54-125.9-137.98-84.79-53.44-185.56-53.44-70.35 0-133.69 26.21-63.35 26.21-111.64 72.13-48.29 45.92-77.88 108.77-29.6 62.85-32.41 136.42H68.46Z"/></svg>

     </div>
      </EzLink>
{/* ⇧ End   __________________________  ฝาก  ___________________________ End ⇧ */}


{/* ⇩ Start  `````````````````````````  เข้าเล่นเกมส์  ````````````````````````` Start ⇩ */}

      <EzLink href={{pathname:"/lobby"}}>
    <div className="flex flex-col drop-shadow items-center pt-1 bg-[#0f0c80] bg-gradient-to-br from-[#fff4] via-[#0008] to-[#000D] rounded-t-full hover:bg-opacity-95 border-t-2 border-x-2 border-[#bf963c] ring-1 ring-[#f2c567] ring-offset-2 ring-offset-[#fefecc]">

   
        <svg className="animate-bounce" height={40} viewBox="0 -960 960 960" fill="url(#xgold)">
          <path d="M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z" />
          <defs>
            <linearGradient id="xgold" x1="100%" x2="0%" y1="95%" y2="0%">
              <stop stopColor="#bf963c" offset="0%" />
              <stop stopColor="#fefecc" offset="50%" />

              <stop stopColor="#f2c567" offset="100%" />
            </linearGradient>
          </defs>
        </svg>
        <svg className="mb-4" height={30} width={120} viewBox="0 -30 160 30" fill="url(#xgold)">

          <text fontSize="1.9rem">
           เข้าเล่นเกมส์
           
          </text>

        </svg>
        
    </div>
      </EzLink>
      
{/* ⇧ End   __________________________  เข้าเล่นเกมส์  ___________________________ End ⇧ */}


{/* ⇩ Start  `````````````````````````  ถอน  ````````````````````````` Start ⇩ */}

      <EzLink className=" mb-2" href={{pathname:"/line"}}>
 <div className="flex flex-row drop-shadow items-center justify-center gap-1 max-h-28 hover:opacity-95">
  
      <svg className="2xsm:block hidden" height={30} viewBox="0 -960 960 960"  fill="url(#xgold)">
        
        <path d="M254.04-371.62h306.58l55.96-55.96H254.04v55.96Zm0-159.19h214.34v-55.96H254.04v55.96Zm-90-145.96v395.15h306.58l-55.96 55.96H108.08v-507.07h743.84v125.77h-55.96v-69.81H164.04Zm715.34 201.04q4.62 4.63 4.62 10.27 0 5.63-4.62 10.26l-32.88 32.81-46.23-47.22 32.88-32.89q4.2-4.42 9.73-4.42 5.54 0 9.93 4.42l26.57 26.77ZM526.96-148.81v-46.73l250.39-250.38 46.73 46.73-250.39 250.38h-46.73ZM164.04-676.77v395.15-395.15Z"/></svg>

        

        <svg className="" height={30} viewBox="0 -25 50 30" fill="url(#xgold)">
          <text fontSize={28}>
            ถอน
            {/*          <tspan fontSize="50" x="0" dy="45">BFLIX</tspan>
            <tspan fontSize="14" x="0" dy="20">www.BFLIX579.com</tspan> */}
          </text>
          
        </svg>
            </div>
      </EzLink>

{/* ⇧ End   __________________________  ถอน  ___________________________ End ⇧ */}

  </div>
}
