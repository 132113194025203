'use client'
import { useActionState } from 'react'


export function EzForm({
children,
actionFn
}:{
children:React.ReactNode
actionFn?:any
}){


const [msg,dispach] = useActionState(actionFn,null)

    return (

    <form action={dispach} className='flex flex-col bg-slate-800 p-2'>
   {children}

   </form>

    )
}