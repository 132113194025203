"use client"

import { useEffect, useState } from "react"

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, s_S<T>] {

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage?.getItem(key)
      return item && JSON.parse(item) || initialValue

    } catch (e) { return initialValue }
  })

  useEffect(() => {
    try {
      localStorage?.setItem(key, JSON?.stringify(
        typeof storedValue === "function"
          ? storedValue(key) : storedValue))

    } catch (error) {
      localStorage?.setItem(key, JSON?.stringify(initialValue))
    }

  }, [key, storedValue])

  return [storedValue, setStoredValue]
}


