'use client'

import { LogOutBtn } from '../../LogOutBtn';
import { useUserUiContext } from '@/lib';
import { memo } from 'react';
import { EzLink } from '../../EzLink'

export const UserNavMenu = memo( function  ()
{
  const { setNavOpen, siteNav } = useUserUiContext()
  const {userInfo} = useUserContext()


  plog("UserNavMenu:", userInfo)

  return (
    <div className='UserNavMenu' ref={siteNav}>
      <div className={`flex flex-col`}></div>
      <div className={`flex flex-col items-center absolute right-0 w-full px-4 h-full sm:w-[20vw] sm:min-w-80 bg-black bg-opacity-50`}>
       <Icons iconName='account_circle' className={`h-fit text-6xl`}/>
       <h1 className='text-title-lg'>
       {userInfo?.account?.username?.toUpperCase()}</h1>
      <span className='text-center'>
        
        <i className='rounded-md'>Your balance is</i>
        <h1>{userInfo?.account?.credit}</h1>

      </span>
     
        

<div className={`flex flex-col justify-center w-full gap-y-3 relative`}>

<EzLink
        className='menu-btn w-full'
        href={{pathname:"/changepassword"}}
        onClick={() => setNavOpen(false)}
      >

        ข้อมูลส่วนตัว

      </EzLink>

      <EzLink
        className='menu-btn w-full'
        href={{pathname:"/changepassword"}}
        onClick={() => setNavOpen(false)}
      >

        เปลี่ยนรหัสผ่าน

      </EzLink>


      <EzLink
        className='menu-btn w-full'
        href={{pathname:"/line"}}
        onClick={() => setNavOpen(false)}
      >
        ฝาก/ถอน ผ่าน LINE
      </EzLink>

      </div>
<div  className={`flex flex-col justify-center w-full gap-5 relative my-auto`}>
      <EzLink
        className='menu-btn w-full '
        href={{pathname:"/lobby"}}
        onClick={() => setNavOpen(false)}
      >

        ทางเข้า  เกมส์ล็อบบี้
      </EzLink>

      <LogOutBtn className='mt-8' navOpen setNavOpen={setNavOpen} />
      </div>


    </div>
    </div>
  )
}
)