'use client'

import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { EzLoading } from "../../../EzLoading";



export function LNav_Btn ({
  MenuLink,
  PathName,
  Active,
  SetActive
}: {
  MenuLink: BoMenuLink,
  PathName: string,
  Active: any
  SetActive?: any
})
{

  const [active, setActive] = useState<boolean>(PathName.length >= 2 ? Active ?? PathName === (MenuLink?.href || PathName.includes(MenuLink?.href as string)) : false)
  useEffect(() =>
  {
    celog("PathName.length=", PathName.length)
    return SetActive && SetActive(active)
  }, [active, setActive])
  return MenuLink && <EzLoading >
    <Link
    href={{pathname:MenuLink.href}}
    className={`relative flex items-center gap-2.5 px-4 py-2 font-medium text-bodydark duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 `} >

    {MenuLink.iconL && <Icons iconName={MenuLink.iconL} />}
    {MenuLink?.title}
  </Link></EzLoading>
  /*         <button
            className={`relative flex items-center gap-2.5 px-4 py-2 font-medium text-bodydark duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 `} >
  
            {MenuLink.iconL && <Icons iconName={MenuLink.iconL} />}
            {MenuLink.name}
          </button> */
}
// ⇧ End   __________________________  LNav_Btn  ___________________________ End ⇧

// ⇩ Start  `````````````````````````  LNav_Group_Btn  ````````````````````````` Start ⇩

export function IfLink ({ ...ags })
{
  const { href } = ags
  return href ? <Link  {...ags as Omit<typeof ags, "className">} href={href} className={`hover:translate-x-0.5 hover:-translate-y-0.5 dark:hover:bg-meta-4 hover:shadow hover:opacity-80 ${ags?.className}`}

  >
  </Link> :
    <button  {...ags as Omit<typeof ags, "className">}
      tabIndex={-1}
      className={`inline-block hover:cursor-default  bg-opacity-50 border-b shadow ${ags?.className}`}
    >

    </button>
}

export function LNav_Group_Btn ({
  MenuLink, SetActive
}: {
  MenuLink: BoMenuLink
  SetActive?: s_S<boolean>
})
{
  const pathname = usePathname()
  const [active, setActive] = useState(false)
  const [sideBarExpanded, setSideBarExpanded] = useState(true)
  
  useEffect(() =>
  {
    if (MenuLink.href?.match(pathname))
    {
      setActive(true)
      SetActive && SetActive(true)
    }
    return () =>
    {
      setActive(false)
      SetActive && SetActive(false)
    }
  }, [pathname])
  return (
    <EzLoading>
      {/* // ⇩ Start  ````````````````````  HeaderGroup  ```````````````````` Start ⇩ */}
      <div className={`flex flex-col justify-start items-end min-w-full max-h-fit`}>
        <IfLink className={`flex flex-row  pl-4   text-sm 
          ${active ? "font-bold bg-darkLbtn shadow-1  text-bodydark-1 dark:text-bodydark-1 bg-opacity-60":"text-meta-4 dark:text-bodydark-1"}`}
          href={MenuLink?.href}
          prefetch={MenuLink?.prefetch}
        >
          <div className={`flex align-middle items-center z-999 min-w-60 pl-10  py-1 pr-0 mr-0`}
            onClick={(e) =>
            {
              if (MenuLink?.subMenu)
              {
                e.preventDefault()
                setSideBarExpanded(n => !n)
              }

            }}
          >
            {
              MenuLink?.iconL && <Icons className=""
                iconName={MenuLink?.iconL}
              />}
            <span className="w-full">{MenuLink?.title || MenuLink?.name}</span>
            {
              MenuLink?.iconR && <Icons
                className={`duration-300 mr-0 pr-0 max-w-10 ${sideBarExpanded ? "rotate-0 " : "rotate-180"}`}
                iconName={MenuLink?.iconR}
              />
            }

          </div>
        </IfLink>
        {
          MenuLink?.subMenu && <div className={`flex flex-row
              duration-300 ease-in-out  overflow-y-clip 
              translate-y-0 w-full
              ${sideBarExpanded ? "max-h-300" : " max-h-0"}
              `}>
            <div className={`flex flex-col w-full duration-300 ease-in-out origin-top`} >
              {
                MenuLink?.subMenu?.map((i: any, k) =>
                {
                  return <EzLoading key={k}>
                    <LNav_Group_Btn MenuLink={i} SetActive={setActive} />
                  </EzLoading>
                })
              }

            </div>
          </div>}


      </div>

      {/* // ⇧ End   ____________________  HeaderGroup  ____________________ End ⇧ */}

    </EzLoading>
  )
};

