"use client"
import { memo, useEffect } from "react"
import { EzLoading } from ".."
import { BfxAGBalance } from "@/lib"
export { }
export const AGShowBalance = memo( function  ({ agInfo, setAgInfo }: { agInfo?: AgInfo, setAgInfo?: s_S<AgInfo | undefined> })
{
    useEffect(() =>
    {
      fxlog("BfxAGBalance=", agInfo)
      agInfo?.username&&BfxAGBalance(agInfo?.username).then(res =>
      {
        agInfo?.username && res?.total_credit &&
           setAgInfo&&setAgInfo({ ...agInfo, balance: res?.total_credit })
      }    )
    }, [])
  return (
    /* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */
    <div className="mx-4 text-title-lg">
      <span className="text-right text-red  " aria-live="polite">
       <EzLoading> {agInfo?.balance && `😒 ${agInfo?.balance.toDDString(2)}`}</EzLoading>
      </span>
    </div> 
  )
  /* ⇧ End   __________________________    ___________________________ End ⇧ */
}
)