'use client';


import { CardOverLay, EzLink, EzLoading, HistoryLists } from "@/Components"
import type { BfxGameProvider } from "@/lib"
import { startTransition, useEffect } from 'react'
import { EzAPI } from "../../lib/apiList"
import { fetchJ } from "../../lib/type"
export function GamesLobby ()
{

  const { bfxGameProviderList, setBfxGameProviderList, userInfo } = useUserContext();
  plog( 'LobbyPage' );


  useEffect( () =>
  {
    if ( bfxGameProviderList[ 1 ] ) return;



    startTransition( async function ()
    {
      const i = await fetchJ<BfxGameProvider[]>( EzAPI.providersList );
      fxlog( "BfxGameProvider[]=", i );
      setBfxGameProviderList( i );

    } );
  }, [ bfxGameProviderList ] );

  const endpoint = 'https://m8geqpm2kpnf78dkfxp9ksg94ndx9weaoa39.com';
  const path = "/login";

  return (
    <>
      <div className="text-center mt-10 pb-12 md:pb-16">
        <EzLoading >{ userInfo?.account?.session?.[ 'l-token' ] &&
          <EzLink href={ { pathname: `${ endpoint + path}`,query:{ltoken:userInfo?.account?.session?.[ "l-token" ],returnUrl:location.origin + "/lobby"} } } target="_blank"
           /*  onClick={async () => sendGTMEvent({ event: 'Pageview', pagePath: '/oldLobby', visitorType: 'customer', pageTitle: 'เข้าหน้าเล่นเก่า', })} */ >
            <h4 className="my-6 fade-d aniDelay-1 text-pink-300">ทางเข้าล็อบบี้เวอร์ชั่นเก่า คลิก</h4>
          </EzLink>

        } </EzLoading>
        <EzLoading >  {
          userInfo?.account?.historyPlay && ( <>
            <h2 className="my-6 shadow-xl fade-d aniDelay-1 text-gray">เกมส์ที่เล่นล่าสุด</h2>
            <div className="flex justify-center gap-x-5 gap-y-10 fade-u aniDelay-2 overflow-x-auto flex-row-reverse max-h-[100vh]  overflow-y-hidden ">

              <EzLoading> { bfxGameProviderList &&

                <HistoryLists HistoryLists={ userInfo?.account?.historyPlay } gamesProviders={ bfxGameProviderList } /> }
              </EzLoading>
            </div>
          </> )
        }
        </EzLoading>
      </div>

      <div className='text-center mt-10 pb-12 md:pb-16'>
        <h2 className="my-6 shadow-xl fade-d aniDelay-3 text-gray">ค่ายเกมส์ชั้นนำ</h2>

        <div className=" flex justify-between z-9 flex-wrap pt-10 gap-x-2 fade-u aniDelay-4 xsm:gap-x-3 gap-y-10 overflow-y-hidden">

          <EzLoading >
            { bfxGameProviderList?.map( ( i, k ) => i?.name && <CardOverLay key={ k } GamesProvider={ i } className={ `max-w-[30vw]` } />
            ) }
          </EzLoading>
        </div>
      </div>
    </>


  );
}
