'use client'

export {}

export function SvgText({
  text,fontSize
}:{
  text:string,fontSize:number
}){
  const textlentg = text.length
  fnclog('text.length:',text.length)
    return(
    <svg  viewBox={`0 0 ${text.length*fontSize/2} ${fontSize}`} xmlns="http://www.w3.org/2000/svg" className="inline-flex bg-boxdark">
    <defs>
      <linearGradient id="xgold" x1="0" x2="0" y1="0" y2="100%">
        <stop stopColor="#bf963c" offset="0%" />
        <stop stopColor="#fefecc" offset="20%" />
        <stop stopColor="#aa7f21" offset="46%" />
        <stop stopColor="#f3dd8a" offset="78%" />
        <stop stopColor="#f2c567" offset="100%" />
      </linearGradient>
    </defs>

    <text fontSize={fontSize} style={{textAlign:'justify'}} fontFamily="Noto Sans Thai" fill="url(#xgold)" >
<tspan x={0} dy={`1em`}>{text}</tspan>
    </text>
  </svg>
  )
}

