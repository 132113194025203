'use client'
import { ReactNode, Suspense } from "react"
import { Skaleton } from "./Utils/Skaleton"


export function EzLoading ({
  children,
  fallback
}: {
  children: ReactNode
  fallback?: ReactNode
})
{
  
  return <Suspense fallback={fallback || <Skaleton />} >{children}</Suspense>
}
