"use client"

import { useAdminUiContext } from "@/lib";
import { EzLoading } from "../../../EzLoading"
import { LNav_Group_Btn } from "./L_Nav_Btn"
import { memo } from "react"


export const LNav_Menu = memo(function ({
  MenuLink = initMenuLink
}: {
  MenuLink?: BoMenuLink[]
})
{

  const { sideBarOpen } = useAdminUiContext()
  // ⇩ Start  `````````````````````````  Start JSX  ````````````````````````` Start ⇩
  celog("sideBarOpen=", sideBarOpen)
  return <aside
    className={
      `relative flex  z-99 justify-end dropShadow-5 duration-300 ease-linear
  ${sideBarOpen ? "min-w-60" : "min-w-10"}  dark:bg-gd-royal bg-gd-mystic `
    }>
    <nav className={`
      fixed flex flex-col min-w-60 w-full no-scrollbar overflow-y-auto overflow-x-clip duration-300 ease-linear `}>
      {MenuLink?.map((i, k) =>
        <EzLoading key={k}>
          <LNav_Group_Btn
            MenuLink={i}
          />
        </EzLoading>
      )}
    </nav>
  </aside>

})