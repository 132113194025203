export { }
export async function readTextFromClipboard ()
{

    const rtext = await navigator.clipboard.readText()
        .catch(() => navigator.clipboard.read()
            .then(items => items[0].getType("text/plain"))
            .then(textBlob => (new Response(textBlob)).text())
        )
        .catch((e) => { throw e })
    return rtext
}
export function copyToClipboard ()
{
    fxlog('navigator.clipboard.writeText(window.location.toString())', window.location.toString())
    navigator.clipboard.writeText(window.location.toString())
}