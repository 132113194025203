'use client'

import { sendGTMEvent } from "@next/third-parties/google"
import { useRouter } from "next/navigation"
import { memo, useEffect, useState } from "react"


export const InstallBanner = memo( function () {

  const {
    showInstallBanner,
    setShowInstallBanner
  } = useUserUiContext()

  const router = useRouter()

 const [deferredPrompt,setDeferredPrompt] = useState<any>(null)
 const [isStandalone,setIsStandalone] = useState<boolean>(window.matchMedia('(display-mode: standalone)').matches||window.matchMedia('(display-mode: fullscreen)').matches)

async function handleClickInstall() {

  async () => sendGTMEvent({ event: 'Pageview', pagePath: '/installApp',visitorType: 'customer',pageTitle: 'ติดตั้งแอพ', })
  fnclog('deferredPrompt=',deferredPrompt)
  if (!deferredPrompt) return
  fnclog('deferredPrompt=',deferredPrompt)
  const result = await deferredPrompt.prompt();
  
    /* fnclog('result.outcome=',result.outcome) */

    if (result.outcome && result.outcome === 'accepted') {
      setDeferredPrompt(null)
      sendGTMEvent({ event: 'installedApp' })
      setShowInstallBanner(false)

    }
    else {
      router.push('intent://betflix579.com#Intent;scheme=http;package=com.android.chrome;end')
    }
}

  useEffect(() =>{
    isStandalone||setIsStandalone(window.matchMedia('(display-mode: standalone)').matches||window.matchMedia('(display-mode: fullscreen)').matches)
    function AppInstalled(){
            tlog('setShowInstallBanner isStandalone=',isStandalone)
            if(isStandalone) {
              setDeferredPrompt(null)
              setShowInstallBanner(false)
              sendGTMEvent({ event: 'openPWA' })
              fnclog('null=',deferredPrompt)
              return true
            }else return false
          }

   async function installPrompt(e:Event) {
      e.preventDefault()
      setDeferredPrompt(e)
      fxlog("setDeferredPrompt=",deferredPrompt)
  
     if(!showInstallBanner) return
     fxlog("showInstallBanner=",showInstallBanner)
    setShowInstallBanner(true)
    tlog('installPrompt=',deferredPrompt)
    }

    if(AppInstalled()) return
  window.addEventListener('beforeinstallprompt',installPrompt)
return () => window.removeEventListener('beforeinstallprompt',installPrompt)
  },[])
  return <div className={`fixed z-999 size-fit ml-auto self-end place-self-end justify-self-end bottom-0 right-0 w-full md:bottom-15 md:right-12 md:w-auto ${showInstallBanner?'fade-u aniDelay-5':'hidden'}`}>
    <div className="text-slate-50 bg-luxury md:text-sm text-lg p-2 md:rounded shadow-lg flex justify-between">
      <div className='text-slate-500 inline-flex'>


        <button className="font-medium hover:underline text-slate-50 text-center"
          onClick={handleClickInstall}>
          <span className="sm:inline-flex">ติดตั้ง APP BFLIX </span></button>
        <span className="italic px-1.5 hidden 2xsm:inline-block">หรือ</span>
        <button className="font-medium hover:underline text-emerald-400 hidden 2xsm:inline-block"
          onClick={handleClickInstall}>เพิ่มไว้หน้าแรก</button>
      </div>

      <button className="text-slate-500 hover:text-slate-400 pl-2 ml-3 border-l border-gray-700"
        onClick={() => setShowInstallBanner(false)}>
        <span className="sr-only">Close</span>
        <svg className="w-4 h-4 shrink-0 fill-current" viewBox="0 0 16 16">
          <path d="M12.72 3.293a1 1 0 00-1.415 0L8.012 6.586 4.72 3.293a1 1 0 00-1.414 1.414L6.598 8l-3.293 3.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L9.426 8l3.293-3.293a1 1 0 000-1.414z" />
        </svg>
      </button>
    </div>
  </div>

}
)