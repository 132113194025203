'use client'

import { type BfxGameProvider, type Games, bfxUserURL, updateOne, useUserContext, useUserUiContext } from "@/lib"
import { useEffect } from "react"
import { EzLoading } from "../EzLoading"



export function GameFrame ( {
  thisprovider,
  gamename
}: {
  thisprovider: BfxGameProvider
  gamename?: string
} )
{

  const users: MongoDB = { colName: 'users', dbName: 'bfx' }

  const { userInfo, setUserInfo } = useUserContext()
  const { navOpen, setNavOpen, gamePlayState, setGamePlayState, GameframeRef } = useUserUiContext()

  const gamecode: Games | undefined = gamename && ( thisprovider?.gameList?.find( f => f?.name?.toUrl().match( new RegExp( `${ gamename }`, 'i' ) ) ) ) || undefined


  const returnUrl = "?returnUrl=" + location.origin + "/lobby"
  const gameendpoint = '/bfx/play'





  useEffect( () =>
  {
    if ( !userInfo?.account?.session?.[ "u-token" ] || !userInfo?.account?.session?.[ "l-token" ] ) return
    fnclog( 'u-token:', userInfo?.account?.session?.[ "u-token" ], "l-token", userInfo?.account?.session?.[ "l-token" ] )
    updateOne( users, { username: userInfo?.account?.username }, {
      $setOnInsert: {

        historyPlay: [ {
          provider: thisprovider?.game,
          gamecode: gamecode,
          updateAt: Date.now(),
        } ]
      }
    }
    )
    /* 
        setUserInfo(n =>
        {
          if (n && thisprovider?.game)
          {
            return n?.historyPlay && {
              ...n, historyPlay: [...n?.historyPlay?.filter(f => (f?.provider != thisprovider?.game && f?.gamecode != gamecode)), {
                provider: thisprovider?.game,
                gamecode: gamecode
              }]
            } || {
              ...n, historyPlay: [{
                provider: thisprovider?.game,
                gamecode: gamecode
              }]
            }
          }
        }) */
  }, [] )


  if ( !userInfo?.account?.session?.[ "l-token" ] )
  {
    !navOpen && setNavOpen( true )
  } else if ( !userInfo?.account?.session?.[ "u-token" ] )
  {
    fetch( bfxUserURL.Balance, {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "l-token": userInfo?.account?.session[ "l-token" ],
      },next:{revalidate:60,tags:["Balance/"+userInfo?.account?.session[ "l-token" ]]}
    } ).then( res => res.clone().json() ).then( res => res?.data?.Session?.[ "u-token" ] && setUserInfo( n =>
    {
      return n && {
        ...n, account: {
          ...n.account,
          session: {
            ...n?.account?.session,
            "u-token": res?.data?.[ "u-token" ]
          }, credit: res?.data?.credit
        }
      }
    } ) )

  } else
  {
    const srcUrl = `${ gameendpoint }/${ gamecode?.img?.match( /.*qtech.*/gi ) ? "qtech" : thisprovider?.game }/${ userInfo?.account?.session?.[ "u-token" ] }/${ userInfo?.account?.session?.[ "l-token" ] }/${ gamecode?.code || 'direct' }/thai${ returnUrl }`

    /* celog('GameFrame:', srcUrl) */

   /*  if(thisprovider?.game=='pg'){
      window.open(srcUrl,'_self')
      
    }
    else */ return <EzLoading ><iframe data-fade="fade-zoom-in" className='top-0 left-0 aniDelay-[2s]  size-screen overflow-hidden fixed z-9' src={ srcUrl } seamless
      onSuspend={ () => fxlog( "onSuspend" ) }
      ref={ GameframeRef }
      onFocus={ () => ( setGamePlayState( true ) ) }

      loading="lazy"
    />

      <div className="fixed size-screen flex justify-center items-center top-0 left-0 -z-1">
        <span className="cubic-loader mx-auto " /></div></EzLoading >
  }

}
