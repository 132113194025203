'use client'
import { type ReactNode } from 'react'


export function UI_L_Nav_Header({
children,
trigger,
sideBarOpen,
setSideBarOpen,

}:{
children:ReactNode
trigger:any,
 sideBarOpen: any,
  setSideBarOpen: any,

}){
    return (
            
   <div className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5 ">
    {children}
        <button
          ref={trigger}
          onClick={() => setSideBarOpen((n: boolean) => !n)}
          aria-controls="sidebar"
          aria-expanded={sideBarOpen}
          className="block"
        />
    
      </div>



    )
}