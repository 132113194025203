'use client'

import { memo, useEffect, useMemo, useState } from "react"
import { EzAPI, fetchJ } from "../../lib"
import { EzLoading } from "../EzLoading"
import { } from "../Utils"
import { ArticleCard } from "./ArticleCard"

export { }
export const Articles = memo( function ()
{
  const [ posts, setPosts ] = useState<Article[]|undefined>(undefined )
  const setmemoposts = useMemo( () => setPosts, [ posts ] )
  useEffect( () =>
  {


    posts ?? fetchJ<Article[]>( EzAPI.article ).then( res =>
    {
      /* fnclog( "fetchJ<Article[]>", res ) */
      if ( res )
      {
        setmemoposts( res )
      }
    } )

  }, [ setmemoposts ] )

  return <section id='relative'>
      {/* Section background (needs .relative class on parent and next sibling elements) */}

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20 text-gray drop-shadow-sm">
            <h2 className="my-4  ">ยินดีต้อนรับสู่ BFLIX579.COM</h2>
            <h3> แอพลิเคชั่น คาสิโนออนไลน์
               
            </h3>
            <h3>
              WELCOME TO BFLIX579 ONLINE CASINO
            </h3>
          </div>

          {/* Items */}
          <div className="flex gap-6 flex-wrap justify-center">
            {/* 1st item */}
                {posts?.map((i: Article, k) => 
                  <EzLoading key={ k }><ArticleCard articleCard={ i } /></EzLoading>
                )}
        </div>
    
  </section>

} )