'use client'
import { type JSX, useActionState, useEffect, useState } from 'react'
import { EzLoading } from '../EzLoading'
import { BfxGetUserBalance, BfxTransfer, type bfxUsers } from '../../lib'


export function CreditTransferOnlyBalanceBTN ( {
  setSlot,
  SetUserBalance,
  SetResponse,
  userId
}: {
  setSlot?:s_S<JSX.Element|null>
  userId?:string
  SetResponse?: s_S<any>
  SetIsPending?: s_S<boolean>
  SetUserBalance?: s_S<bfxUsers | undefined>
}

)
{
  const [ response, actionFrom, isPending ] = useActionState( BfxTransfer, null )
  const [ username, setUsername ] = useState<string>( userId||'')

  useEffect( () =>
  {
    response && SetResponse && SetResponse( response )
  }, [ isPending ] )

  return (

    <form id={ "Transaction" } action={ actionFrom } className={`absolute`} onBlur={()=>setSlot!(null)}>


      <input type="text" className={"hidden"} id="username" name="username" required
        value={ username }
      />
      <div className={ `flex flex-row size-full` }>

        <input
        className={`bg-gd-royal z-999`}
        tabIndex={0}
        autoFocus={true}
          type="number"
          id="amount"
          name="amount"
          step={ 0.01 }
          placeholder={ "0.00" }
          required
          onFocus={ () =>
          {
            if ( SetUserBalance && username.match( /^[Bb]4466[0-9]{3,6}$/ ) )
            {
              fxlog( "username=", username )

              BfxGetUserBalance( username )
                .then( res =>
                {
                  fxlog( "BfxGetUserBalance=", res )
                  res.status && SetUserBalance( res )
                }
                )
            }
          } }

        />


        <button  disabled={ isPending } type="submit" >
          <EzLoading>
            { isPending ? <svg tabIndex={ -1 } className={ `absolute inline animate-spin max-h-full dark:text-white` } fill="none" viewBox="0 0 24 24">

              <circle className="opacity-25" tabIndex={ -1 } cx="12" cy="12" r="0" stroke="currentColor" strokeWidth="4">

              </circle>
              <path tabIndex={ -1 } className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg> :

              <span tabIndex={ -1 } className="text-lg">
                { response?.status &&
                  response?.status == "success" ? `✔️` : response?.status == "error" ? `❌` : '💲' }  </span>
            } </EzLoading>
        </button>


      </div>

    </form>

  )
}
