'use client'
import Link from "next/link";
import { FacebookIcons, LineIcons } from "../Icons";
import { useUserUiContext } from "@/lib";

export function ContactsBtn() {
    const {gamePlayState } = useUserUiContext()
    return <div className={`fixed flex-col top-3/4 items-center z-9 drop-shadow bg-gradient-to-tl from-[#0005] to-[#2235] hover:opacity-95 p-2 right-0 gap-2 rounded-l-lg aniDelay-10 ${gamePlayState?'translate-x-[200%]':'fade-l'} flex`} >
        <Link href={{pathname:"/fb",search:"utm_source=ContactsBtn"}} className="flex">
        <FacebookIcons width={40} height={40}/>
        </Link>

        <Link href={{pathname:"/line",search:"utm_source=ContactsBtn"}} className="flex">
            <LineIcons width={40} height={40}/>
        </Link>
    </div>

}
