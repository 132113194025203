'use client'
import { createContext, useContext } from 'react'



export const usedsvSiteContext = createContext<DsvSiteContext>({
    metaDataTemplate: [], setMetaDataTemplate: () => { },
    contentArticleList: [], setContentArticleList: () => { },
    customersList: [], setCustomersList: () => { },
})

export const useDsvSiteContext = () => useContext(usedsvSiteContext)






