'use client'

import { type HTMLAttributes, memo } from "react"
import type { TableHeader } from "."


export const THead = memo( function ( { ...ags }: HTMLAttributes<HTMLTableSectionElement> & { tableHeader: TableHeader[] }
    
    ){
  return <thead { ...ags as Omit<typeof ags, "TableHeader"> }>
      <tr>
        { ags?.tableHeader?.map( ( i, k ) =>
        {
          return (
            <th
              key={k}
              className={` p-1 ${i?.className||''}`}>
              {i?.name||''}
            </th>
          )
        })}
      </tr>
    </thead>
  }
)
