'use client'

import { usebfxContext, useLocalStorage, type BfxGameProvider, type BfxCreditRefund, useSessionStorage, type BfxTransaction, type customer } from "@/lib"

export function BFXContextProvider ({
  children
}: {
  children: React.ReactNode
})
{

  const [bfxUserList, setBfxUserList] = useLocalStorage<customer[]>('bfxUserList', [])
  const [bfxGameProviderList, setBfxGameProviderList] = useLocalStorage<BfxGameProvider[]>('bfxGameProviderList', [])
  const [bfxCreditRefundList, setBfxCreditRefundList] = useLocalStorage<BfxCreditRefund[]>('bfxCreditRefundList', [])
  const [bfxTransactionList, setBfxTransactionList] = useSessionStorage<BfxTransaction[]>('bfxTransactionList', [])

laylog("BFXContextProvider")

  return <usebfxContext.Provider value={{
    bfxUserList, setBfxUserList,
    bfxGameProviderList, setBfxGameProviderList,
    bfxCreditRefundList, setBfxCreditRefundList,
    bfxTransactionList, setBfxTransactionList
  }}>
    {children}
  </usebfxContext.Provider>
}
