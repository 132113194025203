'use client'
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';

export function NavBarLogOutBtn({setNavOpen,userLogOut,href}:{setNavOpen?:any,userLogOut?:any,href?:string}) {
    const router = useRouter()
    const pathname = usePathname()
  return (

    <Link href={{pathname:href}}>   <button
     onClick={() => {
     log("<<<<<<<<********NavBarLogOutBtn****>>>>>>>>>>>>>>>>>")
      userLogOut()
      setNavOpen(false)
      if(pathname=='/')
      router.push('/bflix')
    }}
  >
      
 ออกจากระบบ LOGOUT  </button>
  </Link>
  )
}
