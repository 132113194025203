'use client'

import { MongoSrv } from "../../lib/MongoDb"




let subscription: PushSubscription | undefined
if (!process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY)
{
      throw new Error("process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY")
}
const SERVICE_WORKER_FILE_PATH = "/sw.js"
const NEXT_PUBLIC_VAPID_PUBLIC_KEY = process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY
/* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */
export async function registerAndSubscribe (
      onSubscribe: (subs: PushSubscription | null) => void,
): Promise<void>
{
      try
      {
            await navigator.serviceWorker.register(SERVICE_WORKER_FILE_PATH)
            await subscribe(onSubscribe)
      } catch (e)
      {
            console.log('Failed to register service-worker: ', e)
      }
}

/* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */
export async function subscribe (onSubscribe: (subs: PushSubscription | null) => void): Promise<void>
{
      return await navigator.serviceWorker.ready
            .then((registration: ServiceWorkerRegistration) =>
            {
                  return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: NEXT_PUBLIC_VAPID_PUBLIC_KEY,
                  })
            })
            .then((subscription: PushSubscription) =>
            {
                  console.log('Created subscription Object: ', subscription.toJSON())
                  // submit subscription to server.
                  submitSubscription(subscription.toJSON() as unknown as any).then(_ =>
                  {
                        onSubscribe(subscription.toJSON() as unknown as any)
                  })
            })
            .catch(e =>
            {
                  console.log('Failed to subscribe cause of: ', e)
            })
}

/* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */
export async function submitSubscription (subscription: PushSubscription): Promise<void>
{
      const endpointUrl = '/api/notify'
      const res = await fetch(endpointUrl, {
            method: 'POST',
            headers: {
                  'Content-Type': 'application/json',
            },
            body: JSON.stringify({ subscription }),
      })
      const result = await res.json()
      console.log("submitSubscription", result)
      return await result
}

/* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */
export function checkPermissionStateAndAct (
      onSubscribe: (subs: PushSubscription | null) => void,
): void
{
      const state: NotificationPermission = Notification.permission
      switch (state)
      {
            case 'denied':
                  break
            case 'granted':
                  registerAndSubscribe(onSubscribe)
                  break
            case 'default':
                  break
      }
}

export async function setSubScribeUser ()
{

      const sub = await navigator.serviceWorker?.ready
            .then(async registration =>
                  await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: NEXT_PUBLIC_VAPID_PUBLIC_KEY
                  }))
      subscription = sub.toJSON() as unknown as any
      tlog("setSubScribeUser", subscription)
      await MongoSrv.updateOne({ colName: "subscribeUser", dbName: "bfx" }, { endpoint: subscription?.endpoint }, { $set: { ...subscription } }, { upsert: true })
      // In a production environment, you would want to store the subscription in a database
      // For example: await db.subscriptions.create({ data: sub })
      return subscription
}

export async function unsubscribeUser ()
{
      await MongoSrv.deleteOne({ colName: "subscribeUser", dbName: "bfx" }, { endpoint: subscription?.endpoint })
      subscription = undefined
      // In a production environment, you would want to remove the subscription from the database
      // For example: await db.subscriptions.delete({ where: { ... } })
      return { success: true }
}
