"use client"
import { createContext, useContext } from "react"
export { }


export const useadmincontext = createContext<AdminContext>({
    adminInfo: undefined, setAdminInfo: () => { },
    hasSession: false, setHasSession: () => { },
    agInfo: undefined, setAgInfo: () => { },
})

export const useAdminContext = () => useContext(useadmincontext)
