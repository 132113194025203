
import Image, { type ImageProps } from 'next/image'
import _nouser from '/public/image/nouser.jpeg'
import _Aandroid from '/public/image/Aandroid.png'
import _Adesktop from '/public/image/Adesktop.png'
import _Aios from '/public/image/Aios.png'
import _android from '/public/image/android.png'
import _desktop from '/public/image/desktop.png'
import _ios from '/public/image/ios.png'
import _BGSpotlight from '/public/image/BGSpotlight@1950.jpg'


export const BGSpotlight = (props: Partial<ImageProps>) => 
<Image {...props as ImageProps} src={_BGSpotlight} fill style={{ objectFit: 'cover' }} priority alt="BGSpotlight" />

export const NoUser = ({...props}) => 
<Image  width={160} height={160} alt={'NoUser'} placeholder='blur' src={_nouser} {...props as Pick<ImageProps,"width"|"height">}/>

export const Aandroid = (props: Partial<ImageProps>) => 
<Image  placeholder='blur'  width={props.width || 173} height={props.height || 60}  {...props as ImageProps}alt={props.alt || 'Aandroid'}src={_Aandroid} />

export const Adesktop = (props: Partial<ImageProps>) =>
<Image width={props.width || 173} height={props.height || 60} placeholder='blur' {...props as ImageProps}  alt={props.alt || 'Adesktop'} src={_Adesktop} />

export const Aios = (props: Partial<ImageProps>) => 
<Image width={props.width || 173} height={props.height || 60}  placeholder='blur' {...props as ImageProps} alt={props.alt || 'Aios'} src={_Aios} />

export const android = (props: Partial<ImageProps>) =>
<Image  width={props.width || 60} height={props.height || 60}  placeholder='blur' {...props as ImageProps} alt={props.alt || 'android'} src={_android} />

export const desktop = (props: Partial<ImageProps>) =>
<Image  width={props.width || 60} height={props.height || 60}   placeholder='blur'{...props as ImageProps} alt={props.alt || 'desktop'} src={_desktop} />

export const ios = (props: Partial<ImageProps>) => <Image  width={props.width || 60} height={props.height || 60}  placeholder='blur' {...props as ImageProps} src={_ios} alt={props.alt || 'ios'}/>

export const lineBF579 = (props: Partial<ImageProps>) =>
<Image {...props as ImageProps} width={props.width || 270} height={props.height || 54} alt={props.alt || 'lineBF579'} priority src='https://img2.pic.in.th/pic/lineBF579.png' />
/**
 * <Image {...props as ImageProps} width={props.width || 270  } height={props.height || 54  } alt={props.alt || 'NoUser'} src='https://img2.pic.in.th/pic/loginauto.png' />
 * @param props  
 * @returns 
 */
export const loginauto = (props: Partial<ImageProps>) => <Image {...props as ImageProps} width={props.width || 270} height={props.height || 54} alt={props.alt || 'loginauto'} priority src='https://img2.pic.in.th/pic/loginauto.png' />
export const picRegister = (props: Partial<ImageProps>) => <Image {...props as ImageProps} width={props.width || 270} height={props.height || 54} alt={props.alt || 'Register'} priority src='https://img2.pic.in.th/pic/Register.png' />




export const coinAnimate = (props: Partial<ImageProps>) => <Image  width={props.width} height={props.height} {...props as ImageProps} alt={'coin'} src='https://img5.pic.in.th/file/secure-sv1/coin-animate.webp' />


export const ButtonRegister = function ({
      text, width=275, height=55, className
}: {
      text?: string, width?: number, height?: number, className?: string
})
{
      let url = `/api/image/BtnRegister`
      text&&(url += `?t=${text}`)
      return <Image src={url} priority
                  className={ className ||''}
            width={ width }
            height={ height }
            sizes={"275px"}
            alt={text||"register"} />
}

export const ButtonLine = ({
      text, width=275, height=55, className
}: {
           text?: string, width?: number, height?: number, className?: string
}) =>
{
      let url = `/api/image/ButtonLine`
      text&&(url += `?t=${text}`)
      return <Image src={url} priority
            className={ className||'' }
            width={width}
            height={height}
            
            sizes={"275px"}
            alt={text||'@line'} />
}
