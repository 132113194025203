'use client'

import { Actions3Button, Ez, EzLoading, TableCell_Credit } from "@/Components"
import { memo, startTransition, useEffect, useState } from "react"
import { LineUI } from "../../images"
import { type customer, updateBfxUserList } from "../../lib"
import { THead } from "./THead"



export function MembersTable ()
{
  const { bfxUserList, setBfxUserList } = useBfxContext()

  async function getupdate ()
  {
    const res = await updateBfxUserList( 3000 )
    if ( res ) { startTransition( () => setBfxUserList( res ) ) }
  }
  const thead = [ { name: '#' }, { name: 'profilePic', className: 'max-w-10' }, { name: 'displayName' }, { name: 'Banking' }, { name: 'username' },{ name: 'Actions' }, { name: 'Credit' },  { name: 'updateAt' } ]
  useEffect( () =>
  {
    bfxUserList?.[ 0 ] ?? getupdate()

  }, [] )
  const [ userTable, setUserTable ] = useState( bfxUserList )
  const [ value, setValue ] = useState( '' )

  useEffect( () =>
  {
    startTransition( async () =>
      value == "" ? setUserTable( bfxUserList ) : setUserTable( bfxUserList.filter( i => new RegExp( `.*${ value }.*`, "igm" ).test( `${i?.account?.username}${i.bio?.bank?.[ 0 ]?.bank_no}${i.bio?.fullName}${i.bio?.phone}` ) ) )
    )
  }, [ value ] )
  return (
    <div className="max-w-full">
      <div className={ `border bottom-1 flex-row flex` }>
       
        <button className={ `menu-btn text-sm h-fit` } onClick={ getupdate }>Reloads</button>
        <Ez.Input className={ `text-md h-full` } placeholder={`ค้นหา`} value={ value } onChange={async e => setValue( e.currentTarget.value ) } />
        <p>{userTable.length}</p>
      </div>
      <table className="w-full relative table-auto">
        <THead className="text-center font-extrabold dark:text-bodydark1"
          tableHeader={ thead }
        />
      </table>

      <div className="flex-1 relative h-full max-h-[80vh] overflow-y-auto">

        <table className="w-full h-fit">
          <EzLoading > <MembersTBody usersList={ userTable.slice(0, 100) } /> </EzLoading>
        </table>
      </div>
    </div>

  )
}

export const MembersTBody = memo( function ( { usersList }: { usersList?: customer[] } )
{

  return <tbody className="flex-1">
    { usersList?.map( ( i, k ) =>
    {
      return <EzLoading key={ k } >
        <tr className="py-1 border-b dark:shadow-card">
          <td>{ k }</td>
          <td className='max-w-10'>
            <LineUI { ...i.bio?.line?.[ 0 ] } width={ 40 } height={ 40 } />
          </td>
          <td className="text-center px-4 text-black dark:text-white">
            { i?.bio?.fullName ?? 'ไม่มีชื่อ ' }
            { i?.bio?.disPlayName && <small className="text-sm">
              {/* >> Value _____*/  i?.bio?.disPlayName /* _____ Value << */ }
            </small> }
          </td>
          <td className="px-4">
            <li>{ `${ i?.bio?.bank?.[ 0 ]?.bank_name }  ${ i?.bio?.bank?.[ 0 ]?.bank_no }` || 'ยังไม่มี ธนาคาร' }</li>
            { i?.bio?.phone && <small>{ `Phone: ${ i?.bio?.phone }` }</small> }
          </td>
          <td className="px-4">
            {/*   <Ez.Input className="text-black dark:text-white" placeholder={i?.username ??'ยังไม่มียูสเซอร์'} /> */ }
            { i?.account?.username || 'ยังไม่มียูสเซอร์' }
          </td>
          <td className="text-center">
           <Actions3Button userId={i?.account?.username}/>
          </td>
          <td className={ `text-center px-4` }>
            <TableCell_Credit int={ i?.account?.credit } />
          </td>
        
          <td className="text-center">
            <div className={ `inline-flex rounded-full bg-opacity-10 px-3 py-1 font-medium ${ i?.account?.status
              ? "bg-success text-success"
              : "bg-danger text-danger"

              }` }
            >
              { i?.account?.status ? <Icons iconName="swords" /> : <Icons iconName="Person_Off" /> }
            </div>
          </td>
        </tr>
      </EzLoading>
    }
    )
    }
  </tbody>



}
)