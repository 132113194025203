'use client'

import { memo } from 'react'
import { } from '../Utils'
import { CardOverLay } from './gamesCard'
import { EzLoading } from '../EzLoading'
import type { BfxGameProvider, BfxHistoryPlay } from '@/lib'
export {}
export const HistoryLists = memo(function HistoryLists ({
  HistoryLists, gamesProviders, className
}: {
  HistoryLists?: BfxHistoryPlay[], gamesProviders: BfxGameProvider[], className?: string
}) {


  return HistoryLists?.map((i, k) => {
    const thisprovider = gamesProviders?.find(f => f?.game?.toUrl() == i?.provider)
    if (thisprovider) {
      if (i?.gamecode && i?.gamecode != "none") {
        const gameCard = thisprovider?.gameList?.find(g => g?.code?.toUrl() == i.gamecode)

        return gameCard && <EzLoading key={k}>
          <CardOverLay
            GamesProvider={thisprovider}
            gameCard={gameCard}
            className={`item-center max-w-[15vw] overflow-visible ${className ?? ''}`}
          />
        </EzLoading>


      } else return <EzLoading key={k}>
        <CardOverLay
          GamesProvider={thisprovider}
          className={`item-center max-w-[15vw] overflow-visible ${className ?? ''}`}
        /></EzLoading>

    }
  }


  )
})
