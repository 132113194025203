'use client'

import Link from "next/link";
import { usePathname } from 'next/navigation';
import { EzLoading } from "../../EzLoading"


export  function MenuBtn({
  className,
  name,
  Href
}: {
  className?: string,
  name?: string
  Href?: string
}) {
  const pathname = usePathname()
  return <EzLoading>{Href ? (
    <Link className={pathname == Href ? 'active disabled' : ' ' + className} aria-disabled={pathname == Href}  href={{pathname:Href}}>
{name}
    </Link>
  ) : (
    <span>{name}</span>
  )}</EzLoading>
}
