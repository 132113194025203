'use client'
import { memo } from "react"
import { ImgList } from "../../images"

export const Background = memo(function PageBackground ()
{
  return <ImgList.BGSpotlight className='absolute -z-9999 h-screen object-fill blur-sm bg-blend-color-dodge' />


})

export function getBackgroundImage (srcSet = '')
{
  const imageSet = srcSet
    .split(', ')
    .map((str) =>
    {
      const [url, dpi] = str.split(' ')
      return `url("${url}") ${dpi}`
    })

  return `image-set(${imageSet[0]})`
}
