import type { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime"
import { type RefObject } from "react"
import type { BfxCreditRefund, BfxGameProvider, BfxTransaction, bfxUsers, customer } from "../actions/bfxAPI/user/typeUser"
import { useAdminContext,
  useUserContext,
  useUserUiContext,
  useDsvSiteContext,
  useLineBotContext,
  useBfxContext } from "src/lib/Contexts";


declare global {
    export  interface DsvSiteContext
  {
    metaDataTemplate: MetaDataTemplate[], setMetaDataTemplate: s_S<MetaDataTemplate[]>
    contentArticleList?: ContentArticle[], setContentArticleList: s_S<ContentArticle[]>
    customersList: MemberInfo[], setCustomersList: s_S<MemberInfo[]>
  }
  export interface LineBotContext
  {
    chatBotEvent: ChatBotEvent[], setChatBotEvent: s_S<ChatBotEvent[]>
    lineChannelList: LineBotChannel[], setLineChannelList: s_S<LineBotChannel[]>
    lineUserList: LineUser[], setLineUserList: s_S<LineUser[]>
    lineBotAssistant: LineBotAssistant[], setLineBotAssistant: s_S<LineBotAssistant[]>
  }

  export interface UserContext
  {
    userInfo?: customer, setUserInfo: s_S<customer | undefined>,
    hasSession?: boolean, setHasSession: s_S<boolean | undefined>,
    bfxGameProviderList: BfxGameProvider[], setBfxGameProviderList: s_S<BfxGameProvider[]>
    historyGames: BfxGameProvider[], setHistoryGames: s_S<BfxGameProvider[]>
  }


  export interface BfxContext
  {
    bfxUserList: customer[], setBfxUserList: s_S<customer[]>
    bfxGameProviderList: BfxGameProvider[], setBfxGameProviderList: s_S<BfxGameProvider[]>
    bfxTransactionList: BfxTransaction[], setBfxTransactionList: s_S<BfxTransaction[]>
    bfxCreditRefundList: BfxCreditRefund[], setBfxCreditRefundList: s_S<BfxCreditRefund[]>
  }

  export interface AdminContext
  {
    adminInfo?: BoUser, setAdminInfo: s_S<BoUser | undefined>,
    hasSession?: boolean, setHasSession: s_S<boolean | undefined>,
    agInfo?: AgInfo, setAgInfo: s_S<AgInfo | undefined>
  }

  export interface UserUiContext
  {
    themeRef?: RefObject<any> | undefined, trigger?: RefObject<any> | undefined
    pageScroll?: RefObject<any> | undefined, siteNav?: RefObject<any> | undefined
    GameframeRef?: RefObject<HTMLIFrameElement|null>
    top: boolean, setTop: s_S<boolean>, pathname?: string, router?: AppRouterInstance | undefined
    navOpen: boolean, setNavOpen: s_S<boolean>,
    gamePlayState: boolean, setGamePlayState: s_S<boolean>,
    showInstallBanner: boolean, setShowInstallBanner: s_S<boolean>,
    delay?: number, setDelay: s_S<number | undefined>,
    themeContext?: "light" | "dark", setThemeContext: s_S<"light" | "dark">
  }
  export interface AdminUiContext
  {
    themeRef?: RefObject<any> | undefined, trigger?: RefObject<any> | undefined
    pageScroll?: RefObject<any> | undefined, siteNav?: RefObject<any> | undefined
    GameframeRef?: RefObject<any> | undefined
    top: boolean, setTop: s_S<boolean>, pathname?: string, router?: AppRouterInstance | undefined
    navOpen: boolean, setNavOpen: s_S<boolean>,
    showInstallBanner: boolean, setShowInstallBanner: s_S<boolean>,
    delay?: number, setDelay: s_S<number | undefined>,
    sideBarOpen: boolean, setSideBarOpen: s_S<boolean>,
    darkThemeContext: boolean, setDarkThemeContext: s_S<boolean>
    // ⇧ End   __________________________  Dependencies  ___________________________ End ⇧
 
 
  }


  var useAdminContext: () => AdminContext

  var useUserContext: () => UserContext
  var useUserUiContext: () => UserUiContext
  var useDsvSiteContext: () => DsvSiteContext
  var useLineBotContext: () => LineBotContext
  var useBfxContext: () => BfxContext
}

globalThis.useAdminContext = useAdminContext
globalThis.useUserContext = useUserContext
globalThis.useUserUiContext= useUserUiContext
globalThis.useDsvSiteContext = useDsvSiteContext
globalThis.useLineBotContext = useLineBotContext
globalThis.useBfxContext = useBfxContext
