'use client'
import Link from "next/link"
import { useActionState, useEffect, useRef, useState } from "react"
import { Ez } from ".."
import { BoCreateUser } from "@/lib"


export function UI_Bo_regis_Form ()
{

  const [ remem, setRemem ] = useState( "on" )

  const { setAgInfo, setAdminInfo } = useAdminContext()
  const [ res, dispatch, isPending ] = useActionState( BoCreateUser, { msg: "", status: "" } )

  async function handleClick ( e: any )
  {
    setRemem( e.currentTarget.value )
  }
  useEffect( () =>
  {
    if ( res )
    {
      /*   startTransition(()=>setAgInfo(respone?.result?.agInfo)) */
    }
  }, [ res ] )


  const loginForm = useRef<HTMLFormElement>( null )
  const loginBtn = useRef<HTMLButtonElement>( null )

  const [ username, setUsername ] = useState( '' )
  const [ displayname, setDisplayname ] = useState( '' )
  const [ displaynameValid, setDisplaynameValid ] = useState( '' )
  const [ usernameValid, setUsernameValid ] = useState( '' )
  const [ newpassword, setNewpassword ] = useState( '' )
  const [ conpassword, setConpassword ] = useState( '' )
  const [ newpasswordValid, setNewpasswordValid ] = useState( '' )
  const [ conpasswordValid, setConpasswordValid ] = useState( '' )

  return <div className="flex flex-col justify-center relative justify-self-center min-w-40 max-w-6xl px-4 sm:px-6 "  >

        <div aria-live="polite" className={ `bg-zinc-800 rounded-xl ring-rose-500 ring-2 my-4 text-center p-2 ${ !res?.status && 'invisible' }` }>
          { !isPending && res?.status && <p className="text-lg text-rose-400 shadow-xl">{ res?.msg } </p> }
        </div>


        {/*   <div aria-live="polite" className={`bg-zinc-800 rounded-xl ring-rose-500 ring-2 my-4 text-center p-2 ${loginForm?.current?.['username'].invalid && 'invisible'}`}
            aria-invalid={loginForm?.current?.['username']?.invalid}
          >

            <p className="text-lg text-rose-400 shadow-xl">
              {loginForm?.current?.['username']?.checkValidity()}
            </p>
          </div> */}


        <form className='auth ' aria-disabled={ isPending } ref={ loginForm } action={ dispatch }>

          <label className={ `block text-gray text-lg font-medium mt-4 mb-1 fade-u` } htmlFor="displayname"      >
          Name
          </label>
          <Ez.Input
            className={ `form-input peer/username w-full shadow-xl text-lg text-graydark fade-u  ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500` }
            disabled={ isPending }
            onLoadedData={ e => e.currentTarget.setCustomValidity( "" ) }
            onChange={ e => setDisplayname( e.currentTarget.value ) }
            value={ displayname }
            id="displayname"
            type="text"
            name="displayname"
            pattern="[\S]{1,20}"
            placeholder="ชื่อ"
            autoComplete="username"
            autoFocus
            required
          />

          <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/username:block' htmlFor="displayname" >

            { displaynameValid }
            {/*  {`${loginForm?.current?.['username']?.validity.valid}`} */ }
            { displaynameValid.match( 'Auto' ) && <li><Link className="text-blue-400" href={ { pathname: '/auto' } }>ทางเข้าระบบ Auto คลิก</Link></li> }
          </label>

          {/* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */ }


          <label className={ `block text-gray text-lg font-medium mt-4 mb-1 fade-u` } htmlFor="username"      >
            Username
          </label>
          <Ez.Input
            className={ `form-input peer/username w-full shadow-xl text-lg text-graydark fade-u  ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500` }
            disabled={ isPending }
            onLoadedData={ e => e.currentTarget.setCustomValidity( "" ) }
            onBlur={ e =>
            {
              if ( e.currentTarget.value.match( "^0[a-zA-Z0-9]{8,}" ) )
              {
                setUsernameValid( 'ยูสเซอร์ของท่านอาจอยู่ที่ระบบ Auto' )

              } else if ( e.currentTarget.value.match( "^[bB446\S.*]{1,15}" ) )
              {
                setUsername( i => i.replaceAll( /\s|\%20/gm, '' ).replace( /^[bB]{1}/g, 'B' ) )
                setUsernameValid( "" )
              } else
              {
                setUsernameValid( "ชื่อผู้ใช้ ควรจะขึ้นต้นด้วย B4466 ตามด้วยตัวเลขอีก 5-6 ตัว" )

              }
            } }
            onChange={ e => setUsername( e.currentTarget.value ) }
            value={ username }
            id="username"
            type="text"
            name="username"
            pattern="[\S]{1,20}"
            placeholder="ชื่อผู้ใช้"
            autoComplete="username"
            autoFocus
            required
          />

          <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/username:block' htmlFor="username" >

            { usernameValid }
            {/*  {`${loginForm?.current?.['username']?.validity.valid}`} */ }
            { usernameValid.match( 'Auto' ) && <li><Link className="text-blue-400" href={ { pathname: '/auto' } }>ทางเข้าระบบ Auto คลิก</Link></li> }
          </label>

          {/* ⇩ Start  `````````````````````````    ````````````````````````` Start ⇩ */ }

          <label className={ `block text-gray text-lg duration-100 font-medium  mt-4 mb-1 fade-u` } htmlFor="newpassword">
            New Password
          </label>
          <Ez.Input
            className={ `form-input peer/newpassword duration-100 w-full shadow-xl text-lg text-graydark fade-u ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500` }
            disabled={ isPending }
            id="newpassword"
            type="password"
            name="newpassword"
            onBlur={ ( { currentTarget } ) =>
            {
              if ( currentTarget.value.match( /^[A-Za-z0-9]{4,16}$/ ) ) setNewpasswordValid( "" )
              else setNewpasswordValid( "รหัสผ่านต้องมีอย่างน้อย 4 ตัวอักษร" )
            } }
            onChange={ ( { currentTarget } ) => setNewpassword( currentTarget.value ) }
            value={ newpassword }
            pattern="^[A-Za-z0-9]{4,16}$"
            title="รหัสผ่านมีอย่างน้อย 4 ตัว"
            minLength={ 4 }
            placeholder="รหัสผ่านใหม่"
            autoComplete="new-password"
            required
          />
          <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/password:block' htmlFor="new-password" >
            { newpasswordValid }
          </label>

          <label className={ `block text-gray text-lg duration-100 font-medium  mt-4 mb-1 fade-u` } htmlFor="conpassword">
            Confirm Password
          </label>
          <Ez.Input
            className={ `form-input peer/cpassword duration-100 w-full shadow-xl text-lg text-graydark fade-u ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500` }
            disabled={ isPending }
            id="conpassword"
            type="password"
            name="conpassword"
            onBlur={ ( { currentTarget } ) =>
            {
              if ( currentTarget.value.match( /^[A-Za-z0-9]{4,16}$/ ) ) setConpasswordValid( "" )
              else setConpasswordValid( "รหัสผ่านต้องมีอย่างน้อย 4 ตัวอักษร" )
            } }
            onChange={ ( { currentTarget } ) => setConpassword( currentTarget.value ) }
            value={ conpassword }
            pattern="^[A-Za-z0-9]{4,16}$"
            title="รหัสผ่านมีอย่างน้อย 4 ตัว"
            minLength={ 4 }
            placeholder="ยืนยัน รหัสผ่านใหม่"
            autoComplete="new-password"
            required
          />
          <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/cpassword:block' htmlFor="password" >
            { conpasswordValid }
          </label>

          <button type="submit"
            className={ `p-2 text-xl text-indigo-100 disabled:text-zinc-600 disabled:hover:cursor-default enabled:active:animate-ping enabled:hover:animate-hover hover:-translate-y-px hover:translate-x-px drop-shadow-sm size-fit font-bold rounded-md mt-6 mb-4 w-full ring-2 inset-1 enabled:ring-blue-600  ${ isPending ? 'animate-bounce-2 aniDelay-0' : 'aniDelay-300 fade-u' } disabled:read-only ` }
            disabled={ !( loginForm?.current?.[ 'displayname' ]?.validity.valid &&loginForm?.current?.[ 'username' ]?.validity.valid && loginForm?.current?.[ 'newpassword' ]?.validity.valid && loginForm?.current?.[ 'conpassword' ]?.validity.valid ) }
            id='loginBtn'
            name='loginBtn'
            ref={ loginBtn }
          >
            { isPending ? <span className="cubic-loader" /> : <span className="flex flex-row justify-center">เพิ่มยูสเซอร์ Sub</span> }

          </button>

        </form>
  </div>
}
