'use client'

import { useEffect } from "react"
import { EzLoading, SiteNav, UserNavMenu, LoginForm, EntransLobbyBtn } from "../Components"
import { useSessionStorage, BfxGameProvider, useusercontext, type customer, UpdateBfxUsers } from "@/lib"


export function UserContextProvider ({
  children,
}: {
    children: React.ReactNode
})
{
  /*  \/````````````````````````````  หน้าบ้านโซน  ````````````````````````````\/  */

  const [userInfo, setUserInfo] = useSessionStorage<customer | undefined>('userInfo', undefined)
  const [hasSession, setHasSession] = useSessionStorage<boolean | undefined>('_bfx_dSV', undefined)
  const [bfxGameProviderList, setBfxGameProviderList] = useSessionStorage<BfxGameProvider[]>('bfxGameProviderList', [])
  const [historyGames, setHistoryGames] = useSessionStorage<BfxGameProvider[]>('historyGames', [])

/*   useEffect(()=>{
    if(!userInfo?.account) return
    UpdateBfxUsers(userInfo, {session: userInfo?.account?.session} )
  },[userInfo?.account]) */
  celog('UsersContextProvider')

  return <useusercontext.Provider value={{
    userInfo, setUserInfo,
    hasSession, setHasSession,
    bfxGameProviderList, setBfxGameProviderList,
    historyGames, setHistoryGames,
  }}>

    <SiteNav>
      <EzLoading>{hasSession ? <UserNavMenu /> : <LoginForm />}</EzLoading>
    </SiteNav>



      {children}



    <EzLoading> {hasSession && <EntransLobbyBtn />}</EzLoading>
  </useusercontext.Provider>
}
