'use client'
import { type BfxTransaction, updateAgTransaction } from "@/lib"
import { startTransition, useEffect } from "react"
import { EzLoading } from "../EzLoading"
import { TableCell_Credit } from "./CellTemplate"
import { THead } from "./THead"
import { LineUI } from "../../images"


export function TransactionTable ()
{
  const { bfxTransactionList, setBfxTransactionList } = useBfxContext()
  useEffect(() =>
  {
    async function getupdate ()
    {
      const res = await updateAgTransaction(bfxTransactionList?.[0]?._id)
      if (res)
      {
        startTransition(()=>setBfxTransactionList(n => n && res?.pushUpdate(n,"_id")))
      }
    }
    getupdate()
  }, [])

  return <div className="relative overflow-y-clip fixed max-w-full h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)]">
    <table className="w-full table">
      <THead className="text-center font-extrabold dark:text-bodydark1"
        tableHeader={[
          { name: '#', className: 'max-w-2' },
          { name: 'เวลา', className: 'max-w-12' },
          { name: 'By', className: 'w-24' },
          { name: "type", className: '' },
          { name: 'User', className: '' },
          { name: 'ยอดรายการ', className: '' },
          { name: 'ก่อน/หลัง', className: '' },
          { name: 'คงเหลือ', className: 'max-w-8' }
        ]} />
    </table>
    <div className="flex h-full overflow-y-scroll">
      <table className="w-full h-full">
        {bfxTransactionList && <TransactionTBody transaction={bfxTransactionList.slice(0, 100)} />}

      </table>
    </div>
  </div>
}

export const TransactionTBody = function ({ transaction }: { transaction: BfxTransaction[] })
{
  const { bfxUserList, setBfxUserList } = useBfxContext()
  return <tbody className="gap-y">
    {transaction?.map((i, k) =>{
      const userBio = bfxUserList.find(u=>u.account?.username == i.user)
      return   <EzLoading key={k} >
        <tr className="py-1 border-b border-strokedark">
          <td className="max-w-4">{k}</td>
          <td className="max-w-24"><small>{i?.timestamp && new Date(i?.timestamp).toLocaleString("th")}</small></td>
          <td className="text-center">{i?.source ? `${i?.source}` : "-"}</td>
          <td className={`w-[25px]`}>{userBio?.bio?.line?.[ 0 ] && 
            <LineUI { ...userBio?.bio?.line?.[ 0 ] } width={ 25 } height={ 25 } />}</td>
          <td >{userBio?.bio?.fullName ? `${userBio?.bio?.fullName}` : ".."}</td>
          <td className="text-center">{i?.user && `${i.user}`}</td>
          <td className="text-center"><TableCell_Credit int={i?.amount} /></td>
          <td className="text-right">
          </td>
          <td className="text-right">
            <small>
              <ul>
                <li>{i?.before && `${i.before.toDDString(2)}`}</li>
                <li>{i?.after && `${i.after.toDDString(2)}`}</li>
              </ul>
            </small>
          </td>
          <td className="text-right">
            {i?.total_credit && `${i.total_credit.toDDString(2)}`}
          </td>
        </tr>
      </EzLoading>
})
    }
  </tbody>
}
