'use client'
import { startTransition, useEffect } from 'react'
import { updateData } from '@/lib'
import { after } from 'node:test'


export function UpdateAdminContext ()
{
     celog("UpdateAdminContext")
     const {
          lineUserList, setLineUserList,
          chatBotEvent, setChatBotEvent
     } = useLineBotContext()


     useEffect(() =>
     {
          async function clientGetDataDB<T> (database: string, collection: string, data: T[], setData?: s_S<T[]>)
          {
               const res = await updateData(database, collection, (data as unknown as any)?.[0]?.["_id"])
               if (res)
               {
                    fxlog(`${typeof data}\n`, res)
                         setData && startTransition(() =>setData(n => n ? (res as unknown as any[])?.pushUpdate(n) : res))
               }
          }
          const Arraytask = new Array()
          Arraytask.push(
               ["linebot", "users", lineUserList, setLineUserList],
               ["linebot", "events", chatBotEvent, setChatBotEvent]
          )
          
          Arraytask
               .map((task, k) => task[0] && task[1] &&
                    clientGetDataDB(task[0], task[1], task[2], task[3])
               )


     }, [])


     return null
}

