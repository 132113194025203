
import Images from 'next/image';
import Link from 'next/link';

export function UserProfile({ 
    imgSrc
  } : { 
    imgSrc:string|""
  }) {
  
    return (
      <Link href={{pathname:"/"}} className="block" aria-label="Cruip">
        <Images
       src={imgSrc}
       width={32}
       height={32}
       alt={""}
       />
       
          <defs>
            <radialGradient cx="21.152%" cy="86.063%" fx="21.152%" fy="86.063%" r="79.941%" id="footer-logo">
              <stop stopColor="#4FD1C5" offset="0%" />
              <stop stopColor="#81E6D9" offset="25.871%" />
              <stop stopColor="#338CF5" offset="100%" />
            </radialGradient>
          </defs>
          <rect width="32" height="32" rx="16" fill="url(#footer-logo)" fillRule="nonzero" />
      
      </Link>
    )
  }
