import type { ReactNode } from "react"


export function FncIcons ({ iconName, className, children }: { iconName?: string; className?: string; children?: ReactNode} )
{
    if (iconName)
    {
        return <span className={`icons  ${className || ''}`}>{iconName}</span>
    } else if (children)
    {
        return (
            <svg width={64} height={64} viewBox="0 -960 960 960"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg">{children}</svg>
        )
    }
}
