'use client'

import { useEffect, useRef, useState } from 'react'
import { UI_EventItems } from './UI_EventItems'
import { EzLoading } from '../EzLoading'

export function ChatUi() {
  
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const messagesStartRef = useRef<HTMLDivElement>(null)
  function scrollToBottom(beh: boolean | ScrollIntoViewOptions | undefined = { behavior: "smooth" }) {
    messagesStartRef.current?.scrollIntoView(beh)
  }
  const {chatBotEvent,lineUserList} =useLineBotContext()
  const [messagesBox,setMessagesBox] = useState<ChatBotEvent[]>([])
  useEffect(() => {
    if(chatBotEvent[0]!=messagesBox[0]){
      setMessagesBox(chatBotEvent)
      scrollToBottom()
    }
    
    return () => {
      
      scrollToBottom()
    }

  }, [chatBotEvent])

  return (
    <div className="inline-flex  w-full bottom-0 h-full pt-15 border flex-col-reverse">
      <div ref={messagesStartRef}> Start </div>
      {chatBotEvent?.map((i, k) => {
        const userProfile = lineUserList?.find(n => n?.userId == i?.source?.userId)
        //if(userProfile?.pictureUrl && userProfile)
        return (
          <EzLoading key={k} >

            <UI_EventItems item={i} userProfile={userProfile} />
            {i?.reply?.text && <UI_EventItems item={i.reply} reverse={true} />}

          </EzLoading>
        )
      })}

      <div ref={messagesEndRef} >End</div>
    </div>
  )
}
