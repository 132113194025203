'use client'

import { useAdminUiContext } from "@/lib";



export function DarkModeSwitcher ()
{
  const { darkThemeContext, setDarkThemeContext } = useAdminUiContext()
  return (

    <label
      className={`relative sm:block hidden m-0 h-7 w-14 rounded-full bg-gray text-slate-800
          dark:bg-primary`}
    >
      <button
        onClick={e =>
        {
          e.preventDefault()
          setDarkThemeContext(n => !n)
        }
        }
        className="duration-75 absolute top-0 z-50 m-0 h-full w-full cursor-pointer opacity-0"
      />
      <span
        className={`absolute left-[0px] top-1/2 flex h-8 w-8 -translate-y-1/2 translate-x-0 items-center justify-center rounded-full dark:bg-slate-300 bg-slate-600 shadow-switcher duration-75 ease-linear dark:!right-[3px] dark:!translate-x-full`}
      >
        <span className={`dark:hidden text-slate-50`}>
          <Icons iconName="Sword_Rose " />
        </span>
        <span className="hidden dark:inline-block">
          <Icons iconName="Visibility_Lock" />
        </span>
      </span>
    </label>

  )
}

