
import type { BfxGameProvider } from "@/lib";
import { EzLoading } from "../EzLoading";
import { } from "../Utils";
import { CardOverLay } from "./gamesCard";
import { memo } from "react";


export { };

export const LobbyGamesList = memo( function  ( { GamesProvider }: { GamesProvider: BfxGameProvider; } )
{

  return <div className="flex justify-between flex-wrap gap-x-2 gap-y-10 overflow-y-visible">
    { GamesProvider?.gameList && GamesProvider?.gameList?.sort( ( a, b ) => ( ( a?.order || 0 ) - ( b?.order || 1 ) ) )?.map( ( i, k ) =>
    {

      return <EzLoading key={ k } >
        <CardOverLay GamesProvider={ GamesProvider } gameCard={ i } className={ `max-w-[30vw] fade-u` } />
      </EzLoading>;

    }
    ) }


  </div>;



}
)