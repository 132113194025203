'use client'
import Image from "next/image"
import { InputIcon } from "../Form"
import { EditableFromClipboard } from "../Utils"
import { EzLoading } from "src/Components/EzLoading";


export function DatabaseTablePage ()
{

  return (

    <div className="w-full h-fit rounded-sm border border-stroke bg-white px-4 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-2">
      <div className="max-w-full">
        <table className="w-full table-auto">

         {/*  <DatabaseTable dataBase={DatabaseTable} /> */}
        </table>
      </div>
    </div>
  )
}

export function DatabaseTable ({ dataBase }: { dataBase?: any[]} )
{

  return (

    <tbody className="gap-y-2 order-last">
      {dataBase?.map((i, k) =>
      {

        return (

<EzLoading key={k}>
          <tr  className="py-1 border-b border-[#eee] dark:border-strokedark">

            {/* ⇩ Start  ````````````````````````` # ````````````````````````` Start ⇩ */}
            <td className='max-w-12'>

              <Image
                width={150}
                height={150}
                src={i?.pictureUrl ?? ''}
                className="rounded-full"
                alt={i?.displayName ?? "USER"} />

            </td>
            {/* ⇧ End   __________________________  #  ___________________________ End ⇧ */}

            {/* ⇩ Start  ````````````````````````  ชื่อ-นามสกุล  ````````````````````````` Start ⇩\ */}
            <td className="table-cell text-center px-4 text-black dark:text-white">

              <EditableFromClipboard value={(i?.fname ?? 'ไม่มีชื่อ ') + (i?.lname ?? ' ไม่มีนามสกุล')} />

              <div className="text-sm">


                {/* >> Value _____*/i?.displayName ?? 'Unknow' /* _____ Value << */}


              </div>


            </td>
            {/* ⇧ End   __________________________  ชื่อ-นามสกุล  ___________________________ End ⇧ */}


            {/* ⇩ Start  ````````````````````````  บัญชี ธนาคาร  ````````````````````````` Start ⇩\ */}
            <td className="table-auto px-4">

              <EditableFromClipboard value={i?.banking ?? 'ยังไม่มี ธนาคาร'} />

            </td>

            {/* ⇩ Start  ````````````````````````  Username  ````````````````````````` Start ⇩\ */}
            <td className="table-auto px-4">
              {/*   <Ez.Input className="text-black dark:text-white" placeholder={i?.username ??'ยังไม่มียูสเซอร์'} /> */}

              <EditableFromClipboard value={i?.username ?? 'ยังไม่มียูสเซอร์'} />


            </td>
            {/* ⇧ End   __________________________  Username  ___________________________ End ⇧ */}

            {/* ⇩ Start  ````````````````````````  Actions  ````````````````````````` Start ⇩ */}
            <td className="table-cell text-center w-fit px-4">

              {/* >> Actions _____*/<InputIcon id='Balance' className="w-full h-full input-editable" placeholder="เติมถอน ใส่ยอด + - "> + -
                {<Icons iconName='Currency_Exchange' />}</InputIcon> /* _____ Actions << */}

            </td>
            {/* ⇧ End   __________________________  Actions  ___________________________ End ⇧ */}


            {/* ⇩ Start  ````````````````````````  Credit  ````````````````````````` Start ⇩ */}
            <td className={`table-auto text-center px-4`}>
              <div className={`rounded-full bg-opacity-10 px-4 text-2xl font-semibold ${i?.credit > 10000 ? "bg-danger text-danger" : i?.credit > 1000 ? "bg-success text-success" : i?.credit < 500 ? "bg-warning text-warning" : "bg-gray text-gray"}`}>

                {/* >> Credit _____*/`${i?.credit ?? 0}.00 ฿` /* _____ Credit << */}

              </div>              </td>
            {/* ⇧ End   __________________________  Credit  ___________________________ End ⇧ */}



            {/* // ⇩ Start  ````````````````````````  Edits  ````````````````````````` Start ⇩\ */}
            <td className="table-auto text-center">
              <div className="mx-1 space-x-3.5">
                <button className="hover:text-primary">
                  <Icons iconName="Tune" />
                </button>
                <button className="hover:text-primary">
                  <Icons iconName="Edit" />
                </button>
                <button className="hover:text-primary">
                  <Icons iconName='Currency_Exchange' />
                </button>
              </div>
            </td>
            {/* ⇧ End   __________________________  Edits  ___________________________ End ⇧ */}



            {/* // ⇩ Start  ````````````````````````  Status  ````````````````````````` Start ⇩\ */}
            <td className="table-auto text-center">
              <div className={`inline-flex rounded-full bg-opacity-10 px-3 py-1 font-medium ${i?.active
                ? "bg-success text-success"
                : "bg-danger text-danger"}`}
              >

                {/* >> Value _____*/i?.active ? <Icons iconName="swords" /> : <Icons iconName="Person_Off" /> /* _____ Value << */}

              </div>
            </td>
            {/* ⇧ End   __________________________  Status  ___________________________ End ⇧ */}



          </tr></EzLoading>
        )
      }
      )}
    </tbody>

  )

}
