'use client'
import Image from "next/image"
import { EzLoading } from "src/Components/EzLoading"
import { THead } from "./THead"



export function BoSubUserTable ( { DataTable }: { DataTable?: BoUser[] } )
{

  return (

    <div className="w-full h-fit rounded-sm border border-stroke bg-white px-4 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-2">
      <div className="max-w-full">
        <table className="w-full table-auto">
          <THead className="text-center font-extrabold dark:text-bodydark1"
            tableHeader={ [ { name: "#", }, { name: "pic", }, { name: "name", }, { name: "username" }, { name: "role" } ,{name:"action"},{name:"status"}] }
          />
          <DatabaseTable dataBase={ DataTable } />
        </table>
      </div>
    </div>
  )
}

export function DatabaseTable ( { dataBase }: { dataBase?: any[] } )
{

  return (

    <tbody className="gap-y-2 order-last">
      { dataBase?.map( ( i, k ) =>
      {

        return (

          <EzLoading key={ k }>
            <tr className="py-1 border-b">

              <td>                { k }              </td>
        
              <td>
                <Image 
                  priority
                  width={ 60 }
                  height={ 60 }
                  src={ "/api/image/goldborder" + `?t=${ i?.name }` }
                  className="'max-w-4 max-h-6' rounded-full" alt={ "" } />

              </td>
              {/* ⇧ End   __________________  #  _______________________ End ⇧ */ }

              <td >
              { i?.name }
              </td>

              {/* ⇩ Start  ````````````````````````  บัญชี ธนาคาร  ````````````````````````` Start ⇩\ */ }
              <td >
              { i?.username }
              </td>

              {/* ⇩ Start  ````````````````````````  Username  ````````````````````````` Start ⇩\ */ }
              <td >
              { i?.role }
              </td>

              {/* // ⇩ Start  ````````````````````````  Edits  ````````````````````````` Start ⇩\ */ }
              <td>
                <div className="mx-1 space-x-3.5">
                  <button className="hover:text-primary">
                    <Icons iconName="Tune" />
                  </button>
                  <button className="hover:text-primary">
                    <Icons iconName="Edit" />
                  </button>
                  <button className="hover:text-primary">
                    <Icons iconName='Currency_Exchange' />
                  </button>
                </div>
              </td>
              {/* ⇧ End   __________________________  Edits  ___________________________ End ⇧ */ }



              {/* // ⇩ Start  ````````````````````````  Status  ````````````````````````` Start ⇩\ */ }
              <td>
                <div className={ `inline-flex rounded-full bg-opacity-10 px-3 py-1 font-medium ${ i?.status=="active"
                  ? "bg-success text-success"
                  : "bg-danger text-danger" }` }
                >
                 {i?.status=="active" ? <Icons iconName="swords" /> : <Icons iconName="Person_Off" />}

                </div>
              </td>
              {/* ⇧ End   __________________________  Status  ___________________________ End ⇧ */ }



            </tr></EzLoading>
        )
      }
      ) }
    </tbody>

  )

}
