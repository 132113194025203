"use client"

import { Transition } from "@headlessui/react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { ImgLogo } from "../../images";
import { MenuBtnUserCredit } from "./MenuBtnUserCredit";
import { useUserUiContext } from "@/lib";
import { EzLink } from "../EzLink"


export function SiteNav ({
  children
}: {
  children: React.ReactNode
})
{
  const { navOpen, setNavOpen, trigger, siteNav, gamePlayState } = useUserUiContext()

  const [top, setTop] = useState<boolean>(true)
  //const [offset, setOffset] = useState(0)

  // close the mobile menu on click outside


  useEffect(() =>
  {
    /*     celog('SiteNav hasSession:',hasSession) */

    if (!navOpen) return
    fxlog("useEffect clickHandler")
    function clickHandler ({ target }: { target: EventTarget | null }): void
    {
      /*       fxlog('target:',target,'  !siteNav.current:',!siteNav?.current,' || !trigger.current:',!trigger?.current) */
      if (!siteNav?.current || !trigger?.current) return
      /*       fxlog('siteNav.current.contains(target as Node):',siteNav.current.contains(target as Node)) */
      if (siteNav?.current.contains(target as Node)) return
      setNavOpen(false)
    }
    document.addEventListener("click", clickHandler)

    return () => document.removeEventListener("click", clickHandler)
  }, [navOpen])


  useEffect(() =>
  {
    const onScroll = () => scrollY > 100 ? setTop(false) : setTop(true)

    addEventListener('scroll', onScroll, { passive: true })

    return () => removeEventListener('scroll', onScroll)

  }, [scrollY])


  // close the mobile menu if the esc key is pressed
  useEffect(() =>
  {
    if (!navOpen) return
    fxlog("useEffect keyHandler keydown")
    function keyHandler ({ keyCode }: { keyCode: number }): void
    {
      if (keyCode !== 27) return
      setNavOpen(false)
    }
    document.addEventListener("keydown", keyHandler)
    return () => document.removeEventListener("keydown", keyHandler)
  }, [navOpen])


  return (
    <header
      className={`fixed left-0 top-0 w-full z-999 transition duration-200 ease-in-out ${(!top || navOpen) && "backdrop-blur-sm backdrop-brightness-50 "} ${gamePlayState && '-translate-y-full delay-[3000ms]' || ''}`}
    >

      <div className="max-w-full mx-auto px-2 sm:px-6">
        <div className="flex items-center h-16 md:h-20">
          <EzLink className="shrink-0 m1xsm:max-w-[25vw]" href={{pathname:`/`}}>
            <ImgLogo.BFLIX width={100} height={30} alt={"BFLIX"} />
          </EzLink>
          <div className="flex flex-row ml-auto text-lg text-white">
            <MenuBtnUserCredit />
          </div>
        </div>
      </div>

      {/*     <div className="absolute text-red bottom-0 border z-9999">
          {'offset='+offset}
          </div> */}

      <Transition
        show={navOpen}
     
        enter="transition ease-in-out duration-500 transform "
        enterFrom="opacity-0 -translate-y-2"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <nav id={`siteNav`} className={`w-full h-screen z-9999 left-0 top-16 md:bg-opacity-90 md:top-20`}>

          {children}
        </nav>
      </Transition>
    </header>

  )
}
