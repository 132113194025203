
'use client'
import { createContext, useEffect, useMemo, useState } from "react"
import { EzLoading } from "../Components"
import { unsubscribeUser } from "../Components/Utils/Push"


export interface NotificationContextType
{
    isSupported: boolean
    setIsSupported: React.Dispatch<React.SetStateAction<boolean>>
    isSubscribed: boolean
    setIsSubscribed: React.Dispatch<React.SetStateAction<boolean>>
    subscription: PushSubscription | null
    setSubscription: React.Dispatch<React.SetStateAction<PushSubscription | null>>

}
export const NotificationContext = createContext<NotificationContextType>({
    isSupported: false,
    setIsSupported: () => { },
    isSubscribed: false,
    setIsSubscribed: () => { },
    subscription: null,
    setSubscription: () => { },
})


export function WebPushProvider ({
    children
}: {
        children?: React.ReactNode
})
{
    const [isSupported, setIsSupported] = useState<boolean>(false)
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [subscription, setSubscription] = useState<PushSubscription | null>(null)



    const contextValue = useMemo(
        () => ({
            isSupported,
            setIsSupported,
            isSubscribed,
            setIsSubscribed,
            subscription,
            setSubscription,
        }),
        [isSupported, setIsSupported, isSubscribed, setIsSubscribed, subscription, setSubscription]
    )

    const [title, setTitle] = useState("")

    async function registerServiceWorker ()
    {
        celog("registerServiceWorker")
        return await navigator.serviceWorker.register('/sw.js')
            .then(registration =>
                registration.pushManager.getSubscription()
                    .then(res =>
                    {
                        res && setSubscription(res)
                        celog("setSubscription=", res)
                        return res
                    }

                    ))
    }
    const fetchNotification = async () =>
    {
        await fetch("/api/notify", {
            method: "POST",
            body: JSON.stringify({ title, message, subscription }),
            headers: {
                "Content-Type": "application/json",
            },
        })
    }

    useEffect(() =>
    {
        if (!isSupported && ('serviceWorker' in navigator) ||
            ('PushManager' in window) ||
            ('showNotification' in ServiceWorkerRegistration.prototype))
        {
            setIsSupported(true)
        }


        isSupported && !subscription &&
            registerServiceWorker()

        async () => await fetch("/api/notify", {
            method: "POST",
            body: JSON.stringify({ title, message, subscription }),
            headers: {
                "Content-Type": "application/json",
            },
         })
        fxlog('isSupported', isSupported)
    }, [isSupported])



    return (
        <NotificationContext.Provider value={contextValue}>{children}<div>{subscription ? <EzLoading>
            <button onClick={async () =>
                subscription?.unsubscribe()
                    .then(() => unsubscribeUser())
                    .then(() =>
                        setSubscription(null))}>
                Unsubscribe
            </button>
            <input type="text"
                placeholder="Enter notification message"
                value={message || ""}
                onChange={(e) =>
                    setMessage(e.target.value)}
            />
            <button onClick={async () =>
                message && (await fetchNotification()
                    .then(() =>
                        setMessage('')))}
            >
                Send Test
            </button>
            <div className="bg-white shadow-md rounded-lg p-6 mt-6 w-full max-w-md">
                <h2 className="text-xl font-bold mb-4">Send a Notification</h2>

                {/* Title Input */}
                <input
                    type="text"
                    placeholder="Notification Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
                />

                {/* Message Input */}
                <textarea
                    placeholder="Notification Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
                />

                <button onClick={fetchNotification} className="w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition">
                    Send Notification
                </button>
            </div>
        </EzLoading> :
            <div>

                <button onClick={registerServiceWorker}>
                    Subscribe
                </button>




            </div>}</div>

            {children}</NotificationContext.Provider>
    )
}
