
export function Ui_CardIcons({
    icon,            index
}:{
    icon:string ,    index:string|number
}){
    return(
        <div key={index} className='m-5 flex-col justify-center align-top flex'>
            <Icons iconName={icon} />
        <h1 className='text-5xl mx-auto'>
        {icon}
        </h1>
        </div>
    )
}