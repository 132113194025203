export { Collection } from 'next-flight-server-reference-proxy-loader?id=40097450a5c630e64596af8f1859d450e85e87fe37&name=Collection!'
export { WatchNext } from 'next-flight-server-reference-proxy-loader?id=602fcbc7186e06d804da6d22e23a92b6ca6b02d42c&name=WatchNext!'
export { updateMany } from 'next-flight-server-reference-proxy-loader?id=7f0346658588f7f6df880c1f4bb2b2187e2ff81d21&name=updateMany!'
export { insertMany } from 'next-flight-server-reference-proxy-loader?id=7f16ebfe72f9532c7e89f4403a5adc923c2f5fbd46&name=insertMany!'
export { aggregate } from 'next-flight-server-reference-proxy-loader?id=7f1ceeba039843240bdd0eec086df250c3a2003d60&name=aggregate!'
export { deleteMany } from 'next-flight-server-reference-proxy-loader?id=7f575711cd30eed26ec4c0a0998705c3ae729fbe97&name=deleteMany!'
export { find } from 'next-flight-server-reference-proxy-loader?id=7f5d76ca4e7043f2b18d14fc76a307b85e6ebc852f&name=find!'
export { updateOne } from 'next-flight-server-reference-proxy-loader?id=7f5fa20678396d0c558bb9338d0df4aac1f08f8d64&name=updateOne!'
export { findOne } from 'next-flight-server-reference-proxy-loader?id=7f728f58e7d4338ad39525e1e5a465ca466d252da1&name=findOne!'
export { replaceOne } from 'next-flight-server-reference-proxy-loader?id=7f79d9b29953e0d8423189022a5b20029c7b334c17&name=replaceOne!'
export { deleteOne } from 'next-flight-server-reference-proxy-loader?id=7f7d709aa90bf2e27c628909b8184194c9b399ecea&name=deleteOne!'
export { connectedDb } from 'next-flight-server-reference-proxy-loader?id=7f8f84349a6df8d89c8b6e0840db1aa889afea80b6&name=connectedDb!'
export { Aggregate } from 'next-flight-server-reference-proxy-loader?id=7fb02cd500c430404ff866133e7e6f75d55b80c842&name=Aggregate!'
export { insertOne } from 'next-flight-server-reference-proxy-loader?id=7ffae4d7b386d2e5d2cf51972b601f5a45adc509fd&name=insertOne!'