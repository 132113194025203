'use client'
import type { BfxGameProvider } from "@/lib";
import Image from "next/image";
import Link from "next/link";
import { memo, useEffect } from "react";
import { ImgLogo } from "../../images";
import { EzAPI } from "../../lib/apiList";
import { fetchJ } from "../../lib/type";
import { EzLoading } from "../EzLoading";
import { EzLink } from "../EzLink"


export const AboutsUs = memo(function ()
{
  const { bfxGameProviderList, setBfxGameProviderList } = useUserContext()
  useEffect(() =>
  {
    bfxGameProviderList[0] ?? fetchJ<BfxGameProvider[]>(EzAPI.providersList).then(i => i&&setBfxGameProviderList(i))
  })
  return <section id='AboutsUs' className="relative">

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className=" max-w-3xl mx-auto text-center text-gray shadow pb-12 md:pb-16">
            <h1 className="mb-4 ">ค่ายเกมส์ชั้นนำแนะนำ</h1>

            <EzLoading> <h4>
              รวมเว็บสล็อต บาคาร่า คาสิโนสด ทั้งไทยและเทศ อย่าง
             {/*  {bfxGameProviderList?.map(i => ' ' + i?.name)} */}
            </h4></EzLoading>

          </div>
          <div className="flex justify-center flex-wrap">
            {bfxGameProviderList?.map((i, k) => <EzLoading key={k}>
              {i?.logo && <Image
                src={i?.logo}
                width={100}
                height={100}
                alt={i?.name}
              />}
            </EzLoading>
            )}
          </div>
          <div className="max-w-3xl mx-auto mt-20">
            <div className="relative flex items-start border-2 border-gray-200 rounded bg-zinc-100">

              <div className="text-center px-12 py-8 pt-20 mx-4 md:mx-0">


                <div className="absolute z-99 top-0 -mt-8 left-1/2 transform -translate-x-1/2 max-w-20  ring-2 ring-yellow-400 ring-offset-4 ring-offset-yellow-600 rounded-full ">
                  <ImgLogo.DarinBET
                    className="bg-gradient-to-tl from-black via-purple-800 to-pink-900 rounded-full"
                    width={150}
                    height={150}
                  />
                </div>
                <blockquote className="text-xl font-medium mb-4">
                  {` “ ศูนย์บริการกลางดูแลลูกค้า โดย ดาริน เซอร์วิสเซ็นเตอร์ พร้อมซัพพอทตลอด 24 ชม. 
                  รายงานถึงผู้บริหารโดยตรง พนักงานไม่ตอบ บอกดาริน เดี๋ยวดารินมาตอบเอง :) “`}
                </blockquote>
                <cite className="block font-bold text-lg not-italic mb-1">
                  www.Darin.BET
                </cite>
                <div className="text-gray-600">
                  <span>DarinBET Co.,Ltd </span>
                  <EzLink className="text-blue-600 hover:underline" href={{pathname:"/line"}}>
                    LINE:@DarinBET
                  </EzLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
}
)
