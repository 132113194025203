'use client'
import { getAgInfo, getSessionData, useadmincontext, useSessionStorage } from "@/lib"
import { redirect } from "next/navigation";
import { startTransition, useEffect, useMemo } from "react";


export function AdminContextProvider ({
  children,UserHasSession
}: {
  children: React.ReactNode,UserHasSession?:boolean
})
{
  const [adminInfo, setAdminInfo] = useSessionStorage<BoUser | undefined>('adminInfo', undefined)
  const [hasSession, setHasSession] = useSessionStorage<boolean | undefined>('_bo_dSV', undefined)
  const [agInfo, setAgInfo] = useSessionStorage<AgInfo | undefined>('agInfo', undefined)

  laylog('AdminContextProvider')

  const memosetAdminInfo = useMemo(() => setHasSession,[UserHasSession])
  UserHasSession!=hasSession&&memosetAdminInfo(UserHasSession)
  useEffect(() =>
  {
    if(!hasSession){
      setAdminInfo(undefined)
      setAgInfo(undefined)
    }
    adminInfo ?? startTransition(() => getSessionData<BoUser>("_bo_dSV").then(res => res ? setAdminInfo(res):redirect("/login")))
    agInfo ??  startTransition(() => {getAgInfo<AgInfo>('b4466').then(res => res && setAgInfo(res))})

  }, [adminInfo,hasSession,memosetAdminInfo])

  return <useadmincontext.Provider value={{
      adminInfo, setAdminInfo, hasSession, setHasSession, agInfo, setAgInfo
    }}>

    {children}

  </useadmincontext.Provider>
}
