'use client'
export { }
import { useEffect, useRef, useTransition } from "react"

import { type bfxUsers, type customer, bfxUserURL, getSessionData, UpdateBfxUsers, UserLogOut, useUserContext, useUserUiContext } from "@/lib"
import { EzLoading } from "../EzLoading"

export function UserBfCredit ()
{
  const [isPending, startTransition] = useTransition()

  const Spinning = [{ transform: "rotateY(180deg)" }, { opacity: 0.5, easing: "ease-in-out" }]
  const SpinningEnd = [{ transform: "rotateY(360deg)" }, { opacity: 1, easing: "ease-in-out" }]
  const Timing = {
    duration: 200,
    iterations: 1,
  }
  const btnref = useRef<HTMLButtonElement>(null)
  const { userInfo, setUserInfo, hasSession, setHasSession } = useUserContext()
  const { setNavOpen } = useUserUiContext()
  let retry = 0

  async function reLoadCredit ( ev?: any )
  {
    fnclog('reLoadCredit:')
    if ( ev?.currentTarget as HTMLButtonElement )
    {
      ( ev?.currentTarget as HTMLButtonElement )?.animate( Spinning, Timing )
    }
    if (!hasSession) return
    else if (!userInfo)
    {
      UserLogOut()
      setHasSession(false)
      return
    }
    if ( !userInfo?.account?.session?.[ "l-token" ] )
    {
      startTransition(async () => {
          fnclog(
            'reLoadCredit:hasSession:',
            hasSession, '&&!userInfo?.account?.["l-token"]>>>',
            userInfo?.account?.session?.[ "l-token" ]
          )
          const userinfo = await getSessionData<bfxUsers>("_bfx_dSV")
            .then( res => res?.session?.[ "l-token" ] && setUserInfo( n =>
            {
              return n && {
                ...n,account:{
                  ...n.account,
                session: {
                  ...n.account?.session,
                  'l-token': res?.session?.[ "l-token" ]
                }
              }}
            }))
          tlog('reLoadCredit:userinfo:', userinfo)
        })
    }

    userInfo?.account?.session?.[ "l-token" ] && startTransition( async () =>
    {
      fnclog( 'userInfo?.account?.Session?.["l-token"]:', userInfo?.account?.session?.[ "l-token" ] )
      userInfo?.account?.session?.[ "l-token" ] && await fetch( bfxUserURL.Balance, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "l-token": userInfo?.account?.session[ "l-token" ],
        }
      }).then(res => res.clone().json())
        .then((res:{status:string,data:{'u-token':string,credit:number}}) => {
          fnclog('reLoadCredit res:', res)
          if (res?.status&&res.data?.['u-token'] && res?.data?.credit !== userInfo?.account?.credit)
          {
            btnref.current?.animate(SpinningEnd, {
              duration: 100,
              iterations: 3,
            })
            fnclog('res?.data[u-token]:', res?.data?.['u-token'])
            setUserInfo(n => {
              if(n){
                const save:customer = {
                  ...n,account:{ 
                    ...n.account,
                    credit: res?.data?.credit,
                  session: {
                    ...n?.account?.session,
                    'u-token': res?.data?.['u-token']
                  }}
                }
               
                return save
              }
            })
            
          } else if (!res?.status)
          {
            fxlog('res?.status !== success', res)
            if (retry < 3)
            {
              fxlog('retry:', retry)
              retry++
              setTimeout(reLoadCredit, 1000)
            } else
            {
              fxlog( 'userInfo=', userInfo )
              
              UserLogOut( "_bfx_dSV" )
              setHasSession( undefined )
              setUserInfo(n =>
              {
                if ( n?.account?.session )
                {

                  return { ...n, account:{
                    ...n.account,
                    session: undefined }}
                }
              })
              setNavOpen(true)
            }
          }

        })
    })

  }

  useEffect(() =>
  {


    /*    fxlog('useEffect', optCredit) */
    reLoadCredit()

  }, [userInfo])

  return <div className="flex flex-col">

    <label>User: <EzLoading>{userInfo?.account?.username}</EzLoading></label>
    <span className="flex flex-row">Credit:
      <button ref={btnref} className={`mx-2 fill-current `}
        onClick={reLoadCredit}
      >

        <div className="flex flex-row w-fit animate-bounce aniDelay-1">

          <EzLoading>
            {userInfo?.account?.credit ?
              (
                isPending ? <small> ...</small> : userInfo?.account?.credit.toDDString(2)
              )
              : 0.00}
          </EzLoading>

          <div className="animate-pulse-2"><Icons iconName='Heart_Plus' /></div>
        </div>
      </button></span>

  </div>

}

