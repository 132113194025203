import { type MouseEventHandler } from 'react'


export function UI_BtnTest({
  className,
  onClick,
  disabled
}:{
  className?:string,
  onClick?:MouseEventHandler<HTMLButtonElement>,
  disabled?:boolean
}) {
  flog('testbtn loaded')


  return <button className={className} onClick={onClick} disabled={disabled}>
      Test
    </button>
}
