'use client'
import Link from "next/link"
import { useEffect, useRef, useState } from "react"

export function DropdownNotification ()
{
  const [ dropdownOpen, setDropdownOpen ] = useState( false )
  const [ notifying, setNotifying ] = useState( true )

  const trigger = useRef<any>( null )
  const dropdown = useRef<any>( null )

  useEffect( () =>
  {
    const clickHandler = ( { target }: MouseEvent ) =>
    {
      if ( !dropdown.current ) return
      if (
        !dropdownOpen ||
        dropdown.current.contains( target ) ||
        trigger.current.contains( target )
      )
        return
      setDropdownOpen( false )
    }
    document.addEventListener( "click", clickHandler )
    return () => document.removeEventListener( "click", clickHandler )
  } )

  // close if the esc key is pressed
  useEffect( () =>
  {
    const keyHandler = ( { keyCode }: KeyboardEvent ) =>
    {
      if ( !dropdownOpen || keyCode !== 27 ) return
      setDropdownOpen( false )
    }
    document.addEventListener( "keydown", keyHandler )
    return () => document.removeEventListener( "keydown", keyHandler )
  } )

  return (
    <div className="relative hidden sm:inline-flex">
      <Link
        ref={ trigger }
        onClick={ () =>
        {
          setNotifying( false )
          setDropdownOpen( !dropdownOpen )
        } }
        href="#"
        className="relative flex h-8 w-8 items-center justify-center rounded-full border-[1px] bg-red-400 border-stroke bg-opacity-50 hover:text-primary dark:border-strokedark dark:bg-meta-4 dark:text-white"
      >
        <span
          className={ `absolute -top-0.5 right-0 z-1 h-2 w-2 rounded-full bg-meta-1 ${ notifying === false ? "hidden" : "inline"
            }` }
        >
          <span className="absolute -z-1 inline-flex h-full w-full animate-ping rounded-full bg-meta-1 opacity-75"></span>
        </span>

        <Icons className="Bomb" iconName={ "Bomb" } />
      </Link>

      <div
        ref={ dropdown }
        onFocus={ () => setDropdownOpen( true ) }
        onBlur={ () => setDropdownOpen( false ) }
        className={ `absolute px-1  -right-24 mt-2 flex h-[50vh] w-[50vh]  flex-col rounded-sm border border-stroke bg-slate-300 shadow-default dark:border-strokedark dark:bg-boxdark sm:right-0 sm:w-80 ${ dropdownOpen === true ? "block" : "hidden"
          }` }
      >
        <div className="px-4.5 py-3">
          <h5 className="text-sm font-medium text-bodydark2">Notification</h5>
        </div>

        <ul className="flex h-auto flex-col overflow-y-auto">
          <li>
            <Link
              className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
              href="#"
            >
              <p className="text-sm">
                <span className="text-black dark:text-white">
                  Edit your information in a swipe
                </span>{ " " }
                Sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim.
              </p>

              <p className="text-xs">12 May, 2025</p>
            </Link>
          </li>
          <li>
            <Link
              className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
              href="#"
            >
              <p className="text-sm">
                <span className="text-black dark:text-white">
                  It is a long established fact
                </span>{ " " }
                that a reader will be distracted by the readable.
              </p>

              <p className="text-xs">24 Feb, 2025</p>
            </Link>
          </li>
          <li>
            <Link
              className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
              href="#"
            >
              <p className="text-sm">
                <span className="text-black dark:text-white">
                  There are many variations
                </span>{ " " }
                of passages of Lorem Ipsum available, but the majority have
                suffered
              </p>

              <p className="text-xs">04 Jan, 2025</p>
            </Link>
          </li>
          <li>
            <Link
              className="flex flex-col gap-2.5 border-t border-stroke px-4.5 py-3 hover:bg-gray-2 dark:border-strokedark dark:hover:bg-meta-4"
              href="#"
            >
              <p className="text-sm">
                <span className="text-black dark:text-white">
                  There are many variations
                </span>{ " " }
                of passages of Lorem Ipsum available, but the majority have
                suffered
              </p>

              <p className="text-xs">01 Dec, 2024</p>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
};
