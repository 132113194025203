'use client'
import Image from 'next/image'
import { useState } from 'react'

import { ImgList } from '.'
import _nouser from '/public/image/nouser.jpeg'

export function LineUI({ ...props }) {
    const { width, height, displayName, pictureUrl } = props
    if (!width && !height) return undefined
    if (pictureUrl) return <Image width={width} height={height} src={pictureUrl} alt={displayName||'line'} className="rounded-full" onError={e => {e.currentTarget.srcset=_nouser.src}}/>
    else return < ImgList.NoUser width={width} height={height} className=" rounded-full"/>
}

export function IfImage(this:any,{src}:{src:string}){
       const [isImg,setIsImg] = useState<boolean>(true)
try {
    return isImg?
    <img 
        src={src}
        onError={() => setIsImg(false)}
        width={60}
        height={60}
        alt={src} />:`${src}`
}catch (e){
    return `${src}`
}
}
