import { type ReactNode } from 'react'
import { Breadcrumb } from './PageLayout'
/**
 * @Row <div {...Props} className={`container row-auto flex-row grid-flow-row ${Props.className}`} />
 * @Col
 * @Icons
 * @Input
 * @Form
 * @TextArea
 * @InputIcon
 * @Button
 * @Container
 * @Flex
 * @Breadcrumb
 */

    export const Row= (Props: { [x: string]: RefType<HTMLDivElement> | ReactNode} ) => <div {...Props} className={`container row-auto flex-row grid-flow-row ${Props?.className??''}`} />
    export const flexrow= (Props: { [x: string]: RefType<HTMLDivElement> | ReactNode} ) => <div {...Props} className={`flex row-auto flex-row ${Props?.className??''}`} />
    export const Col= (Props: { [x: string]: RefType<HTMLDivElement> | ReactNode} ) => <div {...Props} className={`container col-auto flex-col grid-flow-col ${Props?.className??''}`} />
    export const flexcol= (Props: { [x: string]: RefType<HTMLDivElement> | ReactNode} ) => <div {...Props} className={`flex col-auto flex-col ${Props?.className??''}`} />

    export const Icons= (Props: { [x: string]: RefType<HTMLSpanElement> | ReactNode | typeof IconList} ) => <div {...Props} className={`icons w-fit h-fit text-center font-bold justify-center ${Props.className}`} />
    /**
     *@param name='channelToken'
    * @param placeholder="Channel access token"
    *  @param required
     * @param Props
     * @returns
     */
   export const Input= (Props: { [x: string]: RefType<HTMLInputElement> } ) => <input {...Props} placeholder={`${Props.placeholder ?? 'placeholder'}`} className={`size-full rounded-sm border border-stroke px-4 py-1 text-black outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-boxdark dark:text-white dark:focus:border-primary ${Props.className}`} />

    /**
     *
     * @param Props
     *
     * @returns
     */
    export const Form= (Props: { [x: string]: RefType<HTMLFormElement> | ReactNode | ((formData: FormData) => void)} ) => <form {...Props} className={`size-fit rounded-lg border-[1.5px] border-stroke px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-luxury dark:text-white dark:focus:border-primary dark:disabled:bg-black ${Props?.className??''}`} />

    export const TextArea= (Props: { [x: string]: RefType<HTMLTextAreaElement> | ReactNode} ) => <textarea {...Props} className={`w-full rounded-lg border-[1.5px] border-stroke bg-transparent px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary resize-none ${Props?.className??''}`} />

    export const InputIcon= (Props: { [x: string]: RefType<HTMLTextAreaElement> | ReactNode} ) => <textarea {...Props} className={`icons size-fit text-center font-bold justify-center ${Props?.className??''}`} />

    export const Button= (Props: { [x: string]: RefType<HTMLButtonElement> | ReactNode} ) => <button {...Props} className={`EzButton ${Props?.className??''}`} />

    export const Container= (Props: { [x: string]: RefType<HTMLDivElement> | ReactNode} ) => <div {...Props} className={`Container p-2 text-xl overflow-y-auto hover:animate-hover  drop-shadow-sm w-full font-bold rounded-md ${Props?.className??''}`} />

    export const Flex= (Props: { [x: string]: RefType<HTMLDivElement> | ReactNode} ) => <div {...Props} className={`flex flex-wrap gap-4 col-auto ${Props?.className??''}`} />

    export const overlay= (Props: { [x: string]: RefType<HTMLDivElement> | ReactNode} ) => <div {...Props} aria-hidden={true} tabIndex={-1} className={`overlay ${Props?.className??''}`} />

    export const pagename= (Props: { pageName?: string} ) => <Breadcrumb {...Props} />
