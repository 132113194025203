
export * as MongoSrv from "./MongoSrv"
export * from "./MongoSrv"
export { }

type MapModule<T> = {
  [P in keyof T & string]-?: T[P]
}


export const userdb: { [x: string]: MongoDB } = {
  users: {
    colName: "users",
    dbName: "bfx"
  },
  userBio: {
    colName: "usersBio",
    dbName: "bfx"
  }
}
export const adminDB: { [x: string]: MongoDB } ={
  adminBio:{
    colName: "adminBio",
    dbName: "bfx"
  },boAG:{
    colName:"boAG",
    dbName:"bfx"
  }
}
export const pipeline = {
  /**
   * Map UsersBio กับ Users
   */
  BfxUserHasId: [
    {
      $lookup: {
        from: 'users',
        localField: 'b4466_id.username',
        foreignField: 'username',
        as: 'b4466_has_id'
      }
    },
    {
      $match: {
        b4466_has_id: {
          $elemMatch: { _id: { $exists: true } }
        }
      }
    }
  ]
}
