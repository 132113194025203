export const HeadingBG = ({ ...ag }) => <svg {...ag} viewBox="0 0 1920 1080" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
  <defs>
    <radialGradient id="eb214q3uvMX2-fill" cx="0" cy="0" r="0.5" spreadMethod="pad" gradientUnits="objectBoundingBox" gradientTransform="translate(0.5 0.5)">
      <stop id="eb214q3uvMX2-fill-0" offset="9%" stopColor="#0006" />
      <stop id="eb214q3uvMX2-fill-1" offset="100%" stopColor="rgba(255,255,255,0)" />
    </radialGradient>
    <linearGradient id="bgrect" x1="0" x2="0" y1="0" y2="100%">
      <stop stopColor="#7774fd" offset="0%" />
      <stop stopColor="#630579" offset="100%" />
    </linearGradient>
  </defs>
  <ellipse rx="590.212072" ry="194.779772" transform="matrix(3.01906 2.322163-3.658573 4.756535 461.794453 184.991845)" fill="url(#eb214q3uvMX2-fill)" />
  <rect fill="url(#bgrect)" />
</svg>

export function BtnRegister ({ ...ags })
{
  return <svg {...ags} width={275} height={55} viewBox={`0 0 275 55`} className="inline-flex">
      <defs>
        <linearGradient id="xgold" x1="100%" x2="0%" y1="95%" y2="0%">
          <stop stopColor="#bf963c" offset="0%" />
          <stop stopColor="#fefecc" offset="50%" />

          <stop stopColor="#f2c567" offset="100%" />
        </linearGradient>
        <linearGradient id="xbg" x1="99%" x2="0%" y1="97%" y2="0%">
          <stop stopColor="#000000" offset="0%" />
          <stop stopColor="#302b63" offset="80%" />
          <stop stopColor="#302b63" offset="90%" />
          <stop stopColor="#302b63" offset="100%" />
        </linearGradient>
      </defs>
      <rect width="275" height="55" x="0" y="0" rx="8" ry="8" fill="url(#xbg)" />
      <rect width="275" height="55" x="0" y="0" rx="8" ry="8" fill="none" stroke="url(#xgold)" stroke-linearGradient='true' strokeWidth="4" />

      <text style={{ textAlign: 'center' }} fontFamily="Noto" fill="url(#xgold)">
        <tspan fontSize={'2rem'} x={55} dy={`1em`}>สมัครสมาชิก</tspan>
        <tspan fontSize={'0.8rem'} x={0} dx={'40%'} dy={`12`}>REGISTER</tspan>
      </text>
    </svg>
}
export function BtnRegister2 ({ ...ags })
{
  return <svg {...ags} viewBox="0 0 275 55" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" project-id="5480e117484d4870a643ac78eb1dcb59" export-id="6a1fd43fe23446509f6af59e92821f05">
    <a href="https://bflix579.com" target="_parent">
      <defs>
        <linearGradient id="untitled-s-rect1-fill" x1="0" y1="0" x2="1.015799" y2="1" spreadMethod="pad" gradientUnits="objectBoundingBox" gradientTransform="translate(0 0)">
          <stop id="untitled-s-rect1-fill-0" offset="0%" stopColor="#302b63" />
          <stop id="untitled-s-rect1-fill-1" offset="22%" stopColor="#1f1b3f" />
          <stop id="untitled-s-rect1-fill-2" offset="66%" stopColor="#302b63" />
          <stop id="untitled-s-rect1-fill-3" offset="86%" stopColor="#302b63" />
          <stop id="untitled-s-rect1-fill-4" offset="100%" stopColor="#000" />
        </linearGradient>

        <linearGradient id="untitled-s-rect1-stroke" x1="0" y1="0" x2="1" y2="1" spreadMethod="pad" gradientUnits="objectBoundingBox" gradientTransform="translate(0 0)">
          <stop id="untitled-s-rect1-stroke-0" offset="0%" stopColor="#fefecc" />
          <stop id="untitled-s-rect1-stroke-1" offset="100%" stopColor="#2d2412" />
        </linearGradient>
      </defs>
      <rect id="untitled-s-rect1" width="275" height="55" rx="2" ry="2" transform="matrix(1.000001 0 0 1-.000137 0)" fill="url(#untitled-s-rect1-fill)" stroke="url(#untitled-s-rect1-stroke)" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="1" />
    </a>
  </svg>
}

export function LineGolden ()
{
  return <svg >
    <path d="M220.792 116.744c0-41.707-41.81-75.64-93.207-75.64-51.4 0-93.205 33.933-93.205 75.64 0 37.39 33.158 68.704 77.95 74.624 3.036.655 7.166 2.003 8.21 4.597.94 2.355.614 6.048.3 8.43l-1.33 7.98c-.407 2.355-1.875 9.216 8.073 5.024s53.68-31.607 73.233-54.116h-.004c13.508-14.812 19.98-29.845 19.98-46.537" fill="#fff" />

    <path d="M108.647 96.6h-6.54c-1.003 0-1.815.813-1.815 1.813v40.612c0 .998.813 1.8 1.815 1.8h6.54c1.003 0 1.815-.8 1.815-1.8V98.403c0-1-.813-1.813-1.815-1.813m45 .01H147.1c-1.005 0-1.815.813-1.815 1.813v24.128l-18.613-25.135c-.043-.064-.092-.126-.14-.183l-.01-.013-.143-.143-.098-.08c-.015-.013-.03-.026-.047-.036l-.094-.064c-.017-.013-.036-.02-.055-.032l-.096-.055-.058-.028-.105-.045-.058-.02a.83.83 0 0 0-.11-.036l-.064-.017-.102-.02c-.026-.006-.053-.01-.077-.01-.032-.006-.064-.01-.096-.013l-.094-.006c-.023 0-.043-.002-.064-.002h-6.537c-1.003 0-1.815.813-1.815 1.813v40.612c0 .998.813 1.8 1.815 1.8h6.537c1.005 0 1.818-.8 1.818-1.8v-24.122l18.633 25.167a1.81 1.81 0 0 0 .463.448c.004.004.01.01.017.015l.113.066.05.03a1.1 1.1 0 0 0 .087.041l.087.038.053.02.126.038c.006.002.017.004.026.006a1.75 1.75 0 0 0 .465.06h6.537c1.003 0 1.815-.8 1.815-1.8V98.402c0-1-.813-1.813-1.815-1.813 M92.887 130.657H75.122V98.403c0-1.003-.813-1.815-1.813-1.815h-6.54c-1.003 0-1.815.813-1.815 1.815v40.6a1.8 1.8 0 0 0 .508 1.254.09.09 0 0 0 .024.028c.01.008.02.017.028.026a1.81 1.81 0 0 0 1.252.506h26.12c1.003 0 1.813-.815 1.813-1.815v-6.54c0-1.003-.8-1.815-1.813-1.815m96.864-23.897c1.003 0 1.813-.813 1.813-1.815v-6.54c0-1.003-.8-1.815-1.813-1.815h-26.12a1.8 1.8 0 0 0-1.259.512c-.006.006-.015.013-.02.02s-.02.02-.028.032c-.3.324-.503.764-.503 1.25v40.613c0 .486.194.928.508 1.254l.023.026.026.024c.326.314.768.508 1.254.508h26.12c1.003 0 1.813-.813 1.813-1.813v-6.54c0-1.003-.8-1.815-1.813-1.815H172v-6.865h17.762a1.81 1.81 0 0 0 1.813-1.815v-6.537c0-1.003-.8-1.818-1.813-1.818H172v-6.863h17.762z" fill="#00b900" />
  </svg>
}