import Image from 'next/image';
export { };

declare global {
      var img: typeof Image
      type paramListType = { [x :string]:string[] }
    var paramsList:[string]
    interface Number{
        day:number
        /**
         * toDDString:()
         * @returns 
         */
        toDDString(Fraction?:number): string
        toStringFormat(style:string):string
    }
    var msSecond: 1000
    var msMinute: 60000
    var msHour: 3600000
    var msDay: 86400000
    var sMinute : 60
    var sHour : 3600
    var sDay : 86400

    interface String{
        toUrl():string
        toDDString(Fraction?:number):string
        Format(text:string):string
    }


    interface Func<T> {
        ([...args]: any): T;
    }
    function returnType<T>(func: Func<T>):T
}
globalThis.returnType = function<T>(func: Func<T>) {
    return {} as T;
}

globalThis.String.prototype,"toUrl",{
    value:function():string{
        return encodeURIComponent( this.toString().replace( /\s|\%20/gm, '' ) )
   }
}

globalThis.Number.prototype.toDDString = function(fraction=0){ 
    return Intl.NumberFormat("th",{
    maximumFractionDigits:fraction,
    minimumFractionDigits:fraction
}).format(Number(this))}
globalThis.Number.prototype.toStringFormat = function(style){
    const date = new Date(this as number)
    const yyyy = date.getFullYear()
    const mm = date.getMonth()
    const dd = date.getDay()
    const HH = date.getHours()
    const hh = date.getUTCHours()
    const MM = date.getMinutes()
    const SS = date.getSeconds()

    return  style.replace("yyyy",`${yyyy}`)
    .replace("mm",`${mm}`)
    .replace("dd",`${dd}`)
    .replace("HH",`${HH}`)
    .replace("hh",`${hh}`)
    .replace("MM",`${MM}`)
    .replace("SS",`${SS}`)
    }
globalThis.img = Image
globalThis.Number.prototype.day = 24*60

globalThis.msSecond = 1000
globalThis.msMinute = 60000
globalThis.msHour = 3600000
globalThis.msDay = 86400000

globalThis.sMinute = 60
globalThis.sHour = 3600
globalThis.sDay = 86400
globalThis.paramsList = ['']



