
import Image from "next/image"
import { memo } from "react"
export { }
export const ArticleCard = memo(function ({ articleCard }: { articleCard: Article, index?: any }) {

  return <div
    className="articleCard" >
    <Image className="main-img"
      alt={ articleCard?.Title }
      src={ articleCard?.ImageURL }
      width={1040}
      height={1040}
    />
    <p>{ articleCard?.Title }     { new Date( `${ articleCard?.DateTime }` )?.toLocaleDateString() }    </p>

    <div className="content" dangerouslySetInnerHTML={ { __html: articleCard.ContentHTML } }>
    </div>

  </div>

})