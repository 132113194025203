

"use client"


import Link from "next/link";
import { useRouter } from "next/router";
import { useActionState, useEffect, useRef, useState } from "react";
import { ImgList } from "@/images";
import { type bfxUsers, type customer, BfxUserLogin, useAdminUiContext } from "@/lib";
import { EzLink } from "../EzLink"



export function LoginPlay ()
{

  const [remem, setRemem] = useState("on")

  const endpoint = "https://m8geqpm2kpnf78dkfxp9ksg94ndx9weaoa39.com"
  const path = "/login?ltoken="
  const returnUrl =
    "&returnUrl=" +
    window.location +
    "?back=" +
    window.location.hostname.split(".")[0]

  const loginForm = useRef<HTMLFormElement>(null)
  const loginBtn = useRef<HTMLButtonElement>(null)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameValid, setUsernameValid] = useState('')
  const [passwordValid, setPasswordValid] = useState('')
  /*   flog('LoginForm hasSession:', hasSession) */

  const { navOpen, setNavOpen } = useAdminUiContext()

  async function handleClick (e: any)
  {
    console.log(e.currentTarget.value)
    setRemem(e.currentTarget.value)
  }

  const [res, dispatch, isPending] = useActionState(BfxUserLogin, undefined)
  const router = useRouter()


  useEffect(() =>
  {

    fxlog('isPending:', isPending)
    if (isPending) return
    if (!isPending && res?.status == 'success')
    {
      tlog('res?.status', res)
      if (res?.result)
      {
        router.push(endpoint + path + res?.result?.account?.session?.["l-token"] + returnUrl)
      }
      navOpen && setNavOpen(false)
    }
  }, [isPending])

  let anidelay = 1
  return (
    (<div className="max-w-6xl mx-auto px-4 sm:px-6 justify-center justify-items-center items-center"
    >
      <div className="pt-32 pb-12 md:pt-40 md:pb-20">
        <div className="max-w-md min-w-md mx-auto ">
          <p aria-live="polite">
            {isPending && <div className="animate-spin border-l-violet-400" />}
            {!isPending && res?.status && <p className="text-lg text-green-500">{res?.msg} </p>}
          </p>


          <form className='loginform ize-fit rounded-lg border-[1.5px] border-stroke px-5 py-3 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-boxdark dark:text-white dark:focus:border-primary dark:disabled:bg-black bg-slate-300 bg-opacity-80 w-full gap-4 p-4 max-w-lg'
            action={dispatch} >

            <label className={`block text-gray text-lg font-medium mt-4 mb-1 fade-u aniDelay-${anidelay++}`} htmlFor="username"
            >
              Username
            </label>
            <input
              className={`form-input peer/username w-full shadow-xl text-lg text-graydark fade-u aniDelay-${anidelay++}  ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500
              `
              }
              disabled={isPending}
              onLoadedData={async e => e.currentTarget.setCustomValidity("")}
              onBlur={async e =>
              {

                if (e.currentTarget.value.match("^0[a-zA-Z0-9]{8,}"))
                {
                  setUsernameValid('ยูสเซอร์ของท่านอาจอยู่ที่ระบบ Auto')

                } else if (e.currentTarget.value.match("^[a-zA-Z]4466[0-9]{1,}"))
                {
                  setUsername(i => i?.replace(/\s|\%20/gm, '').replace(/^[a-zA-Z]{1}/g, 'B'))
                  setUsernameValid("")
                } else
                {
                  setUsernameValid("ชื่อผู้ใช้ ควรจะขึ้นต้นด้วย B4466 ตามด้วยตัวเลขอีก 5-6 ตัว")

                }
              }}


              onChange={e => setUsername(e.currentTarget.value)}
              value={username}
              id="username"
              type="text"
              name="username"
              pattern="[\S]{1,20}"
              placeholder="ชื่อผู้ใช้"
              autoComplete="username"
              title="ชื่อผู้ใช้นี้ใช้สำหรับระบบเติมถอนผ่านไลน์เท่านั้น ไม่รวมระบบออโต้นะคะ"
              autoFocus

              required
            />

            <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/username:block' htmlFor="username" >

              {usernameValid}
              {/*  {`${loginForm?.current?.['username']?.validity.valid}`} */}
              {usernameValid.match('Auto') && <li><Link className="text-blue-400" href={{pathname:'/auto'}}>ทางเข้าระบบ Auto คลิก</Link></li>}
            </label>


            <label className={`block text-gray text-lg duration-100 font-medium  mt-4 mb-1 fade-u aniDelay-${anidelay++}`} htmlFor="password">
              Password
            </label>
            <input
              className={`form-input peer/password duration-100 w-full shadow-xl text-lg text-graydark fade-u aniDelay-${anidelay++} ring-2 [&:not(:placeholder-shown):not(:focus)]:invalid:ring-rose-500 [&:not(:placeholder-shown):not(:focus)]:valid:ring-green-500`}
              disabled={isPending}
              id="password"
              type="password"
              name="password"
              onBlur={({ currentTarget }) =>
              {
                if (currentTarget.value.match(/^[A-Za-z0-9]{4,16}$/)) setPasswordValid("")
                else setPasswordValid("รหัสผ่านต้องมีอย่างน้อย 4 ตัวอักษร")
              }}
              onChange={({ currentTarget }) => setPassword(currentTarget.value)}
              value={password}
              pattern="^[A-Za-z0-9]{4,16}$"
              title="รหัสผ่านมีอย่างน้อย 4 ตัว"
              minLength={4}
              placeholder="รหัสผ่าน"
              autoComplete="current-password"
              required
            />
            <label className='text-red hidden text-lg font-medium mt-4 mb-1 fade peer-[&:not(:placeholder-shown):not(:focus):invalid]/password:block' htmlFor="password" >
              {passwordValid}
            </label>
            <div className={`flex flex-row  mt-4 fade-u items-center duration-100  aniDelay-${anidelay++}`}>
              <input type="checkbox" id={'remember'} className="form-checkbox" value={remem}
                disabled={isPending}
                onChange={async (e) => handleClick(e)} />
              <label className="flex items-center ml-2 mr-auto" htmlFor="remember">
                จดจำ
              </label>
              <EzLink href={ { pathname: "/line" } } className="text-sm font-medium text-blue-400 hover:underline">
                ลืมรหัสผ่าน?
              </EzLink>
            </div>


            <button type="submit"
              className={`p-2 text-xl text-indigo-100 disabled:text-zinc-600 disabled:hover:cursor-default enabled:active:animate-ping enabled:hover:animate-hover hover:-translate-y-px hover:translate-x-px drop-shadow-sm size-fit font-bold rounded-md mt-6 mb-4 w-full ring-2 inset-1 enabled:ring-blue-600  ${isPending ? 'animate-bounce-2 aniDelay-0' : 'aniDelay-' + anidelay + ' fade-u'} disabled:read-only `}
              disabled={!(loginForm?.current?.['username']?.validity.valid && loginForm?.current?.['password']?.validity.valid)}
              id='loginBtn'
              name='loginBtn'
              ref={loginBtn}
            >
              {isPending ? <span className="cubic-loader" /> : <span className="flex flex-row justify-center">LOGIN เข้าสู่ระบบ</span>}

            </button>

          </form>

          <div className={`flex flex-col fade-u items-center text-gray my-6 `}>
            <div className={`flex flex-row w-full items-center my-6 aniDelay-${anidelay++}`}>
              <div className="border-b w-full h-0.5 border-gray mr-3"></div>
              <div className={`italic`}>Or</div>
              <div className="border-b w-full h-0.5 border-gray ml-3"></div>
            </div>
            <form>
              {/*    <div className="flex flex-wrap -mx-3 mb-3">
        <div className="w-full px-3">
          <button className="btn px-0 text-white bg-gray-900 hover:bg-gray-800 w-full relative flex items-center">
            <svg className="w-4 h-4 fill-current text-white opacity-75 shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.95 0C3.578 0 0 3.578 0 7.95c0 3.479 2.286 6.46 5.466 7.553.397.1.497-.199.497-.397v-1.392c-2.187.497-2.683-.993-2.683-.993-.398-.895-.895-1.193-.895-1.193-.696-.497.1-.497.1-.497.795.1 1.192.795 1.192.795.696 1.292 1.888.895 2.286.696.1-.497.298-.895.497-1.093-1.79-.2-3.578-.895-3.578-3.975 0-.895.298-1.59.795-2.087-.1-.2-.397-.994.1-2.087 0 0 .695-.2 2.186.795a6.408 6.408 0 011.987-.299c.696 0 1.392.1 1.988.299 1.49-.994 2.186-.795 2.186-.795.398 1.093.199 1.888.1 2.087.496.596.795 1.291.795 2.087 0 3.08-1.889 3.677-3.677 3.875.298.398.596.895.596 1.59v2.187c0 .198.1.497.596.397C13.714 14.41 16 11.43 16 7.95 15.9 3.578 12.323 0 7.95 0z" />
            </svg>
            <span className="flex-auto pl-16 pr-8 -ml-16">Continue with GitHub</span>
          </button>
        </div>
      </div> */}


              <EzLink
                href={{pathname:'/register'}}
                className={`px-0  w-full relative flex items-center fade-u aniDelay-${anidelay++}`}
              >
                <ImgList.ButtonRegister text="สมัครสมาชิก_register"
                />
              </EzLink>


            </form>
            <Link href={{pathname:"/line"}} className={`text-blue-600 hover:underline fade-u aniDelay-${anidelay++}`}>
              ลืมรหัสผ่าน?
            </Link>
          </div>
        </div>
      </div>
    </div>)
  )
}


