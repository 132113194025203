'use client'
import Link from "next/link"
import { usePathname } from "next/navigation"

export function EzLink({...ags}){
    const pathname = usePathname()
    return <Link  { ...ags } href={{
        query:{
            utm_source:pathname.replace("/", "home"),
            utm_content:"EzLink"}
    ,...ags.href} }></Link>
}