'use client'
import { usePathname, useRouter } from "next/navigation"
import { useRef, useState } from "react"
import { useadminUiContext, useSessionStorage } from "@/lib"
import { BoMenuLink, EzLoading, LNav_Menu, PageHeader } from "@/Components"

export function AdminUiContexProvider ({
  children
}: {
  children: React.ReactNode
})
{
  laylog("AdminUiContexProvider")
  const themeRef = useRef<HTMLDivElement>(null)
  const trigger = useRef(null)
  const pageScroll = useRef(null)
  const siteNav = useRef(null)
  const GameframeRef = useRef<HTMLIFrameElement>(null)

  const [navOpen, setNavOpen] = useSessionStorage<boolean>("navOpen", false)
  const [sideBarOpen, setSideBarOpen] = useSessionStorage<boolean>("sideBarOpen", true)
  const [delay, setDelay] = useState<number | undefined>(300)


  const pathname = usePathname()
  const router = useRouter()

  const [darkThemeContext, setDarkThemeContext] = useSessionStorage<boolean>("darkThemeContext", false)
  const [showInstallBanner, setShowInstallBanner] = useSessionStorage<boolean>('showInstallBanner', false)
  const [top, setTop] = useState(true)

  /*  /\_____________________  Dependencies  ______________________/\  */

  return <useadminUiContext.Provider value={{
    top, setTop,
    themeRef, trigger, siteNav, pageScroll, navOpen, setNavOpen, delay, pathname, router, setDelay, GameframeRef, sideBarOpen, setSideBarOpen,
    darkThemeContext, setDarkThemeContext, showInstallBanner, setShowInstallBanner
  }}>
    <EzLoading>
    <main  className={`${darkThemeContext ? "dark":"light"} flex flex-col w-full min-h-screen h-full`}>
    <PageHeader /> 
     <div className='flex flex-row h-full border border-blue-500 grow'>
      <LNav_Menu MenuLink={BoMenuLink} />
    
        {children}
      </div>
    </main></EzLoading>
  </useadminUiContext.Provider>

}
