'use client'
import { useEffect } from 'react'


export function TestServerPass (props:{next:any})
{
     celog("TestServerPass")

     useEffect(() =>
     {
          props.next && fxlog("next=",props.next)
     }, [props.next])




     return null
}