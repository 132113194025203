"use client"
export { }
import { useEffect, useState } from "react"

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
): [T, s_S<T>] {

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = sessionStorage?.getItem(key)
      return item && JSON.parse(item) || initialValue
    } catch (e) { return initialValue }
  })

  useEffect(() => {
    try {
      sessionStorage?.setItem(key, JSON?.stringify(typeof storedValue === "function" ? storedValue(key) : storedValue))

    } catch (error) {
      sessionStorage?.setItem(key, JSON?.stringify(initialValue))
    }


  }, [key, storedValue])

  return [storedValue, setStoredValue]
}


