'use client'
import { startTransition, useEffect, useState } from 'react'
import { Articles, Ez } from '..'
import { UI_Bo_regis_Form } from '../Form'
import { BoSubUserTable } from '../Tables/BoSubUserTable'
import { adminDB, findOne } from '@/lib'


export function BoSubUser ()
{

    const {adminInfo,agInfo} = useAdminContext()
    const [ subBoUser, setSubBoUser ] = useState<BoUser[]|undefined>( undefined )


    useEffect( () =>
    {
        if(!subBoUser?.[0]&&agInfo?.username){
            findOne<AgInfo&{account:BoUser[]}>(adminDB.boAG,{username:agInfo?.username},{
                projection:{ "account.password": 0 ,article:0}
            })
            .then(res => {
                if(res?.account?.[0]){
                    startTransition(()=>{
                        setSubBoUser(res?.account)
                    })
                }
            })
        }


    }, [] )
    return <Ez.Container>
        <UI_Bo_regis_Form />
        <section>
            <BoSubUserTable DataTable={ subBoUser} />
        </section>
    </Ez.Container>





}