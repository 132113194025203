
if ( !process.env.NEXT_PUBLIC_ROOT_DOMAIN ||!process.env.NEXT_PUBLIC_BFX_USER_ENDPOINT) { throw new Error }
/**
 * UserEndPoint
*/
export const EndPoint = process.env.NEXT_PUBLIC_BFX_USER_ENDPOINT || "https://a.quantumdynamicstech.com"
const secret = process.env.AUTH_SECRET
export const usersDB: MongoDB = { colName: 'customer', dbName: 'bfx' }
declare function updateBfx<Type> ( obj: Type ): Type & PropEventSource<Type>
export var BfxUserThis: customer = {}
/* const watchBfxUser = updateBfx<bfxUsers>(BfxUserThis) */

/* watchBfxUser.on('creditChanged',function(this:bfxUsers,n){
  if(this._id){
    updateOne(usersDB,{_id:{$oid:this._id}},{$set:{credit:n}},{upsert:true})
  }
  
}) */
/* type update= (this:Pick<bfxUsers,"credit"|"username"|"Session"|"historyPlay">)=>bfxUsers extends infer return ? return :never */



export interface BfxGameProvider
{
  _id?: string
  name: string
  type: string
  game: string
  status?: string
  logo?: string
  gameList?: Games[]


}

export interface Games {
  _id?: string
  type: string
  name: string
  logo: string
  order?: number
  feature_spin?: boolean
  img: string
  code: string
}
export type UserLoginType = "phone" | "b44678" | "b4466" | "absolute"

export interface bfxUsers
{
  username: string
  status?: string,
  type: UserLoginType,
  credit: A extends { credit?: number, creditUpdateAt?: number }
  ? A
  : number
  historyPlay?: BfxHistoryPlay[]
  session: bfxUsers_Session
  createAt?: number
  updateAt?: number
}
export type BfxUser = Partial<bfxUsers>

export interface bfxUsers
{
  Notice?: [{
    NoticeMsgId?: NoticeMsg
    Readed?: boolean
    ReadedAt?: number
  }]
}
export interface userBio
{
  bank: [ {
    bank_name: string,
    bank_no: string
  } ],
  fullName: string,
  disPlayName?: string,

  line: Line[]
  phone?: string
}

interface Line { chanelId: string, pictureUrl?: string, userId: string, displayName?: string }

export type customer = Partial<{
  account: Partial<bfxUsers>,
  bio: Partial<userBio>,
  _id: oId,
  updateAt?: number
}>
type MyPartialType<Type> = {
  // For every existing property inside the type of Type
  // convert it to be a ?: version
  [ Property in keyof Type ]?: Type[ Property ]
};


export interface bfxUsers_Session
{
  "u-token"?: string
  "l-token"?: string
  updateAt?: number
}
export interface BfxHistoryPlay
{
  provider: string, gamecode?: string, updateAt?: number
}

export interface BfxCreditRefund {
  _id?: string
  BfxUsersId?: string
  transactionId?: number
  amount?: number
  createBy?: string
  dateTime?: number
  updateAt?: number
  expireAt?: number
}

export interface BfxTransaction
{
  _id?: string
  source?: string
  type?: string
  user:string
  amount: number
  before?:number,
  after?: number,
  status?: string,
  total_credit?: number
  timestamp?: number,
}

type userDB = A extends { dbName: "bfx", collection: "users" } ? A : never
export const bfxUserURL = {
  Login: `https://c.quantumdynamicstech.com/v1/userlogin`,
  Balance: `${ EndPoint }/api/v1/balance`,
  ChangePassword: `${EndPoint}/api/v1/changepassword`,
} as const


