'use client'


import { useRouter } from "next/navigation";
import { Ez } from ".";
import { UserLogOut } from "../lib/actions/authAction";
import { useUserContext } from "@/lib";


export function LogOutBtn ({ navOpen, setNavOpen, className = '' }: { navOpen?: boolean, setNavOpen?: s_S<boolean>, className?: string })
{

  const { setUserInfo, setHasSession } = useUserContext()
  const router = useRouter()
  function HandleLogOut ()
  {
    setUserInfo(undefined)
    setHasSession(undefined)
    UserLogOut().then(() => router.push('/'))

  }

  return (
    <Ez.Button className={`menu-btn ${className}`} onClick={HandleLogOut}>
      ออกจากระบบ LOGOUT  </Ez.Button>

  )
}
