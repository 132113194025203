'use client'

import { usePathname, useRouter } from "next/navigation"
import { useEffect, useRef, useState } from "react"
import { NavigationEvents } from "../lib/utils/NavigationEvents"
import { useLocalStorage, userUiContext, useSessionStorage } from "@/lib"
import { ContactsBtn, EzLoading, InstallBanner } from "@/Components"

export function UserUiContexProvider ({
  children
}: {
  children: React.ReactNode
})
{
  const themeRef = useRef<HTMLDivElement>(null)
  const trigger = useRef(null)
  const pageScroll = useRef(null)
  const siteNav = useRef(null)
  const GameframeRef = useRef<HTMLIFrameElement|null>(null)

  const [navOpen, setNavOpen] = useState<boolean>(false)
  const [gamePlayState, setGamePlayState] = useState<boolean>(false)
  const [delay, setDelay] = useState<number | undefined>(300)

  const pathname = usePathname()
  const router = useRouter()

  const [themeContext, setThemeContext] = useLocalStorage<"light" | "dark">('themeContext', "dark")
  const [showInstallBanner, setShowInstallBanner] = useSessionStorage<boolean>('showInstallBanner', false)
  const [top, setTop] = useState(true)
  const [isClient, setIsClient] = useState(false)

  useEffect(() =>
  {
   /*  fxlog('setIsClient useEffect:', isClient) */
    setIsClient(true)
    return () => setIsClient(false)
  }, [isClient])

  /*  /\_____________________  Dependencies  ______________________/\  */

  return isClient && <userUiContext.Provider value={{
    top, setTop,
    themeRef, trigger, siteNav, pageScroll, navOpen, setNavOpen, delay, pathname, router, setDelay, gamePlayState, setGamePlayState, GameframeRef,
    themeContext, setThemeContext, showInstallBanner, setShowInstallBanner
  }}>



 {children}
   <EzLoading>
      <NavigationEvents />
    </EzLoading>


    <EzLoading>
      <ContactsBtn />
    </EzLoading>

    <EzLoading>
      <InstallBanner />
    </EzLoading>


  </userUiContext.Provider>

}
