'use client'
import { ImgList } from "@/images"
import { UserLogOut } from "@/lib"
import Image from "next/image"
import Link from "next/link"
import { startTransition, useEffect, useRef, useState } from "react"

export  function DropdownUser ()
{

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const { adminInfo, setAdminInfo,setAgInfo } = useAdminContext()
  const dropdown = useRef<any>(null)
  const [profileImg,setProfileImg] = useState<string|undefined>(undefined)
  useEffect(()=>{
    fxlog("adminInfo",adminInfo?.name)
if(adminInfo?.name!=profileImg){
  startTransition(()=>setProfileImg(adminInfo?.name))
}
  },[adminInfo?.name])

  return (
    <div className="relative min-w-fit">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center gap-4"
      >
        <span className="hidden text-right lg:block">
          <span className="block text-sm font-medium text-black dark:text-white">
            {adminInfo?.name&&adminInfo?.name}
          </span>
          <span className="block text-xs"> {adminInfo?.username}</span>
        </span>

        <span className="h-12 w-12 rounded-full">
          {adminInfo?.name ?
            <Image
              priority
              width={160}
              height={160}
              src={"/api/image/goldborder" + `?t=${adminInfo?.name}`}
              className="rounded-full" alt={""} />
            :
            <ImgList.NoUser
              className="rounded-full"
            />}
        </span>

      </button>

      {/* ⇩ Start  `````````````````````````  Dropdown Start  ````````````````````````` Start ⇩ */}
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onMouseLeave={() => setDropdownOpen(false)}
        className={`absolute z-9999 right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark ${dropdownOpen === true ? "block" : "hidden"
          }`}
      >
        <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-7.5 dark:border-strokedark">
          <li>
            <Link
              href="#"
              className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
            >

              My Profile
            </Link>
          </li>
          <li>
            <Link
              href="#"
              className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
            >

              My Contacts
            </Link>
          </li>
          <li>
            <Link
              href="#"
              className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
            >

              Account Settings
            </Link>
          </li>
        </ul>
        <button className="flex items-center gap-3.5 px-6 py-4 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          onClick={ () => UserLogOut( "_bo_dSV" ).then( ( res => res &&
            startTransition(()=>{
              setAdminInfo( undefined )
              setAgInfo(undefined)
            })  ) ) }
        >
          Log Out
        </button>
      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  )
}