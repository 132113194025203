'use client'
import Link from "next/link"
import { startTransition, useActionState, useEffect, useState } from "react"
import { Ez } from ".."
import { BoLogin } from "@/lib"


export function UI_Bo_Login_Form ()
{

  const [remem, setRemem] = useState("on")
  const {setAgInfo,setAdminInfo} = useAdminContext()
  const [respone, dispatch,isPending] = useActionState(BoLogin, undefined)
  async function handleClick (e: any)
  {
    setRemem(e.currentTarget.value)
  }
  useEffect(()=>{
    if(respone?.result?.agInfo){
      startTransition(()=>setAgInfo(respone?.result?.agInfo))
    }
    if(respone?.result){
      startTransition(()=>setAdminInfo(respone?.result))
    }

  },[respone])


  return (
    <div className="container m-auto sm:max-h-fit sm:max-w-25 justify-center items-center w-full h-fit sm:p-4 bg-slate-800 bg-opacity-50 ring-dark-hover-400 ring-1 p-1 rounded">

      <form className='auth m-auto' name='DarinBET' action={dispatch}>
        <label className="block text-gray-100 text-lg font-medium mb-1" htmlFor="username">
          Username <small>ชื่อผู้ใช้</small>
        </label>
        <Ez.Input
          id="username"
          type="text"
          name="username"
          className="w-full p-1 rounded-sm shadow-xl text-lg text-gray-600 inset-1"
          placeholder="ชื่อผู้ใช้"
          autoComplete="username"
          required
        />

        <label className="block mt-2 text-gray-200 text-lg font-medium mb-1" htmlFor="password">
          Password <small>รหัสผ่าน</small>
        </label>

        <Ez.Input
          id="password"
          type="password"
          name="password"
          className="form-inpu p-1 rounded-sm w-full shadow-xl text-lg text-gray-600 inset-1"
          placeholder="รหัสผ่าน"
          autoComplete="password"
          required
        />

        <div className="flex justify-between">
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox" value={remem} onChange={handleClick} />
            <span className="text-gray-100 ml-2">จดจำ</span>
          </label>
          <Link href="#" className=" text-sm font-medium text-blue-200 hover:underline">
            ลืมรหัสผ่าน?
          </Link>
        </div>

        <div aria-live="polite">
          {respone?.status && (

            <p className="text-sm text-red-500"> {respone?.msg} </p>

          )}
        </div>

        <button type="submit" disabled={isPending} className={`p-2 text-xl bg-slate-900 hover:bg-blue-700 shadow-xl w-full font-bold disabled:animate-line3`}>
          เข้าสู่ระบบ BFLIX
        </button>

      </form></div>
  )
}
