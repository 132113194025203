'use client'
import { type JSX, useState } from "react"
import { CreditTransferOnlyBalanceBTN } from "../../Form/CreditTransferOnlyBalanceBTN"

export {}
export function TableCell_Credit ({int=0}:{int?: number})
{
  return  <div className={`rounded-full 
    ${int < 0 ? 'bg-rose-500' : 'bg-green-500 '}
  ${int < -10000 || int > 10000 ? 'bg-opacity-100' :
      int < -5000 || int > 5000 ? 'bg-opacity-90' :
          int < -3000 || int > 3000 ? 'bg-opacity-80' :
            int < -1500 || int > 1500 ? 'bg-opacity-70' :
              int < -1000 || int > 1000 ? 'bg-opacity-60' :
                int < -500 || int > 500 ? 'bg-opacity-50' :
                  int < -100 || int > 100 ? 'bg-opacity-40' :
                    'bg-opacity-30'}`}>
    <span className='drop-shadow text-2xl font-semibold text-meta-4 dark:text-meta-1'>{int.toDDString(2) || 0.00}</span>
  </div>
}

export function Actions3Button({userId}:{userId?:string}){
  const [userSlot,setSlot]=useState<JSX.Element|null>(null)
  function setHandle(){
    setSlot(<CreditTransferOnlyBalanceBTN setSlot={setSlot} userId={userId}/>)
  }
  return  <div className="mx-1 space-x-3.5">
      {userSlot}
  <button className="hover:text-primary" onFocus={setHandle} onClick={setHandle}>
    <Icons iconName='Currency_Exchange' />
  </button>

  <button className="hover:text-primary" tabIndex={-1}>
    <Icons iconName="Tune" />
  </button>
  <button className="hover:text-primary" tabIndex={-1}>
    <Icons iconName="Edit" />
  </button>
  

</div>
}
