'use client'

import { type BfxTransaction, type bfxUsers, useAdminUiContext } from "@/lib"
import { memo, startTransition, useEffect, useState } from "react"
import { AGShowBalance, CreditTransfer, DropdownNotification, EzLoading } from "../.."
import { DarkModeSwitcher } from "./DarkModeSwitcher"
import { DropdownUser } from "./DropdownUser"



export const PageHeader = memo( function ()
{

	const [ response, setResponse ] = useState<BfxTransaction | undefined>( undefined )
	const { sideBarOpen, setSideBarOpen } = useAdminUiContext()
	const { agInfo, setAgInfo } = useAdminContext()
	const { bfxTransactionList, setBfxTransactionList } = useBfxContext()
	const [ userBalance, setUserBalance ] = useState<bfxUsers | undefined>( undefined )
	useEffect( () =>
	{
		if ( response?.status == "success" )
		{
			fxlog( "bfxTransactionList=", bfxTransactionList )
			if ( !response?.total_credit ) return
			const { total_credit, after } = response
			startTransition( () =>
			{
				bfxTransactionList && setBfxTransactionList( n =>
					n ?
						[ response as BfxTransaction, ...n ] :
						[ response as BfxTransaction ]
				)
				agInfo?.username && setAgInfo( { ...agInfo, balance: total_credit } )
				agInfo?.username && setUserBalance( n => n && { ...n, balance: after } )
			} )
		}
	}, [ response ] )
	return (
		<header className={ `grid grid-rows-1 sticky top-0 max-h-15 w-full drop-shadow-1 dark:drop-shadow-none bg-gd-mystic dark:bg-gd-royal` }>
			<div className="flex items-center justify-between px-4 shadow-2 md:px-6 2xl:px-11">
				<div className="h-full box-border items-center sm:gap-4">

					{/* <!-- Hamburger Toggle BTN --> */ }
					<button
						aria-controls="sidebar"
						onClick={ ( e ) =>
						{
							e.preventDefault()
							setSideBarOpen( n => !n )
						} }
						className={ `relative nav-toogler hover:cursor-pointer text-center items-center size-10 before:w-10 after:w-10 after:mt-2 before:h-1 after:h-1 ${ sideBarOpen && "active" }` }
					>


					</button>
					{/* <!-- Hamburger Toggle BTN --> */ }
				</div>
				<div className="static w-full h-full sm:inline-flex sm:flex-row z-99">

					<EzLoading><AGShowBalance agInfo={ agInfo } setAgInfo={ setAgInfo } /></EzLoading>
					<div className='relative'>
						<div inert tabIndex={ -1 } aria-hidden="true" className="absolute flex h-full w-full justify-center items-end align-middle">
							<EzLoading>{ userBalance?.status &&
								<label className={ `flex duration-75 mr-[50%] ml-auto text-green-700 dark:text-green-500 text-lg font-medium  ${ userBalance.status.match( "error" ) && "animate-ping-once" } ` } htmlFor="username">
									{
										userBalance.status.match( "success" )
											? userBalance?.credit?.toDDString( 2 )
											: `❌`
									}
								</label> }</EzLoading>
						</div>
						<EzLoading><CreditTransfer SetResponse={ setResponse } SetUserBalance={ setUserBalance } /></EzLoading>
					</div>
				</div>
				<div className="flex flex-grow-0 items-center gap-3 2xsm:gap-7">
					<EzLoading><DropdownNotification /></EzLoading>
					{/* ⇩ Start  `````````````````````````  Dark Mode Toggler  ````````````````````````` Start ⇩ */ }
					<DarkModeSwitcher />

					<EzLoading><DropdownUser /></EzLoading>
					{/* <!-- User Area --> */ }
				</div>
			</div>

		</header>
	)
} )
