
/* export * from './LoginBflix'
 */
export * from './authAction'
export * from './handleEditDB'
export * from './getUsers'
export * from './bfxAPI'
export * from './getDataDB'
export * from './LineBot'
export * from './bfxAPI'
export * as LineBotApi from './linebotAPI'

export async function EzFetch(url:string | URL | Request,init?: RequestInit) {
            return await fetch(url,init??{}).then(res => res.clone().json())
        }