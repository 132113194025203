
import type { JSX } from "react"
import { BoSubUser } from "./BoSubUser"
import { EzPreviewPage } from "../EzPreviewPage"
import { ChatUi } from "../LineBot/ChatUi"
import { DatabaseTable } from "../Tables/DatabaseTable"
import { LineUserListTable } from "../Tables/LineUserListTable"
import { MembersTable } from "../Tables/MembersTable"
import { SheetsTableBase } from "../Tables/SheetsTableBase"
import { TransactionTable } from "../Tables/TransactionTable"
import { PushPage } from "./PushPage"

export const BoMenuLink:BoMenuLink[] = [
    { title: "DARIN.BET" },
    {
        title: "AG", iconR: 'keyboard_double_arrow_up', passHref: true,
        subMenu: [
            { title: "Transfer", href: "/transfer", iconR: 'Currency_Exchange' },
            { title: "สมาชิก", href: "/userlist", iconR: "Group" },
            { title: "Line USER", href: "/lineuser", iconR: "Group" },
            { title: "LineOA CHAT", href: "/linechat", iconR: "Group" },
            
        ]
    },
    {
        title: "Setting", iconR: 'keyboard_double_arrow_up', passHref: true,
        subMenu: [
            { title: "ค่ายเกมส์", href: "/Gameprovider", iconR: "Sword_Rose" },
            { title: "SubUser", href: "/subuser", iconR: "Sword_Rose" },

            { title: "บช.", href: "/setting", iconR: 'Currency_Exchange' },
            { title: "Contents", href: "/article", iconR: "Kid_Star" },
            { title: "ทางเข้า", href: "https://bflix.darin.bet", iconR: "Support_Agent" },
            
        ]
    },
    {
        title: "สำหรับทดสอบระบบ", iconR: 'keyboard_double_arrow_up', passHref: true,
        subMenu: [
            { title: "DataBase", href: "/databasetable", iconR: "Group" },
            { title: "SheetsTest", href: "/sheetstable", iconR: "Group" },
            { title: "PreviewUI", href: "/previewui" },
            { title: "Push", href: "/push" },
        ]
    }
]
export const BoContent:{[x:string]:JSX.Element} = {
    
      transfer: <TransactionTable />,
    lineuser: <LineUserListTable />,
    userlist: <MembersTable />,
    Gameprovider: <MembersTable />,
    previewui: <EzPreviewPage />,
    linechat: <ChatUi />,
    databasetable: <DatabaseTable />,
    sheetstable: <SheetsTableBase />,
    push: <PushPage />,
    subuser: <BoSubUser />
  }