'use client'

import { Ez, Form_Elements } from "."
import { iconList } from "../lib/utils/iconList"

export function EzPreviewPage ()
{

  const gloupflex = 'flex justify-center items-center gap-4'
  const buttonList = [
     "hover"
    , "active"
    , "disabled"
    , "animate-spin"
    , "animate-ping"
    , "animate-pulse"
    , "animate-bounce"
    , "animate-linspin"
    , "animate-easespin"
    , "animate-easespin"
    , "animate-right-spin"
    , "animate-ping-once"
    , "animate-topbottom"
    , "animate-bottomtop"
    , "animate-spin-1.5"
    , "animate-spin-2"
    , "animate-spin-3"
    , "animate-line1"
    , "animate-line2"
    , "animate-line3"
  ]
  return (

    <div className="grid grid-flow-row gap-4">

      <div className="flex flex-wrap size-fit">Ez.Button

        <section className='border gap-30'>

          <Ez.Button>toTHead</Ez.Button>
          <Ez.Button>Primary</Ez.Button>
          <Ez.Button className="bg-secondary">secondary</Ez.Button>
        </section>


        <section className="border">
          <div className="">
            {buttonList.map((i, k) =>
              <div key={k} className={gloupflex}>
                <button className={i}>{i}</button>
                <p>{i}</p>
              </div>
            )}

          </div>
        </section>
        <section className="relative border">
          <Ez.pagename pageName="Icons" />
          <div className="flex flex-wrap gap-4 col-auto">
            {iconList.map((i, k) =>
              <div className="flex flex-col bg-black p-4 justify-center items-center gap-4" key={k}>
                <Icons className="text-title-xl2" iconName={i} />
                {i}
              </div>
            )}
          </div>
        </section>
        <section className="w-full relative border">
          <Ez.pagename pageName="Form_Elements" />
          <Form_Elements />
        </section>
      </div>
    </div>
  )
}
