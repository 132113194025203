'use client'

import { useEffect } from "react"
import { useLocalStorage } from "."
import { getCookieValue, setCookie } from "../utils/cookieFnc"


export function useColorMode() {
  const [colorMode, setColorMode] = useLocalStorage('colorMode', "light")
  useEffect(() => {
    const className = "dark"
    const bodyClass = window.document.body.classList

    colorMode === "dark"
      ? bodyClass.add(className)
      : bodyClass.remove(className)
  }, [colorMode])

  return [colorMode, setColorMode]
};


export async function useCookkieMode() {
  const colorMode = getCookieValue("color-theme")
  const setColorMode = setCookie("color-theme", "light")

  useEffect(() => {
    const className = "dark"
    const bodyClass = window.document.body.classList

    async function getTheme() {
      (await colorMode) === "dark"
        ? bodyClass.add(className)
        : bodyClass.remove(className)
    }
  }, [colorMode])

  return [colorMode, setColorMode]
}

