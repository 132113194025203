'use client'

import { UI_BtnTest } from ".."



export function UI_TextEditer ({ placeholder }: { placeholder?: string })
{
    return (
        <div className=''>
            <div className='absolute aligh al-flx-end m-1 border-none'>
                <UI_BtnTest />
                <button className='prm'>ปุ่มยืมตังค์</button>
            </div>
            <textarea
                placeholder={placeholder}
                autoFocus={true}
                className={`inputChatEditer`}
            />

        </div>

    )
}