export default function ImageLoader({
  src,
  width,
  quality,
}: {
  src: string;
  width?: number;
  quality?:number;
}) {


  if (src.match(/.*line-scdn.*/)&&width&&!src?.match(/.*preview.*/)){
   return src += "/"+(width<720)&&'/preview'
  }else if (src.match(/.*api\/image.*/))
    return src
    else {
   width&&(src += `${src.match(/.*\?.*/)?"&":"?"}${"w="+width}`)
   quality&&(src += `${src.match(/.*\?.*/)?"&":"?"}${"q="+quality}`)
   //fnclog("xloader=",xloader)
   return src
  }
}
