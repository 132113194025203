'use client'

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { memo } from 'react';
import { Ez, EzLoading, } from '..';
import { LineBotApi, useLineBotContext } from '../../lib';


export const UI_LineChatList = memo(function ()
{
  const pathname = usePathname()
  const { lineChannelList } = useLineBotContext()



  async function update (i: LineBotChannel)
  {
    flog('UpdateBotInfo', i)
    if (!i?.channelInfo?.updateAt)
    {
      i?.Token && LineBotApi.UpdateBotInfo(i?.Token)
    } else if (i?.channelInfo?.updateAt + ((3600 * 60 * 24) / 1000) < Date.now())
    {
      flog('updateAt:', i?.channelInfo?.updateAt, ' Date.now():', Date.now())
      flog('((3600*60*24)/1000):', i?.channelInfo?.updateAt + ((3600 * 60 * 24) / 1000), ' Date.now():', Date.now())
      flog('((3600*60*24)):', i?.channelInfo?.updateAt + ((3600 * 60 * 24)), ' Date.now():', Date.now())
      i?.Token && LineBotApi.UpdateBotInfo(i?.Token)
    }
  }

  return (<>
    <div className='flex flex-row'>

      <Ez.Input
        id={`${pathname.replace(/linebot\/(.*)\/.*/, `linebot/$1/`)}`}
        name={`${pathname.replace(/linebot\/(.*)\/.*/, `linebot/$1/`)}`}
        placeholder="ค้นหา" />
      <button className='btn bg-black' >Add</button>
    </div>
    <div className='flex h-full flex-col overflow-y-auto'>

      {lineChannelList[0] && lineChannelList?.map((i, k) =>
      {
        if (!i?.channelInfo?.displayName)
        {
          update(i)
        }
        return (
          (<EzLoading key={k}>
            <Link className={`items-center w-full h-fit p-2 border-b hover:opacity-80 ${(pathname == (`${pathname.replace(/linebot\/(.*)\/.*/, `linebot/$1/`)}${i?.channelId}`)) && 'bg-hover dark:bg-dark-hover'}`}
              href={{pathname:(`${pathname.replace(/linebot\/(.*)\/.*/, `linebot/$1`)}/${i?.channelId}`)}}>
              <div className='flex flex-row'>
                {i?.channelInfo?.pictureUrl &&
                  <EzLoading >
                    <Image
                      src={i?.channelInfo?.pictureUrl ?? ''}
                      onError={() => update(i)}
                      width={50}
                      height={50}
                      alt={i?.channelInfo?.displayName ?? ''}
                    /> </EzLoading>
                }
                <ul className='mx-2'>
                  <li>
                    {i?.channelInfo?.displayName}
                  </li>
                  <li>
                    {i?.channelInfo?.basicId}
                  </li>
                </ul>
              </div>
            </Link>
          </EzLoading>)
        )
      })}

    </div>
  </>)
}
)
