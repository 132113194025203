import { type Key } from "react"
import { IfImage } from "../images"
import { EzLoading } from "../Components"


declare global
{
	interface Array<T>
	{
		filterUndefined: () => Array<Exclude<T, undefined>>
		toTable(): T extends (
			Thead?: T[]
		) => infer R 
		? R 
		: any
		toTHead():T extends (
			Thead?: T[]
		) => infer R 
		? R 
		: any
		toTBody (headkeys?: any[]): any
		getAllKey (): Array<T>
		addToIndex (item: T, index?: Key): Array<T>
		remove (index: Key): Array<T>
		findVal (find: string): T
		setUpdate (): Array<T>
		setUpdate (index: string): Array<T>
		pushUpdate (array: Array<T>, index?: string): Array<T>
	}
}

/**
 * ลบข้อมูล
 */
Array.prototype.remove = function <T> (index: Key)
{
	return this.filter((o: T, id: number) => id !== index as number)
}

Array.prototype.findVal = function <T extends Object> (find: T)
{
	return this.find(f => f[Object.keys(find)[0]] === Object.values(find)[0])
}
/**
 * สร้างตารางอัตโนมัติ
 */
Array.prototype.toTable = function (Thead?: [])
{
	return (
		<div className="max-w-full max-h-full rounded-sm border border-stroke bg-white px-4 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-2">
			<div className="max-w-full">
				<table className="table-auto w-full break-words divide-y-reverse">
					<EzLoading  >
						{Thead ? Thead?.toTHead() : this?.getAllKey()?.toTHead()}
						{this.toTBody(Thead ? Thead : this?.getAllKey())}
					</EzLoading>
				</table>
			</div>
		</div>
	)
}
/**
 *
 */
Array.prototype.getAllKey = function ()
{
	return [...new Set(this.flatMap(i => Object.keys(i)))]
}
/**
 * เพิ่มไอเท็มกลาง Array
 */
Array.prototype.addToIndex = function <T> (item: T, index: Key): Array<T>
{
	return [...this.slice(0, index as number), item, ...this.slice(index as number)]
}

Array.prototype.toTBody = function (headkeys?: any[])
{
	return (
		(<tbody className="table-row-group gap-y-2 order-last py-1 border-b border-[#eee] dark:border-strokedark">
			{this.map((i, k) =>
				<EzLoading key={k}> <tr className="table-row" >
					{headkeys?.map((vi, vk) =>
					{
						let value = i[vi]
						return (
							(<EzLoading key={vk}>
								<td className={` table-cell max-w-60 text-center px-2 py-1 border-b border-b-zinc-500 text-black dark:text-white  truncate`} >
									{typeof value == 'object' ?
										Object?.keys(value)?.map((oi, ok) =>
											<EzLoading key={ok}>
												<ol className="text-left">
													<li className="text-xs truncate">
														- {`${oi}`} : {`${Object?.values(value)[ok]}`}
													</li>
												</ol>
											</EzLoading>
										) : (
											typeof value == 'string' &&
												value?.match(/^http/) != null ?
												<IfImage src={value} /> :
												`${value || '-'}`
										)
									}
								</td></EzLoading>)
						)
					})}
				</tr></EzLoading>
			)}
		</tbody>)
	)
}

Array.prototype.filterUndefined = function ()
{
	return this.filter((x) => x !== undefined)
}

Array.prototype.setUpdate = function <T> (index?: string): Array<T>
{
	return [...new Map([...this].map(i => [i?.[index || this[0] && Object?.keys(this[0])[0]] || i, i])).values()]
}
Array.prototype.pushUpdate = function (Array, index)
{
	return [...new Map([...this, ...Array].map(i => [i?.[index ||Array[0]?._id&&"_id"|| Array[0] && Object?.keys(Array[0])[0] || Object?.keys(this[0])[0]], i])).values()]
}

Array.prototype.toTHead = function ()
{
	fnclog("toTHead=", this)
	return (
		<thead className="table-header-group bg-gray-2 text-center font-extrabold dark:bg-meta-4">
			<tr className="table-row">
				{this.map((i, k) =>
				{
					fnclog("\ntoTHead.map=", i)
					return (
						<EzLoading key={k}>
							<th className={`${i?.w ? 'min-w-[' + i.w + 'px]' : ''} table-cell mx-4 py-4  font-medium text-black dark:text-white`}>{i}</th>
						</EzLoading>
					)
				}
				)}
			</tr>
		</thead>
	)
}

