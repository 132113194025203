'use client'


import Link from 'next/link';
import { NavBarLogOutBtn } from '..';
import { useUserUiContext } from '@/lib';

export function UserSideNavMenu ({ userInfo }: { userInfo?: any })
{
  const { setNavOpen, navOpen, siteNav } = useUserUiContext()


  plog("UserSideNavMenu:", userInfo)



  return (
    <div className='absolute overflow-auto right-0 h-full flex flex-col  items-center bg-black bg-opacity-50 p-4 gap-y-4' ref={siteNav}>

      <div className='text-gray border rounded-md px-4 text-center'>
        <i className='text-gray  rounded-md text-center'>ยอดเงินคงเหลือ</i>
        <h1>{userInfo?.credit}</h1>

      </div>
      <div className='text-gray text-title-lg mt-4'>
        <Icons iconName='account_circle' /> USER: {userInfo?.userId.toUpperCase()}</div>



      <Link
        className='btn w-full'
        href={{pathname:"/changepassword"}}
        onClick={() => setNavOpen!(false)}
      >

        เปลี่ยนรหัสผ่าน

      </Link>


      <Link
        className='btn w-full'
        href={{pathname:"/line?twbtn=usermenu"}}
        onClick={() => setNavOpen!(false)}
      >
        ฝาก/ถอน ผ่าน LINE
      </Link>



      <Link
        className='btn w-full'
        href={{pathname:"/lobby?place=usermenu"}}
        onClick={() => setNavOpen!(false)}
      >

        ทางเข้า  ล็อบบี้ Game Lobby
      </Link>

      <NavBarLogOutBtn setNavOpen={setNavOpen} />



    </div>
  )
}
