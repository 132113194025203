'use client'
import { useState, useEffect, useContext } from 'react'
import { NotificationContext } from '../../ContextsProvider'


export function PushPage ()
{

    const [message, setMessage] = useState<string>('')
    const [title, setTitle] = useState("")
    const { subscription } = useContext(NotificationContext)
    const fetchNotification = async () =>
    {
        await fetch("/api/notify/push", {
            method: "POST",
            body: JSON.stringify({ title, message, subscription }),
            headers: {
                "Content-Type": "application/json",
            },
        })
    }

    useEffect(() =>
    {


    }, [])
    return (<div className="bg-white shadow-md rounded-lg p-6 mt-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4">Send a Notification</h2>

        {/* Title Input */}
        <input
            type="text"
            placeholder="Notification Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
        />

        {/* Message Input */}
        <textarea
            placeholder="Notification Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
        />

        <button onClick={fetchNotification} className="w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition">
            Send Notification
        </button>
    </div>
    )
}