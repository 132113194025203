import type { ReactNode } from "react"

export function Page_Ground({
    ClassName,
    children
}: {
    ClassName?: String,
    children: ReactNode,
}) {

    return (
        <div className={`block relative size-full ${ClassName??''}`}>
            {children}
        </div>
    )
}
