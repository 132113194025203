'use client'

import { useLocalStorage } from "../../lib"
import { readTextFromClipboard } from "../../lib/utils/clipBoard"
import type { RefAttributes, ReactNode, Dispatch, SetStateAction } from "react"

export function PopOverFromClipboard (
  Props: {
    [x: string]: RefAttributes<HTMLDivElement> | ReactNode | Dispatch<SetStateAction<string>>,
    value: string
  })
{
  return (


    <span {...Props} data-clibboard={Props.value} contentEditable='true' className={`z-9 space-y-1 rounded-sm border border-stroke bg-white p-1.5 shadow-default dark:border-strokedark dark:bg-boxdark duration-150 ease-in-out`}>

      {Props.value}

    </span>

  )
}

export function EditableFromClipboard (
  Props: {
    [x: string]: RefAttributes<HTMLInputElement> | ReactNode | Dispatch<SetStateAction<string>>
  })
{

  const [clipboardtext, setClipboardtext] = useLocalStorage<string>('clipboardtext', '')

  const handleGetText = () => readTextFromClipboard().then(res =>
  {
    res && setClipboardtext(res)
    fnclog('EditableFromClipboard', res)
  })

  return (

    <input {...Props}
      className="size-full bg-transparent rounded-sm border border-stroke px-4 py-1 text-black outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-boxdark dark:text-white dark:focus:border-primary"
      data-clibboard={clipboardtext}

      onCopy={handleGetText}
      onFocus={handleGetText} />

  )
}
