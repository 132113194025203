'use client'

import { usedsvSiteContext, useLocalStorage } from "@/lib"
import { useState, useEffect } from "react"


export function SiteContexProvider ({
    children
}: {
    children: React.ReactNode
})
{

    const [metaDataTemplate, setMetaDataTemplate] = useLocalStorage<MetaDataTemplate[]>('metaDataTemplate', [])
    const [contentArticleList, setContentArticleList] = useLocalStorage<ContentArticle[]>('contentArticle', [])
    const [customersList, setCustomersList] = useLocalStorage<MemberInfo[]>('customersList', [])
    laylog("SiteContexProvider")
    const [isClient, SetIsClient] = useState(false)
    useEffect(() =>
    {
      SetIsClient(true)
    }, [isClient])
    return isClient&&<usedsvSiteContext.Provider value={{
        metaDataTemplate, setMetaDataTemplate,
        contentArticleList, setContentArticleList,
        customersList, setCustomersList
    }}>
        {children}
    </usedsvSiteContext.Provider>
}