'use client'

import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { type JSX, memo, useEffect, useState } from 'react'
import { type BfxGameProvider, updateOne } from '@/lib'
import { EzLoading, GameFrame, LobbyGamesList } from '@/Components'

const providers:MongoDB = { colName: 'providers', dbName: 'bfx' }

export const ProvidersPage = memo( function  ({
  lobby,
}: {
    lobby: string
})
{
  const { bfxGameProviderList } = useUserContext()
  const { navOpen } = useUserUiContext()
  const [gameList, setGameList] = useState<JSX.Element>()
  const [loginPending, setLoginPending] = useState(false)

  const thisprovider = bfxGameProviderList?.find(i => i?.name?.toUrl()?.match(new RegExp(`${lobby}`, 'i')) ?? i?.game?.match(new RegExp(`${lobby}`, 'i')))

  const router = useRouter()
  useEffect(() =>
  {
    if (!loginPending) return
    if (loginPending && !navOpen) return router.push('/lobby')

  }, [loginPending, navOpen, router])
/*   plog(thisprovider, '<<<<<gamecode Loaded', new RegExp(`${lobby}`, 'i'))
  plog(lobby, '<<<<<provider Loaded', new RegExp(`${lobby}`, 'i')) */

  useEffect(() =>
  {
    thisprovider && updateOne(providers, { game: thisprovider?.game }, { $inc: { views: 1 } })
  }, [thisprovider])

  return <div className=' flex flex-col text-center items-center'>

    <EzLoading>
      {
        thisprovider?.logo &&
        <Image
          className='md:max-w-[30vw] max-w-[50vw] fade-d aniDelay-1'
          src={thisprovider?.logo} width={500} height={500} alt={thisprovider?.name || ''}
        />
      }
    </EzLoading>

    <h2 className="shadow-xl text-gray fade-d aniDelay-2">
      <EzLoading> {thisprovider?.name}   </EzLoading>
    </h2>

    <div className=" flex justify-evenly mt-10 flex-wrap gap-x-2  xsm:gap-x-5 xsm:gap-y-10 overflow-y-hidden">
      <EzLoading>{
        thisprovider?.gameList ? <LobbyGamesList GamesProvider={thisprovider} />
          :
          thisprovider?.game && <GameFrame thisprovider={thisprovider} />
      } </EzLoading>
    </div>
  </div>


}

)