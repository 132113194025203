import { LineUI } from "../../images"


export function UI_EventItems ({ item, reverse, userProfile }: { item: ChatBotEvent | any, reverse?: boolean, userProfile?: any })
{
  const time = new Date(item?.timestamp).toLocaleString()

  return (
    <div className={`flex flex-row${reverse && '-reverse'} break-words`}>

      <LineUI width={50} height={50} {...userProfile} />

      <ul>
        {userProfile?.displayName &&
          <li>{userProfile.displayName}</li>
        }
        <li>{reverse && item?.sender?.name}</li>

        <div className="bg-green-600 bg-opacity-40 py-px px-1 rounded-sm max-w-150">
          { item?.type?.match( /message/i ) ?
            item?.message?.type.match(/text/) ? (reverse ? item?.text : item?.message.text) :
              item?.message?.type?.match(/image/) ? "Image" : item?.message?.type :
            item?.type?.match(/follow/) ? item?.type + '  😍😍' : item?.type?.match(/unfollow/) ? item?.type + '💢💢💢💢' : item?.type}
        </div>
      </ul>
      {reverse ?? <small>{time}</small>}
    </div>
  )
}