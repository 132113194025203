'use client'
import { EzLoading } from "@/Components"
import { LineUI } from "../../images"
import { TableCell_Credit } from "./CellTemplate"
import { THead } from "./THead"
import { memo } from "react"


export function LineUserListTable ()
{
  const { lineUserList, setLineUserList } = useLineBotContext()
  fxlog("lineUserList=", lineUserList.length)
  return (

    <div className="max-w-full">
      <table className="w-full relative table-auto">
        <THead className="text-center font-extrabold dark:text-bodydark1"
          tableHeader={[
            { name: '#', className: 'max-w-fit' },
            { name: 'User', className: 'text-center max-w-fit min-w-[6vw]' },
            { name: 'ชื่อ-นามสกุล และ Line', className: 'w-auto min-w-[6vw]' },
            { name: 'บัญชีธนาคาร', className: 'w-auto max-w-[10vw]' },
            { name: 'PhoneNo.', className: 'min-w-fit max-w-[10vw]' },
            { name: 'Credit', className: 'w-auto max-w-[4vw]' },
            { name: 'updateAt', className: 'min-w-fit max-w-[4vw]' }
          ]} />
      </table>
      <div className="flex-1 relative max-h-[90vh] overflow-y-auto">
        <table className="w-full h-fit">
        <EzLoading > {lineUserList && <LineUserListBody usersList={lineUserList.slice(0, 100)} />} </EzLoading>
        </table>
      </div>
    </div>
  )
}

export const LineUserListBody = memo( function  ({ usersList }: { usersList?: any[] })
{

  return <tbody >
    {usersList?.map((i, k) => <EzLoading key={k} >
      <tr >
        <td className="inline-table">
          <LineUI {...i} width={40} height={40} />
        </td>
        <td className="text-center w-auto max-w-[10vw]">
        {i?.username ?? 'ยังไม่มียูสเซอร์'}
        </td>
        <td className='w-auto max-w-[10vw]'>
        {(i?.fname ?? 'ไม่มีชื่อ ') + (i?.lname ?? ' ไม่มีนามสกุล')}
          <div className="text-sm">
            {/* >> Value _____*/  i?.displayName ?? 'Unknow'  /* _____ Value << */}
          </div>
        </td>
        <td className="w-auto max-w-[10vw]">
        {i?.banking ?? 'ยังไม่มี ธนาคาร'}
        </td>
        <td className="w-auto max-w-[10vw]">
          {/*   <Ez.Input className="text-black dark:text-white" placeholder={i?.username ??'ยังไม่มียูสเซอร์'} /> */}
          {i?.username ?? 'ยังไม่มีเบอร์'}
        </td>


        <td className=" text-center max-w-[8vw]">

          <div className="inline-flex mx-1 space-x-3.5">
            <button className="hover:text-primary">
              <Icons iconName="Edit" />
            </button>
            <button className="hover:text-primary">
              <Icons iconName="Tune" />
            </button>
            <button className="hover:text-primary">
              <Icons iconName='Currency_Exchange' />
            </button>
            <TableCell_Credit int={i?.Credit && Number(i?.Credit || 0).toFixed(2)} />
          </div>
        </td>
        <td className="text-cente max-w-[3vw]">
          <div className={`inline-flex rounded-full bg-opacity-10 px-3 py-1 font-medium ${i?.active
            ? "bg-success text-success"
            : "bg-danger text-danger"
            }`}
          >
            {i?.active ? <Icons iconName="swords" /> : <Icons iconName="Person_Off" />}
          </div>
        </td>
      </tr>
    </EzLoading>
    )
    }
  </tbody>


})