'use client'
import Image, { type ImageProps } from 'next/image'



   export const PP= (props: Partial<ImageProps>) => <Image {...props}
        src='https://img2.pic.in.th/pic/PP-D1040.png'
        width={1040}
        height={1040}
        alt="LogoPP" />
   export const BFLIX= (props: Partial<ImageProps>) => <Image {...props}
        src='https://img5.pic.in.th/file/secure-sv1/Logo-16-9-Shadow997-342.png'
        width={props.width||997}
        height={props.height||342}
        style={{height:'auto'}}

        alt="LogoBFLIX" />
   export const DarinBET= (props: Partial<ImageProps>) => <Image {...props}
        src='https://img2.pic.in.th/pic/Darin-Mascot2-Shadow-NoBG-Recovered600.png'
        width={props.width||600}
        height={props.height||600}
        style={{height:'auto'}}
        alt="LogoDarinBET" />
   export const SA= (props: Partial<ImageProps>) => <Image {...props}
        src='https://img2.pic.in.th/pic/salogo-3d5200be09809b4e9e03d.png'
        width={520}
        height={520}
        alt="LogoSA" />
   export const Bflix579= (props: Partial<ImageProps>) => <Image {...props}
        src='https://img2.pic.in.th/pic/BFLIX579-Logo.png'
        width={600}
        height={600}
        alt="LogoBflix579" />
   export const CQ9= (props: Partial<ImageProps>) => <Image {...props}
        src='https://img5.pic.in.th/file/secure-sv1/cq92.png'
        width={600}
        height={600}
        alt="LogoCQ9" />
   export const Sexy= (props: Partial<ImageProps>) => <Image
        {...props}
        src='https://img5.pic.in.th/file/secure-sv1/Highcompressed_519860491b76c9c672db30d26.png'
        width={600}
        height={600}
        alt="LogoSexy" />
   export const PG= (props: Partial<ImageProps>) => <Image {...props}
        src='https://img2.pic.in.th/pic/PG-LOGO600.png'
        width={600}
        height={600}
        alt="LogoPG" />
   export const PS= (props: Partial<ImageProps>) => <Image {...props}
        src='https://img2.pic.in.th/pic/ps.png'
        width={600}
        height={600}
        alt="LogoPS" />

