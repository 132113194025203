'use client'
import { createContext, useContext } from 'react'



export const userUiContext = createContext<UserUiContext>({
    showInstallBanner: false, setShowInstallBanner: () => { },
    GameframeRef: undefined,
    themeRef: undefined, trigger: undefined, pageScroll: undefined, siteNav: undefined, pathname: undefined, router: undefined,
    top: true, setTop: () => { },
    navOpen: false, setNavOpen: () => { },
    gamePlayState: false, setGamePlayState: () => { },
    delay: 300, setDelay: () => { },
    themeContext: 'dark', setThemeContext: () => { }
})

export const useUserUiContext = () => useContext(userUiContext)
