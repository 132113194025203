'use client'
import Image from "next/image"
import Link from "next/link"
import { ImgList } from "../../images"
import type { BfxGameProvider, Games } from "../../lib"
import { EzLink, EzLoading } from "@/Components";


export function CardOverLay ({
  GamesProvider,
  gameCard,
  className
}: {
  GamesProvider: BfxGameProvider,
  gameCard?: Games,
  className?: string
})
{

  return <div className={`cardDIV xsm:max-w-40 min-w-25   ${className || ''}`}>
   <EzLoading> {gameCard ? <GameCard GamesProvider={GamesProvider} gameCard={gameCard} /> :
      <ProviderCard GamesProvider={GamesProvider} />}</EzLoading>

  </div>

}

function GameCard ({
  GamesProvider, gameCard
}: {
  GamesProvider: BfxGameProvider, gameCard: Games
})
{
  /*  celog('GameCard :',GamesProvider?.name?.toUrl(),gameCard?.name?.toUrl()) */


  return <EzLink href={{pathname:`${GamesProvider?.name?.toUrl()}/${gameCard?.name?.toUrl()}`}} >
    <div className='absolute translate-y-4 z-1 drop-shadow-1'>

      <ImgList.coinAnimate className='animate-bounce w-10' width={50} height={50} />
    </div>

    <EzLoading>{gameCard?.img &&<Image className="min-w-25 -z-0 rounded-xl drop-shadow"

      alt={gameCard?.name ?? ''}
      src={gameCard?.img}
      width={500}
      height={500}
    />}</EzLoading>

    <h3 className="text-center text-white drop-shadow mt-2"> {gameCard?.name}  </h3>
  </EzLink>
}



function ProviderCard ({
  GamesProvider
}: {
  GamesProvider: BfxGameProvider
})
{
  /* celog('ProviderCard :',GamesProvider?.name?.toUrl()) */
  return <EzLink href={{pathname:`${GamesProvider?.name?.toUrl()}`}}>

    <div className='absolute translate-y-4 z-1 drop-shadow'>

      <ImgList.coinAnimate className='animate-bounce w-10 ' width={50} height={50} />
    </div>
   <EzLoading>{GamesProvider?.logo&& <Image className="bg-[#0f0c80] min-w-25 bg-gradient-to-br from-[#fff4] via-[#0008] to-[#000D]  -z-0 rounded-xl drop-shadow-1"

      alt={GamesProvider?.name ?? ''}
      src={GamesProvider?.logo}
      width={500}
      height={500}
      priority
    />}</EzLoading>

    <h3 className="text-center text-white drop-shadow mt-2"> {GamesProvider?.name}</h3>
  </EzLink>
}