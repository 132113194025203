export {}

export const iconList = [
    'Person',
    'Group',
    'Person_Add',
    'Support_Agent',
    'Share',
    'Person_Remove',
    'Person_Off',
    'Password',
    "Person_Alert",
    'Kid_Star',
    'Language',
    'Account_Circle',
    'Warning',
    'Account_Box',
    'swords',
    'Sword_Rose',
    'Qr_Code_2',
    'Bomb',
    'Phone_Enabled',
    'Chat',
    'Edit',
    'Currency_Exchange',
    'Tune',
    'Visibility_Lock',
    'Question_Mark',
    'Price_Change',
    'Add',
    'Add_Circle',
    'Remove',
    'keyboard_double_arrow_up',
    'Heart_Plus'
 ] as const
 