'use client'
export { };
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { startTransition, useEffect, useState } from 'react';

 
export function NavigationEvents() {


  const {GameframeRef, gamePlayState,setGamePlayState} = useUserUiContext()
  const pathname = usePathname()
  const router = useRouter()
  const searchParams = useSearchParams()
  const [ confirmModal, setConfirmModal ] = useState( false );
  let time: NodeJS.Timeout;
  const handleBackButton = ( ev: PopStateEvent ) =>
  {
    fxlog( "handleBackButton" );
    ev.preventDefault();
    ev.stopPropagation();


    fxlog( "<<<<<<<<<<gamePlayState>>>>>>>>>>=", gamePlayState, time );

    if ( gamePlayState || confirmModal )
    {
      fxlog( "<<<<<<<<<<<<<<<<<<<<< gamePlayState>>>>>>>>>>>>>>>>>>>>>" );
      fxlog( "confirm" );
      history?.go( 1 );
    }
    else
    {

      startTransition( () =>
      {
        setConfirmModal( true );
        setGamePlayState( false );
        fxlog( "<<<<<<<<<<<< history.state>>>>>>>>>>>>=", history.state );
      } );
      history.pushState( null, '' );
      history?.go( -1 );
      time = setTimeout( () =>
      {
        startTransition( () =>
        {
          setConfirmModal( false );
          setConfirmModal( true );
        } );
        history.pushState( null, '' );
        history?.go( 1 );
        history.pushState( null, '' );
        fxlog( " setGamePlayState( true );" );
      }, 10000 );
      fxlog( "confirm=false" );
    }
  };

  useEffect(() => {
    fxlog( "NavigationEvents" );
    !gamePlayState && GameframeRef?.current && setTimeout( () =>
    {
      fnclog( "setGamePlayState!(GameframeRef?.current?true:false);" );
      setGamePlayState!( GameframeRef?.current ? true : false );
    }, 5000 );

    if ( GameframeRef )
    {
      fxlog( "NavigationEvents GameframeRef" );

      window.addEventListener( 'popstate', handleBackButton, { passive: true } );
    }
    return () =>
    {
      setTimeout( () =>
      {
        fnclog( "setGamePlayState!(GameframeRef?.current?true:false);" );
        setGamePlayState!( GameframeRef?.current ? true : false );
      }, 5000 );
      window.removeEventListener( 'popstate', handleBackButton );
    }

  }, [ pathname, searchParams, gamePlayState ] )

  return null
}