




  type FecthAsync<T> =  {async (url: string): Awaited<Promise<T>>}
  type FecthStr = FecthAsync<string>
  


export const fetchJ = async<T>(url: string): Promise<T> => {
      const response = await fetch(url,{next:{revalidate:60,tags:[url]}})
    const data = await response.clone().json()
    return data as T
    }