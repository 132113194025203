'use client'
import { createContext, useContext } from 'react'



export const usebfxContext = createContext<BfxContext>({
    // ⇩ Start  ````````````````````````  หน้าบ้านโซน  ````````````````````````` Start ⇩\
    bfxUserList: [], setBfxUserList: () => { },
    bfxGameProviderList: [], setBfxGameProviderList: () => { },
    bfxTransactionList: [], setBfxTransactionList: () => { },
    bfxCreditRefundList: [], setBfxCreditRefundList: () => { }
    //⇧ End   __________________________  หน้าบ้านโซน  ___________________________ End ⇧\
})


export const useBfxContext = () => useContext(usebfxContext)
