"use client"
export { }
import { memo, startTransition, useEffect } from "react"
import { UserBfCredit } from "./UserBfCredit"
import { EzLoading } from "../EzLoading"
import { getSessionData, type bfxUsers } from "@/lib"



export const MenuBtnUserCredit = memo(function()
{
  const { trigger, navOpen, setNavOpen } = useUserUiContext()
  const { userInfo, setUserInfo,hasSession } = useUserContext()

  useEffect(() =>
  {

    if (hasSession && !userInfo)
    {
      fxlog('MenuBtn hassession', hasSession, ' &&!userInfo', userInfo)
      getSessionData<bfxUsers>()?.then(res => res && startTransition(()=> setUserInfo(res)))
    }
  }, [userInfo])

  fnclog('MenuBtn ?.hassession', hasSession)
  return <div className="flex flex-row items-center gap-4">

    {hasSession &&<EzLoading> <UserBfCredit /></EzLoading>}
    


    <button
      ref={trigger}
      className={`relative  nav-toogler hover:cursor-pointer text-center items-center size-10 before:w-10 after:w-10 after:mt-2 before:h-1 after:h-1 ${navOpen && "active"}`}
      aria-controls="siteNav"
      onClick={() => setNavOpen(n => !n)}
    >
     <EzLoading> {!hasSession && <label className="absolute hover:cursor-pointer size-fit -translate-x-[150%] pr-4 -translate-y-1/2">เข้าสู่ระบบ</label>}
</EzLoading>
    </button>
  </div>

})